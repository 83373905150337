import { apiGetRequest, apiPostRequest } from "./helper"
const BASE_URL = process.env.REACT_APP_BASE_URL
export const TRAN_URL = BASE_URL + "inter_list";
export const TRAN_EDIT_URL = BASE_URL + "trans";

export function get_trans() {
  return apiGetRequest(TRAN_URL);
}

export function get_tran(id) {
  return apiGetRequest(TRAN_EDIT_URL + `/${id}`);
}

export function update_tran(id, newTran) {
  return apiPostRequest(TRAN_EDIT_URL + `/${id}`, newTran);
}

export function add_tran(tran) {
  return apiPostRequest(BASE_URL + `create_inter`, tran);
}

export function remove_inter(id) {
  return apiPostRequest(BASE_URL + `delete_inter/${id}`);
}
