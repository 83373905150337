import { apiGetRequest } from "../helper"
const BASE_URL = process.env.REACT_APP_BASE_URL
export const INTERPRETERS_URL = BASE_URL + "interpreter";

export function get_interpreter_events() {
  return apiGetRequest(INTERPRETERS_URL + '/event_list');
}

export function get_interpreter_event(id) {
  return apiGetRequest(INTERPRETERS_URL + `/events/${id}`);
}

export function get_interpreter_rooms() {
  return apiGetRequest(INTERPRETERS_URL + '/room_list');
}

export function get_room(id) {
  return apiGetRequest(INTERPRETERS_URL + `/rooms/${id}`);
}