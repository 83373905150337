/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types'
import { injectIntl } from "react-intl";
import * as dashboardStore from "../store/ducks/dashboard.duck";

function LatestUpdatesBrowsers(props) {
    const { browsers, getStatBrowsers, intl } = props;

    useEffect(() => {
        getStatBrowsers();
    }, [getStatBrowsers]);
    return (
        <>
            <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            {intl.formatMessage({
                                id: "DASHBOARD.BROWSERS.TITLE"
                            })}
                        </h3>
                    </div>
                    {/* <PortletHeaderDropdown /> */}
                </div>
                <div className="kt-portlet__body">
                    <div className="kt-widget4">
                        {browsers &&
                            browsers.map((browser, index) => (
                                <div key={index} className="kt-widget4__item">
                                    <span className="kt-widget4__icon">
                                        {browser.browser
                                            .toLowerCase()
                                            .includes("chrome") ? (
                                            <i className="fab fa-chrome"></i>
                                        ) : browser.browser
                                              .toLowerCase()
                                              .includes("firefox") ? (
                                            <i className="fab fa-firefox"></i>
                                        ) : browser.browser
                                              .toLowerCase()
                                              .includes("safari") ? (
                                            <i className="fab fa-safari"></i>
                                        ) : (
                                            <i className="far fa-window-maximize"></i>
                                        )}
                                    </span>
                                    <a className="kt-widget4__title">
                                        {browser.browser}
                                    </a>
                                    <span className="kt-widget4__number kt-font-success">
                                        {browser.amount}
                                    </span>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        // user: state.auth.user,
        // isEndMeeting: state.customerReducer.isEndMeeting,
        browsers: state.dashboard.browsers
    };
};

const mapDispatchToProps = dispatch => ({
    // endMeetingSuccess: () => dispatch(eventsStore.actions.endMeetingSuccess()),
    getStatBrowsers: () => dispatch(dashboardStore.actions.getStatBrowsers())
    // updateRoomList: (roomList) => dispatch(eventsStore.actions.updateRoomList(roomList)),
    // addStat: (data) => dispatch(eventsStore.actions.addStat(data)),
});

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(LatestUpdatesBrowsers)
);
