import React from "react";
import { Route, Switch } from "react-router-dom";
import Admins from "./Admins";
import Managers from "./Managers";
import Emitters from "./Emitters";
import Interpreters from "./Interpreters";
import Customers from "./Customers";


export default function Users() {
  return (
    <Switch>

      <Route
        exact={true}
        path="/users/admins"
        component={Admins}
      />

      <Route
        exact={true}
        path="/users/managers"
        component={Managers}
      />

      <Route
        exact={true}
        path="/users/emitters"
        component={Emitters}
      />

      <Route
        exact={true}
        path="/users/interpreters"
        component={Interpreters}
      />

      <Route
        exact={true}
        path="/users/customers"
        component={Customers}
      />


    </Switch>
  );
}
