import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Formik } from "formik";
import clsx from "clsx";
import { toAbsoluteUrl } from "../../../_metronic";
import KTAvatar from "../../../_metronic/_assets/js/avatar"
import KTUtil from "../../../_metronic/_assets/js/util"
import * as authStore from "../../store/ducks/auth.duck";
import { update_profile } from "../../crud/auth.crud";

function Account(props) {

    const { user, getUser, intl } = props
    let avatar = useRef(null)
    useEffect(() => {
        getUser()
        avatar.current = new KTAvatar('kt_user_avatar');
    }, [getUser])

    useEffect(() => {
        if (user.pic && user.pic !== '') {
            avatar.current.setDefaults();
            KTUtil.removeClass(KTUtil.get('kt_user_avatar'), 'kt-avatar--changed');
        }
    }, [user])

    useEffect(() => {
    })

    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
        paddingRight: "2.5rem"
    })

    const enableLoading = () => {
        setLoading(true);
        setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
        setLoading(false);
        setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };
    return (
        <>
            <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="kt-portlet">
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-label">
                                    <h3 className="kt-portlet__head-title">{intl.formatMessage({ id: "PROFILE.ACCOUNT.CONTAINER.TITLE" })} <small>{intl.formatMessage({ id: "PROFILE.ACCOUNT.CONTAINER.SUBTITLE" })}</small></h3>
                                </div>
                            </div>
                            <Formik
                                initialValues={
                                    user
                                }
                                enableReinitialize={true}
                                validate={values => {
                                    const errors = {};
                                    if (!values.name) {
                                        errors.name = intl.formatMessage({
                                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                        });
                                    }

                                    if (!values.email) {
                                        errors.email = intl.formatMessage({
                                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                        });
                                    }

                                    return errors;
                                }}
                                onSubmit={(values, { setStatus, setSubmitting }) => {
                                    let formData = new FormData();
                                    formData.append('id', user.id)
                                    formData.append('name', values.name)
                                    formData.append('email', values.email)
                                    formData.append('pic', values.pic)
                                    enableLoading();
                                    setTimeout(() => {
                                        update_profile(formData).then(data => {
                                            getUser()
                                        }).catch((error) => {
                                        })
                                        disableLoading();
                                    }, 1000);
                                }}
                            >
                                {({
                                    values,
                                    status,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue
                                }) => (
                                        <form
                                            noValidate={true}
                                            autoComplete="off"
                                            className="kt-form kt-form--label-right"
                                            onSubmit={handleSubmit}>
                                            <div className="kt-portlet__body">
                                                <div className="kt-section kt-section--first">
                                                    <div className="kt-section__body">
                                                        <div className="form-group row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">{intl.formatMessage({ id: "USER.FIELD.AVATAR" })}</label>
                                                            <div className="col-lg-9 col-xl-6">
                                                                <div className="kt-avatar kt-avatar--outline" id="kt_user_avatar">
                                                                    <div className="kt-avatar__holder"
                                                                        style={{
                                                                            backgroundImage: `url(${user.pic ? process.env.REACT_APP_FILE_URL + user.pic : toAbsoluteUrl("/media/users/default.jpg")})`
                                                                        }}
                                                                    />
                                                                    <label className="kt-avatar__upload" data-toggle="kt-tooltip" title="" data-original-title="Change avatar">
                                                                        <i className="fa fa-pen"></i>
                                                                        <input type="file" onChange={(event) => {
                                                                            setFieldValue("pic", event.currentTarget.files[0]);
                                                                        }} name="pic" accept=".png, .jpg, .jpeg" />
                                                                    </label>
                                                                    <span className="kt-avatar__cancel" data-toggle="kt-tooltip" title="" data-original-title="Cancel avatar">
                                                                        <i className="fa fa-times"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">{intl.formatMessage({ id: "USER.FIELD.NAME" })}</label>
                                                            <div className="col-lg-9 col-xl-6">
                                                                <input name="name" className="form-control" type="text" value={values.name} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">{intl.formatMessage({ id: "USER.FIELD.EMAIL" })}</label>
                                                            <div className="col-lg-9 col-xl-6">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend"><span className="input-group-text"><i className="la la-at"></i></span></div>
                                                                    <input name="email" type="text" onChange={handleChange} className="form-control" value={values.email} placeholder="Email" aria-describedby="basic-addon1" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="kt-portlet__foot">
                                                <div className="kt-form__actions">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-xl-3">
                                                        </div>
                                                        <div className="col-lg-9 col-xl-9">
                                                            <button
                                                                className={`btn btn-success btn-elevate kt-login__btn-primary ${clsx(
                                                                    {
                                                                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                                                    }
                                                                )}`}
                                                                style={loadingButtonStyle}>{intl.formatMessage({ id: "AUTH.GENERAL.SUBMIT_BUTTON" })}</button>&nbsp;
												            <button type="reset" className="btn btn-secondary">{intl.formatMessage({ id: "EVENT.ACTION.CANCEL" })}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
    };
};

const mapDispatchToProps = dispatch => ({
    getUser: () => dispatch(authStore.actions.requestUser()),
    updateProfile: () => dispatch(authStore.actions.updateProfile()),
})

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Account)
);