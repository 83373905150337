import React from "react";
import { makeStyles, withStyles, LinearProgress } from "@material-ui/core";

// function _getInterpreters(participants) {
//     return participants ? participants.filter(p => parseInt(p.getProperty('role')) === UserRole.INTERPRETER) : []
// }

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: "absolute",
        width: 50,
        // backgroundColor: theme.palette.common.white,
        // borderRadius: 4,
        // transform: props => { return `translate3d(0px, ${_getInterpreters(props.participants).length * -40}px, 0px)` },
        // boxShadow: '0px 0px 50px 0px rgba(82, 63, 105, 0.15)',
        transform: "rotate(270deg)",
        left: -15,
        top: "25%"
    }
}));

const BorderLinearProgress = withStyles(theme => ({
    root: {
        margin: 5
    },
    colorPrimary: {
        backgroundColor: "transparent"
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#1a90ff"
    }
}))(LinearProgress);

function AudioDetector(props) {
    const { pId, audioLevels } = props;
    const classes = useStyles(props);
    const audioLevel = audioLevels.find(a => a.userId === pId);

    return (
        <div className={classes.wrapper}>
            <BorderLinearProgress
                className={classes.linearProgress}
                variant="determinate"
                value={
                    audioLevel
                        ? audioLevel.audioLevel > 2 / 3
                            ? 100
                            : audioLevel.audioLevel * 150
                        : 0
                }
            />
        </div>
    );
}

export default AudioDetector;
