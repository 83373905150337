import React from "react";
import { FormattedMessage } from "react-intl";
import queryString from "query-string";
import { Tab, Nav, Row, Col } from "react-bootstrap";

import Account from "./Account";
import Security from "./Security";
export default function Profile(props) {
    const url = props.location.search;
    const params = queryString.parse(url);
    const eventKey = params.tab;

    return (
        <>
            <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={
                    eventKey && eventKey === "second" ? "second" : "first"
                }
            >
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="first">
                                    <FormattedMessage id="PROFILE.TAB.ACCOUNT" />
                                </Nav.Link>
                            </Nav.Item>
                            {process.env.REACT_APP_SECURITY === "true" && (
                                <Nav.Item>
                                    <Nav.Link eventKey="second">
                                        <FormattedMessage id="PROFILE.TAB.SECURITY" />
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <Account />
                            </Tab.Pane>
                            {process.env.REACT_APP_SECURITY === "true" && (
                                <Tab.Pane eventKey="second">
                                    <Security />
                                </Tab.Pane>
                            )}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    );
}
