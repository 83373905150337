import { useEffect } from "react";
import store from "../store/store";

function useHandleMute(isMuted) {
    useEffect(() => {
        const state = store.getState();
        const room = state.event.room;
        if (!room) return;
        const localAudio = room.getLocalAudioTrack();
        if (localAudio) {
            if (isMuted) {
                localAudio.mute();
            } else {
                localAudio.unmute();
            }
        }
    }, [isMuted]);
}

export default useHandleMute