import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import Builder from "./Builder";
import { LayoutSplashScreen } from "../../../_metronic";

import Events from "./interpreters/events/Events";
import Profile from "../profile/Profile";
const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);

function InterpreterHomePage(props) {
  const { firstLogin, intl } = props
  const [openFirstLoginDlg, setOpenFirstLoginDlg] = React.useState(false)
  useEffect(() => {
    if (firstLogin) {
      setOpenFirstLoginDlg(true)
    }
  }, [firstLogin])

  function handleClose(event) {
    setOpenFirstLoginDlg(prev => !prev)
  }

  function handleContinue(event) {
    setOpenFirstLoginDlg(prev => !prev)
    props.history.push('/profile?tab=second')

  }
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/events" />
          }
          <Route path="/events" component={Events} />
          <Route path="/profile" component={Profile} />
          <Route path="/builder" component={Builder} />
          <Route path="/google-material" component={GoogleMaterialPage} />
          <Route path="/react-bootstrap" component={ReactBootstrapPage} />
          <Redirect to="/error/error-v1" />
        </Switch>
      </Suspense>
      <div>
        <Dialog
          open={openFirstLoginDlg}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{intl.formatMessage({ id: "FIRSTLOGIN.DLG.TITLE" })}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {intl.formatMessage({ id: "FIRSTLOGIN.DLG.DESC" })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {intl.formatMessage({ id: "FIRSTLOGIN.DLG.ACTION.CLOSE" })}
            </Button>
            <Button onClick={handleContinue} color="primary" autoFocus>
              {intl.formatMessage({ id: "FIRSTLOGIN.DLG.ACTION.RESET_PASSWORD" })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}


const mapStateToProps = state => {
  return {
    user: state.auth.user,
    firstLogin: state.auth.firstLogin
  }
}

const mapDispatchToProps = dispatch => ({})
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(InterpreterHomePage)));