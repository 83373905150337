import { apiGetRequest, apiPostRequest } from "../helper"
const BASE_URL = process.env.REACT_APP_BASE_URL
export const CUSTOMERS_URL = BASE_URL + "customer";
export const STAT_URL = BASE_URL + 'add_status';

export function get_customer_events() {
  return apiGetRequest(CUSTOMERS_URL + '/event_list');
}

export function get_customer_event(id) {
  return apiGetRequest(CUSTOMERS_URL + `/events/${id}`)
}

export function get_customer_rooms() {
  return apiGetRequest(CUSTOMERS_URL + '/room_list')
}

export function get_room(id) {
  return apiGetRequest(CUSTOMERS_URL + `/rooms/${id}`)
}

export function post_stat(data) {
  return apiPostRequest(STAT_URL, data)
}