import { put, takeEvery } from 'redux-saga/effects';
import { get_emitter_schedules, get_emitter_events, get_emitter_rooms, get_emitter_event } from "../../../crud/emitters/index.crud"

export const actionTypes = {
  GET_EMITTER_EVENT: "GET_EMITTER_EVENT",
  GET_EMITTER_EVENT_SUCCESS: "GET_EMITTER_EVENT_SUCCESS",
  GET_EMITTER_SCHEDULES: "GET_EMITTER_SCHEDULES",
  GET_EMITTER_SCHEDULES_SUCCESS: "GET_EMITTER_SCHEDULES_SUCCESS",
  GET_EMITTER_EVENTS: "GET_EMITTER_EVENTS",
  GET_EMITTER_EVENTS_SUCCESS: "GET_EMITTER_EVENTS_SUCCESS",
  GET_EMITTER_ROOMS: "GET_EMITTER_ROOMS",
  GET_EMITTER_ROOMS_SUCCESS: "GET_EMITTER_ROOMS_SUCCESS",
  START_MEETING: "START_MEETING",
  END_MEETING: "END_MEETING",
  END_MEETING_SUCCESS: "END_MEETING_SUCCESS",
  UPDATE_SCREEN_MODE: "UPDATE_SCREEN_MODE",
};


export const actions = {
  getEmitterEvent: (id) => ({ type: actionTypes.GET_EMITTER_EVENT, event_id: id }),
  getEmitterEventSuccess: event => ({ type: actionTypes.GET_EMITTER_EVENT_SUCCESS, event: event }),
  getEmitterSchedules: () => ({ type: actionTypes.GET_EMITTER_SCHEDULES }),
  getEmitterSchedulesSuccess: scheduleList => ({ type: actionTypes.GET_EMITTER_SCHEDULES_SUCCESS, scheduleList: scheduleList }),
  getEmitterEvents: () => ({ type: actionTypes.GET_EMITTER_EVENTS }),
  getEmitterEventsSuccess: eventList => ({ type: actionTypes.GET_EMITTER_EVENTS_SUCCESS, eventList: eventList }),
  getEmitterRooms: () => ({ type: actionTypes.GET_EMITTER_ROOMS }),
  getEmitterRoomsSuccess: roomList => ({ type: actionTypes.GET_EMITTER_ROOMS_SUCCESS, roomList: roomList }),
  startMeeting: () => ({ type: actionTypes.START_MEETING }),
  endMeeting: () => ({ type: actionTypes.END_MEETING }),
  endMeetingSuccess: () => ({ type: actionTypes.END_MEETING_SUCCESS }),
  updateScreenMode: (mode) => ({ type: actionTypes.UPDATE_SCREEN_MODE, mode: mode }),

};

const initialEmittersState = {
  scheduleList: [],
  eventList: [],
  roomList: [],
  isStartMeeting: false,
  isEndMeeting: false,
  isEvent: false,
  screenMode: 0,
};


export const reducer = (state = initialEmittersState, action) => {
  switch (action.type) {
    case actionTypes.GET_EMITTER_EVENT: {
      return {
        ...state,
        isEvent: false
      }
    }
    case actionTypes.GET_EMITTER_EVENT_SUCCESS: {
      return {
        ...state,
        event: action.event,
        isEvent: true
      }
    }
    case actionTypes.GET_EMITTER_SCHEDULES_SUCCESS: {
      return {
        ...state,
        scheduleList: action.scheduleList
      }
    }
    case actionTypes.GET_EMITTER_EVENTS_SUCCESS: {
      return {
        ...state,
        eventList: action.eventList
      }
    }
    case actionTypes.GET_EMITTER_ROOMS_SUCCESS: {
      return {
        ...state,
        roomList: action.roomList
      }
    }
    case actionTypes.START_MEETING: {
      return {
        ...state,
        isStartMeeting: true,
        isEndMeeting: false
      }
    }
    case actionTypes.END_MEETING: {
      return {
        ...state,
        isEndMeeting: true,
      }
    }
    case actionTypes.END_MEETING_SUCCESS: {
      return {
        ...state,
        isStartMeeting: false
      }
    }
    case actionTypes.UPDATE_SCREEN_MODE: {
      return {
        ...state,
        screenMode: action.mode
      }
    }
    default:
      return state;
  }
};

export function* emittersModuleSaga() {
  yield takeEvery(actionTypes.GET_EMITTER_EVENT, function* getEventSaga(action) {
    const response = yield get_emitter_event(action.event_id);
    const data = yield response.data;
    yield put(actions.getEmitterEventSuccess(data.data));
  });
  yield takeEvery(actionTypes.GET_EMITTER_SCHEDULES, function* getSchedulesSaga() {
    try {
      const response = yield get_emitter_schedules();
      const data = yield response.data ?? [];
      yield put(actions.getEmitterSchedulesSuccess(data.data));
    } catch {
      yield put(actions.getEmitterSchedulesSuccess([]));
    }
  });

  yield takeEvery(actionTypes.GET_EMITTER_EVENTS, function* getEventsSaga() {
    try {
      const response = yield get_emitter_events();
      const data = yield response.data ?? [];
      yield put(actions.getEmitterEventsSuccess(data.data));
    } catch {
      yield put(actions.getEmitterEventsSuccess([]));
    }
  });

  yield takeEvery(actionTypes.GET_EMITTER_ROOMS, function* getRoomsSaga() {
    try {
      const response = yield get_emitter_rooms();
      const data = yield response.data ?? [];
      yield put(actions.getEmitterRoomsSuccess(data.data));
    } catch {
      yield put(actions.getEmitterRoomsSuccess([]));
    }
  });
}
