import { put, takeEvery } from 'redux-saga/effects';

import { get_languages, update_language, add_language, remove_language, get_language_flags } from "../../crud/language.crud"

export const actionTypes = {
  GET_LANGUAGES: "GET_LANGUAGES",
  RENDER_LANGUAGE_LIST: "RENDER_LANGUAGE_LIST",
  UPDATE_LANGUAGES: "UPDATE_LANGUAGES",
  UPDATE_LANGUAGES_SUCCESS: "UPDATE_LANGUAGES_SUCCESS",
  ADD_LANGUAGE: "ADD_LANGUAGE",
  ADD_LANGUAGE_SUCCESS: "ADD_LANGUAGE_SUCCESS",
  REMOVE_LANGUAGE: "REMOVE_LANGUAGE",
  REMOVE_LANGUAGESUCCESS: "REMOVE_LANGUAGE_SUCCESS",
  GET_LANGUAGE_FLAGS: "GET_LANGUAGE_FLAGS",
  GET_LANGUAGE_FLAGS_SUCCESS: "GET_LANGUAGE_FLAGS_SUCCESS",
};


export const actions = {
  getLanguages: () => ({ type: actionTypes.GET_LANGUAGES }),
  renderLanguageList: languageList => ({ type: actionTypes.RENDER_LANGUAGE_LIST, languageList: languageList }),
  updateLanguages: language => ({ type: actionTypes.UPDATE_LANGUAGES, newLanguage: language }),
  updateLanguagesSuccess: () => ({ type: actionTypes.UPDATE_LANGUAGES_SUCCESS }),
  addLanguage: language => ({ type: actionTypes.ADD_LANGUAGE, newLanguage: language }),
  addLanguageSuccess: language => ({ type: actionTypes.ADD_LANGUAGE_SUCCESS, newLanguage: language }),
  removeLanguage: languageId => ({ type: actionTypes.REMOVE_LANGUAGE, languageId: languageId }),
  removeLanguageSuccess: languageId => ({ type: actionTypes.REMOVE_LANGUAGESUCCESS, languageId: languageId }),
  getLanguageFlags: () => ({ type: actionTypes.GET_LANGUAGE_FLAGS }),
  getLanguageFlagsSuccess: (flags) => ({ type: actionTypes.GET_LANGUAGE_FLAGS_SUCCESS, flags: flags }),
};

const initialLanguagesState = {
  languageList: [],
  flags: []
};


export const reducer = (state = initialLanguagesState, action) => {
  switch (action.type) {
    case actionTypes.RENDER_LANGUAGE_LIST: {
      return {
        ...state,
        languageList: action.languageList
      }
    }
    case actionTypes.ADD_LANGUAGE_SUCCESS: {
      let newLanguageList = [
        ...state.languageList,
        {
          ...action.newLanguage
        }
      ];
      return {
        ...state,
        languageList: newLanguageList
      }
    }
    case actionTypes.REMOVE_LANGUAGESUCCESS: {
      let oldLanguageList = [...state.languageList];
      const newLanguageList = oldLanguageList.filter(language => language.id !== action.languageId);
      return {
        ...state,
        languageList: newLanguageList
      }
    }
    case actionTypes.GET_LANGUAGE_FLAGS_SUCCESS: {
      return {
        ...state,
        flags: action.flags
      }
    }
    default:
      return state;
  }
};

export function* languagesSaga() {
  yield takeEvery(actionTypes.GET_LANGUAGES, function* getLanguagesSaga() {
    try {
      const response = yield get_languages();
      const data = yield response.data ?? [];
      yield put(actions.renderLanguageList(data.languages));
    } catch {
      yield put(actions.renderLanguageList([]));
    }
  });

  yield takeEvery(actionTypes.UPDATE_LANGUAGES, function* updateLanguagesSaga(action) {
    const newLanguage = action.newLanguage
    yield update_language(newLanguage.id, newLanguage)
    yield put(actions.updateLanguagesSuccess());
  });

  yield takeEvery(actionTypes.ADD_LANGUAGE, function* addLanguageSaga(action) {
    const newLanguage = action.newLanguage
    const response = yield add_language(newLanguage)
    yield put(actions.addLanguageSuccess(response.data.data));
  });

  yield takeEvery(actionTypes.REMOVE_LANGUAGE, function* removeLanguageSaga(action) {
    const languageId = action.languageId
    yield remove_language(languageId)
    yield put(actions.removeLanguageSuccess(languageId));
  });

  yield takeEvery(actionTypes.GET_LANGUAGE_FLAGS, function* getFlagsSaga() {
    try {
      const response = yield get_language_flags();
      const data = yield response.data ?? [];
      yield put(actions.getLanguageFlagsSuccess(data.flags));
    } catch {
      yield put(actions.getLanguageFlagsSuccess([]));
    }
  });

}
