import { put, takeEvery } from 'redux-saga/effects';

import { get_events, get_event, update_event, add_event, remove_event, get_participants_list, add_participants, remove_participant } from "../../crud/event.crud"

export const actionTypes = {
  GET_EVENTS: "GET_EVENTS",
  RENDER_EVENT_LIST: "RENDER_EVENT_LIST",
  UPDATE_EVENTS: "UPDATE_EVENTS",
  UPDATE_EVENTS_SUCCESS: "UPDATE_EVENTS_SUCCESS",
  UPDATE_EVENT_FAIL: "UPDATE_EVENT_FAIL",
  ADD_EVENT: "ADD_EVENT",
  ADD_EVENT_SUCCESS: "ADD_EVENT_SUCCESS",
  REMOVE_EVENT: "REMOVE_EVENT",
  REMOVE_EVENT_SUCCESS: "REMOVE_EVENT_SUCCESS",
  GET_PARTICIPANTS_LIST: "GET_PARTICIPANTS_LIST",
  GET_PARTICIPANTS_LIST_SUCCESS: "GET_PARTICIPANTS_LIST_SUCCESS",
  ADD_PARTICIPANTS: "ADD_PARTICIPANTS",
  ADD_PARTICIPANTS_SUCCESS: "ADD_PARTICIPANTS_SUCCESS",
  REMOVE_PARTICIPANT: "REMOVE_PARTICIPANT",
  REMOVE_PARTICIPANT_SUCCESS: "REMOVE_PARTICIPANT_SUCCESS",
  GET_EVENT: "GET_EVENT",
  GET_EVENT_SUCCESS: "GET_EVENT_SUCCESS"
};


export const actions = {
  getEvents: () => ({ type: actionTypes.GET_EVENTS }),
  getEvent: (id) => ({ type: actionTypes.GET_EVENT, event_id: id }),
  getEventSuccess: event => ({ type: actionTypes.GET_EVENT_SUCCESS, event: event }),
  renderEventList: eventList => ({ type: actionTypes.RENDER_EVENT_LIST, eventList: eventList }),
  updateEvents: event => ({ type: actionTypes.UPDATE_EVENTS, newEvent: event }),
  updateEventsSuccess: (event) => ({ type: actionTypes.UPDATE_EVENTS_SUCCESS, newEvent: event }),
  updateEventFail: (msg) => ({ type: actionTypes.UPDATE_EVENT_FAIL, msg}),
  addEvent: event => ({ type: actionTypes.ADD_EVENT, newEvent: event }),
  addEventSuccess: event => ({ type: actionTypes.ADD_EVENT_SUCCESS, newEvent: event }),
  removeEvent: eventId => ({ type: actionTypes.REMOVE_EVENT, eventId: eventId }),
  removeEventSuccess: eventId => ({ type: actionTypes.REMOVE_EVENT_SUCCESS, eventId: eventId }),
  getParticipantsList: eventId => ({ type: actionTypes.GET_PARTICIPANTS_LIST, eventId: eventId }),
  getParticipantsListSuccess: participantsList => ({ type: actionTypes.GET_PARTICIPANTS_LIST_SUCCESS, participantsList: participantsList }),
  addParticipants: (eventId, userIds) => ({ type: actionTypes.ADD_PARTICIPANTS, eventId: eventId, userIds: userIds }),
  addParticipantsSuccess: () => ({ type: actionTypes.ADD_PARTICIPANTS_SUCCESS }),
  removeParticipant: participantId => ({ type: actionTypes.REMOVE_PARTICIPANT, participantId: participantId }),
  removeParticipantSuccess: participantId => ({ type: actionTypes.REMOVE_PARTICIPANT_SUCCESS, participantId: participantId }),
};

const initialEventsState = {
  eventList: [],
  participantsList: [],
  isEvent: false
};


export const reducer = (state = initialEventsState, action) => {
  switch (action.type) {
    case actionTypes.RENDER_EVENT_LIST: {
      return {
        ...state,
        eventList: action.eventList
      }
    }
    case actionTypes.GET_EVENT: {
      return {
        ...state,
        isEvent: false
      }
    }
    case actionTypes.GET_EVENT_SUCCESS: {
      return {
        ...state,
        event: action.event,
        isEvent: true
      }
    }
    case actionTypes.ADD_EVENT_SUCCESS: {
      let newEventList = [
        ...state.eventList,
        {
          ...action.newEvent
        }
      ];
      return {
        ...state,
        eventList: newEventList
      }
    }
    case actionTypes.UPDATE_EVENTS_SUCCESS: {
      let oldEventList = state.eventList
      const newEventList = oldEventList.map(event => {
        if (event.id === action.newEvent.id) {
          return action.newEvent
        } else {
          return event
        }
      })
      return {
        ...state,
        eventList: newEventList
      }
    }
    case actionTypes.UPDATE_EVENT_FAIL: {
      return {
        ...state,
        msg: action.msg
      }
    }
    case actionTypes.REMOVE_EVENT_SUCCESS: {
      let oldEventList = [...state.eventList];
      const newEventList = oldEventList.filter(event => event.id !== action.eventId);
      return {
        ...state,
        eventList: newEventList
      }
    }
    case actionTypes.GET_PARTICIPANTS_LIST_SUCCESS: {
      return {
        ...state,
        participantsList: action.participantsList
      }
    }
    case actionTypes.REMOVE_PARTICIPANT_SUCCESS: {
      let oldParticipantsList = [...state.participantsList];
      const newParticipantsList = oldParticipantsList.filter(participant => participant.id !== action.participantId);
      return {
        ...state,
        participantsList: newParticipantsList
      }
    }
    default:
      return state;
  }
};

export function* eventsSaga() {
  yield takeEvery(actionTypes.GET_EVENTS, function* getEventsSaga() {
    try {
      const response = yield get_events();
      const data = yield response.data ?? [];
      yield put(actions.renderEventList(data.data));
    } catch {
      yield put(actions.renderEventList([]));
    }
  });

  yield takeEvery(actionTypes.GET_EVENT, function* getEventSaga(action) {
    try {
      const response = yield get_event(action.event_id);
      const data = yield response.data;
      yield put(actions.getEventSuccess(data.data));
    } catch {
      yield put(actions.getEventSuccess({}));
    }
  });


  yield takeEvery(actionTypes.UPDATE_EVENTS, function* updateEventsSaga(action) {
    const newEvent = action.newEvent
    const response = yield update_event(newEvent.id, newEvent)
    try {
      yield put(actions.updateEventsSuccess(response.data.event));
    } catch {
      yield put(actions.updateEventsFail('Something is wrong. Please try later.'))
    }
  });

  yield takeEvery(actionTypes.ADD_EVENT, function* addEventSaga(action) {
    const newEvent = action.newEvent
    const response = yield add_event(newEvent)
    yield put(actions.addEventSuccess(response.data.data));
  });

  yield takeEvery(actionTypes.REMOVE_EVENT, function* removeEventSaga(action) {
    const eventId = action.eventId
    yield remove_event(eventId)
    yield put(actions.removeEventSuccess(eventId));
  });

  yield takeEvery(actionTypes.GET_PARTICIPANTS_LIST, function* getParticipantsSaga(action) {
    try {
      const response = yield get_participants_list(action.eventId);
      const data = yield response.data ?? [];
      yield put(actions.getParticipantsListSuccess(data.participants));
    } catch {
      yield put(actions.getParticipantsListSuccess([]));
    }
  });

  yield takeEvery(actionTypes.ADD_PARTICIPANTS, function* addParticipantsSaga(action) {
    const userIds = action.userIds
    const eventId = action.eventId;
    yield add_participants(eventId, userIds)
    yield put(actions.getParticipantsList(eventId));
  });

  yield takeEvery(actionTypes.REMOVE_PARTICIPANT, function* removeParticipantsSaga(action) {
    const participantId = action.participantId
    yield remove_participant(participantId)
    yield put(actions.removeParticipantSuccess(participantId));
  });
}
