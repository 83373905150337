import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {
    makeStyles,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Grid,
} from "@material-ui/core";
import {
    Portlet,
    PortletBody,
} from "../../../../partials/content/Portlet";
import Notify from "../../../../components/Notify"
import EventLayout from "../../../../components/EventLayout"

import * as eventsStore from "../../../../store/ducks/emitters/index.duck";
import Video from "./Video"

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    title: {
        display: 'inline-block'
    },
    description: {
        display: 'inline-block',
        margin: theme.spacing(1)
    },
    eventPlace: {
        marginTop: 20
    },

    videoContainer: {
        flex: '100%',
        maxWidth: 'calc(100% - 380px)'
    },
    control: {
        width: 340,
        maxWidth: 340,
        marginLeft: 'auto',
        marginTop: 5
    },
    fullWidthControl: {
        position: 'absolute',
        right: 20
    },
    headerContent: {
        margin: 0,
    },
    headerShadowNone: {
        boxShadow: 'none !important'
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        // minWidth: 350
    },
    tableWrapper: {
        overflowX: "auto"
    },
    button: {
        margin: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
}));

function Event(props) {
    const { event, isStartMeeting, getEvent, startMeeting, endMeeting, screenMode, intl } = props
    const classes = useStyles();
    const event_id = props.match.params.id;

    useEffect(() => {
        getEvent(event_id);
    }, [event_id, getEvent]);

    function handleStartMeeting() {
        startMeeting();
        const headerMenu = document.getElementById('kt_header_menu')
        const appendContent = document.getElementById('content_header')
        headerMenu.appendChild(appendContent)
    }

    function handleEndMeeting() {
        endMeeting()
    }

    return (
        <>
            <EventLayout event={event} screenMode={screenMode} isStartMeeting={isStartMeeting}>
                <div className={`row ${!isStartMeeting && classes.eventPlace}`} id="eventPlace">
                    <div className={`col-md-8 ${classes.videoContainer}`} id="videoPlace">
                        <Notify />
                        {event && isStartMeeting ? (<Video event={event} size="sm" />) : ''}
                    </div>
                    <div className={`col-md-4 ${classes.control} ${screenMode < 0 && classes.fullWidthControl}`}>
                        <Portlet>
                            <PortletBody>
                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table}>
                                        <TableBody>

                                            <TableRow key="detail">
                                                <TableCell component="th" scope="row">
                                                    {intl.formatMessage({ id: "EVENTS.TABLE_HEADER.DETAIL" })}
                                                </TableCell>
                                                <TableCell>{event ? event.detail : ''}</TableCell>
                                            </TableRow>
                                            <TableRow key="start_datetime">
                                                <TableCell component="th" scope="row">
                                                    {intl.formatMessage({ id: "EVENTS.TABLE_HEADER.START_DATE" })}
                                                </TableCell>
                                                <TableCell>{event ? event.start_datetime : ''}</TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </div>
                                <Grid container className={classes.root} spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Button className={classes.paper} disabled={isStartMeeting} onClick={handleStartMeeting}>{intl.formatMessage({ id: "EVENT.ACTION.START_MEETING" })}</Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button className={classes.paper} disabled={!isStartMeeting} onClick={handleEndMeeting}>{intl.formatMessage({ id: "EVENT.ACTION.END_MEETING" })}</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </PortletBody>
                        </Portlet>
                    </div>
                </div>
            </EventLayout>
        </>
    )
}

const mapStateToProps = state => {
    return {
        event: state.emitterReducer.event,
        isStartMeeting: state.emitterReducer.isStartMeeting,
        isEvent: state.emitterReducer.isEvent,
        screenMode: state.emitterReducer.screenMode,
        user: state.auth.user,
    };
};

const mapDispatchToProps = dispatch => ({
    getEvent: (id) => dispatch(eventsStore.actions.getEmitterEvent(id)),
    startMeeting: () => dispatch(eventsStore.actions.startMeeting()),
    endMeeting: () => dispatch(eventsStore.actions.endMeeting()),

})

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Event)
);