import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { colors } from "@material-ui/core";
import { Button, Grid, makeStyles } from "@material-ui/core";

import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import Notify from "../../../../components/Notify";
import * as eventStore from "../../../../store/ducks/event.duck";
import * as eventsStore from "../../../../store/ducks/interpreters/index.duck";
// import ConferenceVideo from "./ConferenceVideo";
import MeetingVideo from "./MeetingVideo";
import EventLayout from "../../../../components/EventLayout";
import ChannelController from "./ChannelController";
import Sidebar from "./Sidebar";
import Toolbar from "./Toolbar";
import { jitsiMeeting } from "../../../../components/JitsiMeeting";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    title: {
        display: "inline-block"
    },
    description: {
        display: "inline-block",
        margin: theme.spacing(1)
    },
    eventInterPlace: {
        overflowX: "hidden"
    },
    eventPlace: {
        marginTop: 20
    },
    videoContainer: {
        flex: "100%",
        maxWidth: "calc(100% - 340px)"
    },
    control: {
        width: 340,
        maxWidth: 340,
        marginLeft: "auto"
    },
    fullWidthControl: {
        position: "absolute",
        right: 20
    },
    headerContent: {
        margin: 0
    },
    headerShadowNone: {
        boxShadow: "none !important"
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        // minWidth: 350
    },
    tableWrapper: {
        overflowX: "auto"
    },
    buttonDisable: {
        margin: theme.spacing(1),
        padding: 5,
        opacity: 0,
        cursor: "default !important"
    },
    rowHidden: {
        display: "none"
    },
    buttonHidden: {
        display: "none"
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    volumeControl: {
        display: "flex",
        justifyContent: "center",
        // alignItems: 'flex-end',
        marginBottom: theme.spacing(1),
        backgroundColor: "#BEC2CB",
        flexDirection: "column",
        alignItems: "center"
    },
    volume: {
        margin: theme.spacing(1)
    },
    volumeText: {
        wordBreak: "keep-all"
    },
    volumeValue: {
        paddingLeft: 0,
        paddingRight: 0,
        fontWeight: 600
    },
    ctlContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column"
        // height: '1.5rem'
    },
    ctlTopFirstLine: {
        height: "1.6rem",
        alignSelf: "flex-start"
    },
    ctlTop: {
        alignSelf: "flex-start"
    },
    ctlBtn: {
        alignSelf: "flex-end",
        fontSize: "0.8rem",
        fontWeight: 700,
        lineHeight: "8px",
        textTransform: "lowercase"
    },
    langNum: {
        fontWeight: 600
    },
    ctlLang: {
        display: "inline-block",
        marginRight: 5
    },
    flag: {
        width: 32,
        height: 24,
        marginRight: theme.spacing(1),
        borderRadius: 4
    },
    formControl: {
        width: "100%"
    },
    langSelector: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        marginBottom: theme.spacing(1),
        backgroundColor: colors.common.white
    }
}));

const ORIGINAL = <FormattedMessage id="EVENT.LANG.ORIGINAL" />;
const ORIGINAL_ROOMNAME = "original";

function Event(props) {
    const {
        event,
        isStartMeeting,
        getEvent,
        startMeeting,
        endMeeting,
        user,
        isLoading,
        screenMode,
        intl
    } = props;
    const classes = useStyles();
    const event_id = props.match.params.id;
    const [volume, setVolume] = React.useState(0.5);
    const [bass, setBass] = React.useState(4);
    const [treble, setTreble] = React.useState(4);
    const [streamMode, setStreamMode] = React.useState(false);
    const [selectedLangRoom, setSelectedLangRoom] = React.useState(0);
    const [emitterRoom, setEmitterRoom] = React.useState({});
    const [otherInterRooms, setOtherInterRooms] = React.useState([]);
    const [outputRoomname, setOutputRoomname] = React.useState("");
    const [inputRoomname, setInputRoomname] = React.useState(null);
    const [disableAudio, setDisableAudio] = React.useState(true);
    const [inputLanguage, setInputLanguage] = React.useState("");
    const [outputLanguage, setOutputLanguage] = React.useState(ORIGINAL);
    const [clickedLanguageKey, setClickedLanguageKey] = React.useState(0);
    const [startLoading, setStartLoading] = React.useState(false);
    const [liquidUrl, setLiquidUrl] = React.useState("");
    const [floorOn, setFloorOn] = React.useState(false);
    const [outputChannel, setOutputChannel] = React.useState(0);

    const enterBtn = React.useRef(null);
    const delBtn = React.useRef(null);
    const numBtn = React.useRef(null);
    const ch1Btn = React.useRef(null);
    const ch2Btn = React.useRef(null);

    const micBtn = React.useRef(false);
    const muteBtn = React.useRef(false);
    const floorBtn = React.useRef(true);

    useEffect(() => {
        if (event_id) {
            getEvent(event_id);
            window.addEventListener("keydown", handleKeyDown);
            setStartLoading(true);
        }
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            setStartLoading(false);
        };
    }, [event_id]);

    useEffect(() => {
        handleConsoleLanguageBtn(clickedLanguageKey);
    }, [clickedLanguageKey]);

    useEffect(() => {
        if (event) {
            init();
        }
    }, [event]);

    function handleStartMeeting() {
        startMeeting();
        const headerMenu = document.getElementById("kt_header_menu");
        const appendContent = document.getElementById("content_header");
        headerMenu.appendChild(appendContent);
    }

    function handleEndMeeting() {
        endMeeting();
    }

    function handleVolumeUp() {
        setVolume(prevState => {
            if (prevState <= 0.9) {
                return prevState + 0.1;
            } else {
                return 1.0;
            }
        });
    }

    function handleVolumeDown() {
        setVolume(prevState => {
            if (prevState >= 0.1) {
                return prevState - 0.1;
            } else {
                return 0.0;
            }
        });
    }

    function handleBassDown() {
        setBass(prevState => {
            if (prevState >= 1) {
                return prevState - 1;
            } else {
                return 0;
            }
        });
    }

    function handleBassUp() {
        setBass(prevState => {
            if (prevState <= 39) {
                return prevState + 1;
            } else {
                return 40;
            }
        });
    }

    function handleTrebleDown() {
        setTreble(prevState => {
            if (prevState >= 1) {
                return prevState - 1;
            } else {
                return 0;
            }
        });
    }

    function handleTrebleUp() {
        setTreble(prevState => {
            if (prevState <= 39) {
                return prevState + 1;
            } else {
                return 40;
            }
        });
    }

    function init() {
        const event_rooms = event.event_rooms;
        const selfRoom = event.event_rooms.filter(
            event_room => event_room.interpreter_id === user.id
        );
        const emitterRoom = event.event_rooms.filter(
            event_room => event_room.interpreter_id === 0
        );

        setLiquidUrl(selfRoom[0].wss_url);

        var resArr = [];
        event_rooms.filter(function(item) {
            var i = resArr.findIndex(x => x.room_name === item.room_name);
            if (i <= -1) {
                resArr.push(item);
            }
            return null;
        });
        if (resArr) {
            const otherInterRooms = [];
            otherInterRooms.push(emitterRoom[0]);
            setOutputRoomname(`non-${selfRoom[0].room_name}`);
            // setSelfRoomname(selfRoom[0].room_name);
            for (var i = 0; i < resArr.length; i++) {
                const event_room = resArr[i];
                if (event_room.interpreter_id === 0) {
                    setEmitterRoom(event_room);
                    setInputRoomname(ORIGINAL_ROOMNAME);
                } else {
                    otherInterRooms.push(event_room);
                }
            }
            setOtherInterRooms(otherInterRooms);
        }
        if (!disableAudio) {
            setOutputLanguage(selfRoom[0].output_lang_name);
        }
        setInputLanguage(ORIGINAL);
    }

    function handleConsoleLanguageBtn(value) {
        if (value === 0) {
            setSelectedLangRoom(0);
            setInputRoomname(ORIGINAL_ROOMNAME);
            setInputLanguage(ORIGINAL);
            setFloorOn(false);
        } else {
            if (otherInterRooms[value - 1]) {
                setSelectedLangRoom(value);
                setInputRoomname(otherInterRooms[value - 1].room_name);

                setInputLanguage(otherInterRooms[value - 1].output_lang_name);
            }
        }
    }

    function handleKeyDown(event) {
        if (event.keyCode >= 96 && event.keyCode <= 105) {
            // floorBtn.current = event.keyCode === 96;
            // setClickedLanguageKey(event.keyCode - 96);
        } else if (event.keyCode >= 48 && event.keyCode <= 57) {
            // setClickedLanguageKey(event.keyCode - 48);
        } else {
            const activeElement = document.activeElement;
            switch (event.keyCode) {
                // case 110:
                //     muteBtn.current.click();
                //     break;
                case 13:
                    if (activeElement.id !== "chatInput") {
                        // enterBtn.current.click();
                    }
                    break;
                // case 107:
                //     handleVolumeUp();
                //     break;
                // case 109:
                //     handleVolumeDown();
                //     break;
                case 8:
                    if (activeElement.id !== "chatInput") {
                        // delBtn.current.click();
                    }
                    break;
                // case 106:
                //     ch2Btn.current.click();
                //     break;
                // case 111:
                //     ch1Btn.current.click();
                //     break;
                // case 144:
                //     numBtn.current.click();
                //     break;
                // case 189:
                //     handleTrebleDown();
                //     break;
                // case 187:
                //     handleTrebleUp();
                //     break;
                // case 219:
                //     handleBassDown();
                //     break;
                // case 221:
                //     handleBassUp();
                //     break;
                case 32:
                    if (activeElement.id !== "chatInput") {
                        // jitsiMeeting.setIsWithFloor(!jitsiMeeting.isWithFloor);
                    }
                    break;
                case 40: // Down Arrow
                    if (event.ctrlKey) {
                        micBtn.current = !micBtn.current;
                        jitsiMeeting.changeMicOn(micBtn.current);
                    }
                    break;
                case 39: // Right Arrow
                    if (event.ctrlKey) {
                        setOutputChannel(value => (value === 2 ? 0 : ++value));
                    }
                    break;
                case 38: // Up Arrow
                    if (event.ctrlKey) {
                        if (micBtn.current) {
                            muteBtn.current = !muteBtn.current;
                            jitsiMeeting.changeMute(muteBtn.current);
                        }
                    }
                    break;
                case 37: // Left Arrow
                    if (event.ctrlKey) {
                        floorBtn.current = !floorBtn.current;
                        setClickedLanguageKey(floorBtn.current ? 0 : 1);

                        // withFloorBtn.current = !withFloorBtn.current;
                        // jitsiMeeting.setIsWithFloor(withFloorBtn.current);
                    }
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <>
            {event ? (
                <EventLayout
                    event={event}
                    screenMode={screenMode}
                    isStartMeeting={isStartMeeting}
                    fullElement="eventPlace"
                    hidden={isStartMeeting}
                >
                    <Grid
                        container
                        className={`${
                            isStartMeeting
                                ? classes.eventInterPlace
                                : classes.eventPlace
                        }`}
                        id="eventPlace"
                    >
                        <Grid xs={8} item className={classes.videoContainer}>
                            <Notify />
                            {event && isStartMeeting ? (
                                <>
                                    <MeetingVideo
                                        event={event}
                                        streamMode={streamMode}
                                        liquidUrl={liquidUrl}
                                        bass={bass}
                                        treble={treble}
                                    />

                                    <ChannelController
                                        event={event}
                                        clickedLanguageKey={clickedLanguageKey}
                                        outputChannel={outputChannel}
                                    />
                                </>
                            ) : (
                                ""
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            className={`${classes.control} ${
                                screenMode < 0 ? classes.fullWidthControl : ""
                            }`}
                        >
                            <Grid>
                                {isStartMeeting ? (
                                    <Sidebar event={event} />
                                ) : (
                                    <>
                                        <Grid
                                            item
                                            xs={12}
                                            className="text-right"
                                        >
                                            <Button
                                                target="_blank"
                                                href="http://olyusei.speedtestcustom.com/"
                                                color="primary"
                                            >
                                                Speed Test
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Portlet>
                                                <PortletBody>
                                                    <Grid
                                                        container
                                                        className={classes.root}
                                                    >
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                >
                                                                    <Button
                                                                        className={
                                                                            classes.paper
                                                                        }
                                                                        disabled={
                                                                            isStartMeeting
                                                                        }
                                                                        onClick={
                                                                            handleStartMeeting
                                                                        }
                                                                    >
                                                                        {intl.formatMessage(
                                                                            {
                                                                                id:
                                                                                    "EVENT.ACTION.START_MEETING"
                                                                            }
                                                                        )}
                                                                    </Button>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                >
                                                                    <Button
                                                                        className={
                                                                            classes.paper
                                                                        }
                                                                        disabled={
                                                                            !isStartMeeting
                                                                        }
                                                                        onClick={
                                                                            handleEndMeeting
                                                                        }
                                                                    >
                                                                        {intl.formatMessage(
                                                                            {
                                                                                id:
                                                                                    "EVENT.ACTION.END_MEETING"
                                                                            }
                                                                        )}
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </PortletBody>
                                            </Portlet>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Grid xs={12} item>
                            {isStartMeeting && <Toolbar />}
                        </Grid>
                    </Grid>
                </EventLayout>
            ) : (
                <div className="kt-error-v3">
                    <div className="kt-error_container">
                        <p className="kt-error_subtitle">
                            {isLoading && startLoading
                                ? intl.formatMessage({
                                      id: "EVENTS.EVENT_ERROR"
                                  })
                                : ""}
                        </p>
                    </div>
                </div>
            )}
        </>
    );
}

const mapStateToProps = state => {
    return {
        event: state.interpreterReducer.event,
        user: state.auth.user,
        isLoading: state.interpreterReducer.isLoading,
        isStartMeeting: state.event.isStartMeeting,
        screenMode: state.event.screenMode,
        participants: state.event.participants,
        isWithFloor: state.event.isWithFloor
    };
};

const mapDispatchToProps = dispatch => ({
    getEvent: id => dispatch(eventsStore.actions.getInterpreterEvent(id)),
    startMeeting: () => dispatch(eventStore.actions.startMeeting()),
    endMeeting: () => dispatch(eventStore.actions.endMeeting())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Event));
