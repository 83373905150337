import React, { useState } from "react";
import { makeStyles, colors } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
const useStyles = makeStyles(theme => ({
    videoToolbox: {
        position: "absolute",
        bottom: 3,
        color: "#646c9a"
    },
    audioToolbox: {
        position: "absolute",
        bottom: 3,
        color: "#646c9a",
        right: 0
    },
    toolboxMenuContainer: {
        position: "absolute",
        bottom: 0,
        right: "80%",
        background: "gray",
        listStyle: "none",
        margin: 0,
        marginRight: 5,
        padding: 0,
        borderRadius: 5,
        color: colors.common.white,
        "& li": {
            whiteSpace: "nowrap",
            padding: 5
        },
        "& li:hover": {
            background: "rgba(180, 180, 180, 0.5)"
        }
    },
    notifyListMenuContainer: {
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: 140
    },
    popupMenuLeft: {
        position: "absolute",
        height: "100%",
        width: 50,
        top: 0,
        bottom: 0,
        left: -30
    },
    popupMenuRight: {
        position: "absolute",
        height: "100%",
        width: 50,
        top: 0,
        bottom: 0,
        right: -30
    },
    actionMenu: {
        color: colors.common.white
    }
}));

const messageList = [
    { msg_en: "Ready for handover" },
    { msg_en: "Handover now" },
    { msg_en: "Wrong OUT CH" },
    { msg_en: "Check MUTE" },
    { msg_en: "Check MIC" }
];

function GroupTranslatorRemoteVideo(props) {
    const {
        id,
        displayName,
        openChatBox,
        setToParticipant,
        lang,
        sendPreMsg,
        isMuted,
        isMicOn
    } = props;
    const [isHoverMore, setIsHoverMore] = useState(false);
    const [isHoverNotifyList, setIsHoverNotifyList] = useState(false);
    const classes = useStyles();

    function _handleHoverMoreBtn(value) {
        setIsHoverMore(value);
    }

    function _handleHoverNotify(val) {
        setIsHoverNotifyList(val);
    }

    function _handlePrivateMessage() {
        setIsHoverMore(false);
        openChatBox(true);
        setToParticipant(id);
    }

    function _handleSelectPreMsg(e, i) {
        e.preventDefault();
        console.log(messageList[i]);
        sendPreMsg(id, messageList[i]);
    }

    return (
        <>
            <div
                className={`${props.baseClasses.videoContainer} videoContainer`}
            ></div>
            <div className="displayName">{displayName}</div>
            <div
                className={classes.videoToolbox}
                onMouseEnter={() => _handleHoverMoreBtn(true)}
                onMouseLeave={() => _handleHoverMoreBtn(false)}
            >
                <MoreVertIcon className={classes.actionMenu} />
                {isHoverMore && (
                    <ul className={classes.toolboxMenuContainer}>
                        <li onClick={_handlePrivateMessage}>Send Message</li>
                        {messageList.map((message, i) => (
                            <li
                                key={i}
                                onClick={e => _handleSelectPreMsg(e, i)}
                            >
                                {message["msg_en"]}
                            </li>
                        ))}
                        {/* {isNotify && */}
                        {/* <li onMouseEnter={() => _handleHoverNotify(true)} onMouseLeave={() => _handleHoverNotify(false)}>
                            Notify <div className={classes.popupMenuLeft}></div>{
                                isHoverNotifyList &&
                                // messageList && messageList.length > 0 &&
                                <ul className={`${classes.toolboxMenuContainer} ${classes.notifyListMenuContainer}`}>
                                    {
                                        messageList.map((message, i) => (
                                            <li key={i} onClick={(e) => _handleSelectPreMsg(e, i)}>
                                                {message}
                                            </li>
                                        ))
                                    }
                                    <div className={classes.popupMenuRight}></div>
                                </ul>
                            }
                        </li> */}
                        {/* } */}
                    </ul>
                )}
            </div>
            <div className={classes.audioToolbox}>
                {!isMuted && isMicOn ? (
                    <VolumeUpIcon style={{ color: colors.grey[500] }} />
                ) : (
                    <VolumeOffIcon style={{ color: colors.green[500] }} />
                )}
                {isMicOn ? (
                    <MicIcon style={{ color: colors.red[500] }} />
                ) : (
                    <MicOffIcon style={{ color: colors.grey[500] }} />
                )}
            </div>
        </>
    );
}

export default GroupTranslatorRemoteVideo;
