import React from "react";
import { FormattedMessage } from "react-intl";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
} from "@material-ui/core";

export default function ConfirmDlg(props) {

    const { isOpen, title, description, handleConfirm } = props

    function handleClickConfirm() {
        handleConfirm(true)
    }

    function handleCloseDel() {
        handleConfirm(false)
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleCloseDel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDel} color="primary">
                    <FormattedMessage id="EVENT.ACTION.CANCEL" />
                </Button>
                <Button onClick={handleClickConfirm} color="primary" autoFocus>
                    <FormattedMessage id="DIALOG.ACTION.CONTINUE" />
                </Button>
            </DialogActions>
        </Dialog>
    )

}

