import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import GroupIcon from "@material-ui/icons/Group";
import {
    makeStyles,
    Button,
    colors,
    Badge,
    IconButton,
    Fab
} from "@material-ui/core";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import CallEndIcon from "@material-ui/icons/CallEnd";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import RemoveFromQueueIcon from "@material-ui/icons/RemoveFromQueue";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import SettingsVoiceIcon from "@material-ui/icons/SettingsVoice";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import SpeakerIcon from "@material-ui/icons/Speaker";
import MessageIcon from "@material-ui/icons/Message";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { Dropdown } from "react-bootstrap";
import CustomMenu from "../../../../components/CustomMenu";
import CustomToggle from "../../../../components/CustomToggle";
import OscilloMeter from "../../../../components/OscilloMeter";
import GroupTranslator from "../../../../components/GroupTranslator";
import * as eventStore from "../../../../store/ducks/event.duck";
import * as eventsStore from "../../../../store/ducks/admins/index.duck";
import { UserRole } from "../../../../components/utils/UserRole";
import {
    jitsiMeeting,
    ORIGINAL_ROOMNAME
} from "../../../../components/JitsiMeeting";
import ToggleButton from "../../../../components/ToggleButton";

const useStyles = makeStyles(theme => ({
    toolbox: {
        // position: "absolute",
        bottom: props => (props.isToolbarShow ? 0 : -56),
        left: "auto",
        right: "auto",
        zIndex: "500",
        width: "100%",
        // backgroundImage: "linear-gradient(to top,rgba(0,0,0,.6),rgba(0,0,0,0))",
        backgroundColor: "#525050",
        display: "flex",
        justifyContent: "center",
        transition: "bottom .3s ease-in"
    },
    toolboxLeft: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        alignSelf: "center",
        width: "38%"
    },
    toolboxCenter: {
        display: "flex",
        // justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        width: "24%"
    },
    toolboxRight: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        alignSelf: "center",
        width: "38%"
    },
    shareScreen: {
        margin: 6,
        color: colors.common.white,
        minWidth: "40px !important",
        [theme.breakpoints.down("xs")]: {
            height: "30px !important",
            width: "30px !important",
            minWidth: "30px !important",
            minHeight: "30px !important",
            margin: 2
        }
    },
    call: {
        margin: theme.spacing(1),
        backgroundColor: colors.red[800],
        color: colors.common.white,
        minWidth: "40px !important",
        [theme.breakpoints.down("xs")]: {
            height: "30px !important",
            width: "30px !important",
            minWidth: "30px !important",
            minHeight: "30px !important",
            margin: 2
        },
        "&:hover": {
            color: colors.common.white,
            backgroundColor: colors.red[300]
        }
    },
    videoMute: {
        height: "40px !important",
        minWidth: "40px !important",
        borderRadius: "50% !important",
        [theme.breakpoints.down("xs")]: {
            height: "30px !important",
            width: "30px !important",
            minWidth: "30px !important",
            minHeight: "30px !important",
            margin: 2
        }
    },
    mosaicButton: {
        padding: 10
    },
    volume: {
        color: colors.common.white,
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 8,
            paddingRight: 8
        }
    },
    button: {
        color: colors.common.white,
        backgroundColor: colors.common.black,
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 8,
            paddingRight: 8
        }
    },
    oscilloMeter: {
        position: "relative"
    },
    toolbarItem: {
        [theme.breakpoints.down("xs")]: {
            display: "none"
        }
    },
    settingItem: {
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    }
}));

function Toolbar(props) {
    const {
        event,
        isMicOn,
        isMuted,
        isCameraOn,
        isChatBoxOpen,
        isNewMsg,
        isVideo,
        isRaise,
        isMosaic,
        cameraDevices,
        isShareOtherCamera,
        isMuteAll,
        isInterpreter,
        disableCamera,
        isOscilloMeter,
        isGroupTranslator,
        isWithFloor,
        isSubTitle,
        inputRoomname,
        intl
    } = props;
    const classes = useStyles(props);

    function handleCameraOn() {
        if (!disableCamera) jitsiMeeting.changeCameraOn(!isCameraOn);
    }

    function handleChat() {
        props.openChatBox(!isChatBoxOpen);
    }

    function handleLeave() {
        props.endMeeting();
    }

    function shareOtherCamera() {
        jitsiMeeting.changeShareOtherCamera(!isShareOtherCamera);
    }

    function switchVideo(e) {
        jitsiMeeting.setIsVideo(!isVideo);
    }

    function handleMosaicView() {
        props.updateIsMosaic(!isMosaic);
    }

    function handleSetSize(mode) {
        props.updateScreenMode(mode);
    }

    function handleVideoSetting() {
        props.setOpenVideoSettingDlg(true);
    }

    function handleAudioOutputSetting() {
        props.setOpenAudioOutputSettingDlg(true);
    }

    function handleAudioInputSetting(params) {
        props.setOpenAudioInputSettingDlg(true);
    }

    function handleOscilloMeter() {
        props.setIsOscilloMeter(!isOscilloMeter);
    }

    function handleGroupTranslator() {
        props.setIsGroupTranslator(!isGroupTranslator);
    }

    function handleReset() {
        window.location.reload();
    }

    return (
        <div id="toolbox" className={classes.toolbox}>
            <div id="toolboxLeft" className={classes.toolboxLeft}>
                <ToggleButton
                    selected={isWithFloor}
                    onChanged={value => {
                        jitsiMeeting.setIsWithFloor(value);
                    }}
                >
                    With Floor
                </ToggleButton>
                <ToggleButton
                    selected={isSubTitle}
                    onChanged={value => {
                        jitsiMeeting.setIsSubTitle(value);
                    }}
                >
                    Subtitles
                </ToggleButton>
                <ToggleButton
                    selected={inputRoomname === ORIGINAL_ROOMNAME}
                    onChanged={() => {
                        jitsiMeeting.changeLangTrack(ORIGINAL_ROOMNAME);
                    }}
                >
                    0 Floor
                </ToggleButton>
            </div>
            <div id="toolboxCenter" className={classes.toolboxCenter}>
                <ToggleButton
                    selected={isMicOn}
                    onChanged={value => {
                        jitsiMeeting.changeMicOn(value);
                    }}
                >
                    <span style={{ width: 120 }}>Mic</span>
                </ToggleButton>
                <ToggleButton
                    selected={isMuted}
                    onChanged={value => {
                        if (isMicOn) jitsiMeeting.changeMute(value);
                    }}
                >
                    {isMuted ? "Muted" : "Mute"}
                </ToggleButton>
                <Fab
                    color="secondary"
                    size="small"
                    aria-label="Chat"
                    className={classes.shareScreen}
                    onClick={handleChat}
                >
                    <Badge
                        className={classes.badge}
                        color="primary"
                        invisible={!isNewMsg}
                        variant="dot"
                        overlap="rectangular"
                    >
                        <MessageIcon />
                    </Badge>
                </Fab>
                {isCameraOn ? (
                    <Fab
                        color="secondary"
                        size="small"
                        aria-label="CameraOn"
                        onClick={handleCameraOn}
                        className={`${classes.videoMute} ${classes.shareScreen}`}
                        disabled={disableCamera}
                    >
                        <VideocamIcon />
                    </Fab>
                ) : (
                    <Fab
                        color="inherit"
                        size="small"
                        aria-label="CameraOff"
                        onClick={handleCameraOn}
                        className={`${classes.call} ${classes.videoMute} ${classes.shareScreen}`}
                        disabled={disableCamera}
                    >
                        <VideocamOffIcon />
                    </Fab>
                )}
            </div>
            <div id="toolbox-right" className={classes.toolboxRight}>
                <Fab
                    color="inherit"
                    size="small"
                    aria-label="CallEnd"
                    className={classes.call}
                    onClick={handleLeave}
                >
                    <CallEndIcon />
                </Fab>
                <div className={classes.oscilloMeter}>
                    <IconButton
                        color="primary"
                        aria-label="Group Translator"
                        className={classes.volume}
                        onClick={handleGroupTranslator}
                    >
                        <GroupIcon />
                    </IconButton>
                    {/* <GroupTranslator
                        event={event}
                        sendPreMsg={props.sendPreMsg}
                    /> */}
                </div>
                <div className={classes.oscilloMeter}>
                    <IconButton
                        color="primary"
                        aria-label="Channel Audios"
                        className={classes.volume}
                        onClick={handleOscilloMeter}
                    >
                        <EqualizerIcon />
                    </IconButton>
                    <OscilloMeter event={event} />
                </div>

                <IconButton
                    color="primary"
                    aria-label="Layout"
                    className={`${classes.volume} ${classes.mosaicButton} ${classes.toolbarItem}`}
                    onClick={handleMosaicView}
                >
                    <ViewModuleIcon />
                </IconButton>
                <Dropdown>
                    <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-basic"
                        drop={"up"}
                        navbar={false}
                    >
                        <IconButton
                            color="primary"
                            aria-label="Screen Size"
                            className={classes.volume}
                        >
                            <AspectRatioIcon />
                        </IconButton>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                        <Dropdown.Item
                            eventKey="1"
                            onClick={() => handleSetSize(0)}
                        >
                            <RemoveFromQueueIcon />{" "}
                            {intl.formatMessage({ id: "VIDEO.SCREEN.NORMAL" })}
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey="2"
                            onClick={() => handleSetSize(1)}
                        >
                            <FullscreenExitIcon />{" "}
                            {intl.formatMessage({ id: "VIDEO.SCREEN.LARGE" })}
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey="3"
                            onClick={() => handleSetSize(2)}
                        >
                            <FullscreenIcon />{" "}
                            {intl.formatMessage({ id: "VIDEO.SCREEN.FULL" })}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                    <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-basic"
                        drop={"up"}
                        navbar={false}
                    >
                        <IconButton
                            color="primary"
                            aria-label="More"
                            className={classes.volume}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                        <Dropdown.Item
                            eventKey="1"
                            onClick={handleVideoSetting}
                        >
                            <VideocamIcon />{" "}
                            {intl.formatMessage({
                                id: "VIDEO.VIDEO_INPUT"
                            })}
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey="3"
                            onClick={handleAudioInputSetting}
                        >
                            <SettingsVoiceIcon />{" "}
                            {intl.formatMessage({
                                id: "VIDEO.AUDIO_INPUT"
                            })}
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey="2"
                            onClick={handleAudioOutputSetting}
                        >
                            <SpeakerIcon />{" "}
                            {intl.formatMessage({
                                id: "VIDEO.AUDIO_OUTPUT"
                            })}
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey="5"
                            onClick={handleMosaicView}
                            className={classes.settingItem}
                        >
                            <ViewModuleIcon />{" "}
                            {intl.formatMessage({
                                id: "TOOLBOX.SETTING.LAYOUT"
                            })}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Button
                    variant="contained"
                    className={classes.button}
                    onClick={handleReset}
                >
                    Reset
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    const props = {
        user: state.auth.user,
        isMuted: state.event.isMuted,
        isMicOn: state.event.isMicOn,
        isCameraOn: state.event.isCameraOn,
        cameraDevices: state.event.cameraDevices,
        isChatBoxOpen: state.event.isChatBoxOpen,
        isNewMsg: state.event.isNewMsg,
        isRaise: state.event.isRaise,
        isMosaic: state.event.isMosaic,
        isVideo: state.event.isVideo,
        isShareOtherCamera: state.event.isShareOtherCamera,
        isOscilloMeter: state.event.isOscilloMeter,
        isGroupTranslator: state.event.isGroupTranslator,
        isToolbarShow: state.event.isToolbarShow,
        isWithFloor: state.event.isWithFloor,
        isSubTitle: state.event.isSubTitle,
        inputRoomname: state.event.inputRoomname
    };

    if (state.auth.user.role <= UserRole.ADMIN) {
        const adminProps = {
            isAdmin: true,
            isMuteAll: state.adminReducer.isMuteAll
        };
        return {
            ...props,
            ...adminProps
        };
    }

    if (state.auth.user.role === UserRole.EMITTER) {
        const emitterProps = {
            isEmitter: true
        };
        return {
            ...props,
            ...emitterProps
        };
    }

    if (state.auth.user.role === UserRole.INTERPRETER) {
        const interpreterProps = {
            isInterpreter: true
        };
        return {
            ...props,
            ...interpreterProps
        };
    }

    return props;
};

const mapDispatchToProps = dispatch => {
    const actions = {
        endMeeting: () => dispatch(eventStore.actions.endMeeting()),
        openChatBox: isChatBoxOpen =>
            dispatch(eventStore.actions.openChatBox(isChatBoxOpen)),
        setOpenAudioInputSettingDlg: isOpen =>
            dispatch(eventStore.actions.setOpenAudioInputSettingDlg(isOpen)),
        setOpenAudioOutputSettingDlg: isOpen =>
            dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen)),
        setOpenVideoSettingDlg: isOpen =>
            dispatch(eventStore.actions.setOpenVideoSettingDlg(isOpen)),
        setIsRaise: isRaise => dispatch(eventStore.actions.setIsRaise(isRaise)),
        updateScreenMode: mode =>
            dispatch(eventStore.actions.updateScreenMode(mode)),
        updateIsMosaic: isMosaic =>
            dispatch(eventStore.actions.updateIsMosaic(isMosaic)),
        setMuteAll: isMuteAll =>
            dispatch(eventsStore.actions.setMuteAll(isMuteAll)),
        setIsOscilloMeter: isOscilloMeter =>
            dispatch(eventStore.actions.setIsOscilloMeter(isOscilloMeter)),
        setIsGroupTranslator: isGroupTranslator =>
            dispatch(eventStore.actions.setIsGroupTranslator(isGroupTranslator))
    };
    return actions;
};

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(Toolbar)
);
