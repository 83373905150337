import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Iframe from "react-iframe";
import Chat from "../../../../../components/Chat";
import ToolBar from "../../../../../components/Toolbar";
import SelectDeviceDialog from "../../../../../components/SelectDeviceDialog";
import { toAbsoluteUrl } from "../../../../../../_metronic";
import {
    calcWidth,
    calcSidePlaceHeight
} from "../../../../../components/utils/VideoLayoutUtils";
import {
    options,
    initOptions,
    confOptions
} from "../../../../../components/utils/RoomUtils";
import { mutePresenterVideo } from "../../../../../components/utils/LocalVideoTrackUtils";
import { meetingVideo as useStyles } from "../../../../../components/CommonStyles";
import $ from "jquery";

import * as eventStore from "../../../../../store/ducks/event.duck";
import { UserRole } from "../../../../../components/utils/UserRole";
import MeetContainer from "../../../../../components/MeetContainer";
import { EventType } from "../../../../../components/utils/EventType";
import { ORIGINAL_ROOMNAME } from "../../../../../components/JitsiMeeting";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const JitsiMeetJS = window.JitsiMeetJS;

function WebinarVideo(props) {
    const {
        event,
        isEndMeeting,
        endMeetingSuccess,
        roomname,
        volume,
        isMuted,
        changeMute,
        changeCameraOn,
        isCameraOn,
        cameraDevices,
        setCameraDevices,
        isChatBoxOpen,
        openAudioOutputSettingDlg,
        setOpenAudioOutputSettingDlg,
        openVideoSettingDlg,
        setOpenVideoSettingDlg,
        openAudioInputSettingDlg,
        setOpenAudioInputSettingDlg,
        isVideo,
        setIsVideo,
        isShareOtherCamera,
        isRaise,
        openChatBox,
        changeMessageStatus,
        isMosaic,
        updateMessage,
        user,
        authToken,
        addStat,
        startStatId,
        showNotification,
        isOriginal,
        intl
    } = props;

    const [cameraSetting, setCameraSetting] = React.useState("");
    const [audioOutputSetting, setAudioOutputSetting] = React.useState("");
    const [audioInputSetting, setAudioInputSetting] = React.useState("");
    const [receiveMessageObject, setReceiveMessageObject] = React.useState("");
    const [audioOutputDevices, setAudioOutputDevices] = React.useState([]);
    const [audioInputDevices, setAudioInputDevices] = React.useState([]);
    const [participants, setParticipants] = React.useState([]);
    const [disableMic, setDisableMic] = React.useState(true);
    const [disableCamera, setDisableCamera] = React.useState(true);

    const classes = useStyles(props);

    const connection = React.useRef(null);
    const isJoined = React.useRef(false);
    const room = React.useRef(null);
    const bigVideoTrack = React.useRef(null);
    const localTracks = React.useRef([]);
    const remoteTracks = React.useRef({});
    const videoParticipants = React.useRef([]);
    const currentRoomname = React.useRef(null);
    const isWithFloor = React.useRef(null);

    useEffect(() => {
        if (isEndMeeting) {
            postStat(2);
            endMeetingSuccess();
        } else {
            startJitSiMeeting();
        }
    }, [isEndMeeting]);

    useEffect(() => {
        return () => {
            if (connection) {
                unload();
            }
            endMeetingSuccess();
        };
    }, []);

    useEffect(() => {
        if (room.current) {
            currentRoomname.current = roomname;
            changeLangTrack(roomname);

            postStat(3);
        }
    }, [roomname]);

    useEffect(() => {
        handleMute();
    }, [isMuted]);

    useEffect(() => {
        if (room.current) {
            $("audio").prop("volume", volume);
        }
    }, [volume]);

    useEffect(() => {
        handleMosaicView();
    }, [isMosaic, participants]);

    useEffect(() => {}, [startStatId]);

    useEffect(() => {
        handleRaiseHand(isRaise);
    }, [isRaise]);

    useEffect(() => {
        handleCameraAndShare();
    }, [isShareOtherCamera, isCameraOn, isVideo, cameraSetting]);

    useEffect(() => {
        isWithFloor.current = isOriginal;

        changeLangTrack(currentRoomname.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOriginal]);

    function changeLangTrack(newRoomName) {
        if (!room.current) {
            return;
        }
        const participants = room.current.getParticipants();
        let interpreter_ids = [];
        let disabled_interpreter = [];

        for (let j = 0; j < participants.length; j++) {
            if (participants[j].getProperty("output") === newRoomName) {
                interpreter_ids.push(participants[j].getId());
            }

            if (
                participants[j].getProperty("output") ===
                `non-${currentRoomname.current}`
            ) {
                disabled_interpreter.push(participants[j].getId());
            }
        }

        _resetAudioInputs([]);

        if (newRoomName === ORIGINAL_ROOMNAME) {
            for (let key in remoteTracks.current) {
                if (remoteTracks.current.hasOwnProperty(key)) {
                    for (
                        let j = remoteTracks.current[key].length - 1;
                        j >= 0;
                        j--
                    ) {
                        if (
                            !_isInterpreter(key) &&
                            parseInt(
                                room.current
                                    .getParticipantById(key)
                                    .getProperty("role")
                            ) !== UserRole.OBSERVER
                        ) {
                            const audioTrack = remoteTracks.current[key][j];
                            if (
                                audioTrack.getType() === "audio" &&
                                audioTrack.disposed === false
                            ) {
                                $(`#${key}Place`).append(
                                    `<audio autoPlay='1' id='${key}audio${j}'/>`
                                );

                                audioTrack.attach($(`#${key}audio${j}`)[0]);
                                break;
                            }
                        }
                    }
                }
            }
        } else {
            interpreter_ids.forEach(interpreter_id => {
                _addParticipantAudio(interpreter_id);
            });

            if (isWithFloor.current) {
                for (let key1 in remoteTracks.current) {
                    if (remoteTracks.current.hasOwnProperty(key1)) {
                        for (
                            let j = remoteTracks.current[key1].length - 1;
                            j >= 0;
                            j--
                        ) {
                            const track = remoteTracks.current[key1][j];
                            if (
                                track.getType() === "audio" &&
                                track.disposed === false &&
                                !_isInterpreter(key1)
                            ) {
                                $(`#${key1}Place`).append(
                                    `<audio autoPlay='1' id='${key1}audio${j}'/>`
                                );
                                track.attach($(`#${key1}audio${j}`)[0]);
                                const elem = document.getElementById(
                                    `${key1}audio${j}`
                                );
                                elem.volume = volume / 4;
                                break;
                            }
                        }
                    }
                }
            }
        }
    }

    function _resetAudioInputs(exceptParticipants = []) {
        for (var key in remoteTracks.current) {
            if (
                remoteTracks.current.hasOwnProperty(key) &&
                exceptParticipants.indexOf(key) === -1
            ) {
                for (let j = 0; j < remoteTracks.current[key].length; j++) {
                    const containers = remoteTracks.current[key][j].containers;
                    if (
                        remoteTracks.current[key][j].getType() === "audio" &&
                        containers.length > 0
                    ) {
                        const container_id = containers[0].id;
                        remoteTracks.current[key][j].detach(
                            $(`#${container_id}`)[0]
                        );
                        $(`#${container_id}`).remove();
                    }
                }
            }
        }
    }

    function _addParticipantAudio(participantId) {
        for (
            let j = remoteTracks.current[participantId].length - 1;
            j >= 0;
            j--
        ) {
            if (remoteTracks.current[participantId][j].getType() === "audio") {
                $(`#${participantId}Place`).append(
                    `<audio autoPlay='1' id='${participantId}audio${j}'/>`
                );

                remoteTracks.current[participantId][j].attach(
                    $(`#${participantId}audio${j}`)[0]
                );
                break;
            }
        }
    }

    function _isInterpreter(participantId) {
        const participants = room.current.getParticipants();
        for (let j = 0; j < participants.length; j++) {
            if (participants[j].getId() === participantId) {
                if (
                    parseInt(participants[j].getProperty("role")) ===
                    UserRole.INTERPRETER
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    async function startJitSiMeeting() {
        if (!event) {
            return;
        }

        currentRoomname.current = roomname;
        JitsiMeetJS.init(initOptions);
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
        const appId = process.env.REACT_APP_APP_ID;

        connection.current = new JitsiMeetJS.JitsiConnection(
            appId,
            authToken,
            options
        );

        connection.current.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            onConnectionSuccess
        );
        connection.current.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            onConnectionFailed
        );
        connection.current.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            disconnect
        );

        JitsiMeetJS.mediaDevices.addEventListener(
            JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
            onDeviceListChanged
        );

        connection.current.connect();
        postStat(0);

        let _audioOutputDevices = [],
            _audioInputDevices = [],
            _cameraDevices = [];
        let mediaOptions = []; // Initial mediaOptions
        let restOptions = {};

        try {
            const tracks = await JitsiMeetJS.createLocalTracks({
                devices: ["audio", "video"]
            });

            console.log(tracks);

            tracks.forEach(async t => {
                await t.dispose();
            });
        } catch (e) {
            console.log(e);
        }

        if (
            JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("output") ||
            JitsiMeetJS.mediaDevices.isDeviceListAvailable()
        ) {
            JitsiMeetJS.mediaDevices.enumerateDevices(devices => {
                // const audioOutputDevices
                //     = devices.filter(d => d.kind === 'audiooutput');
                devices.forEach((device, i) => {
                    switch (device.kind) {
                        case "audiooutput":
                            _audioOutputDevices.push(device);
                            break;
                        case "audioinput":
                            _audioInputDevices.push(device);
                            break;
                        case "videoinput":
                            _cameraDevices.push(device);
                            break;
                        default:
                            break;
                    }
                });

                // TODO: Check if states are updated above
                if (_audioOutputDevices.length > 0) {
                    setAudioOutputDevices(_audioOutputDevices);
                    setAudioOutputSetting(_audioOutputDevices[0].deviceId);
                }
                if (_audioInputDevices.length > 0) {
                    restOptions.micDeviceId = _audioInputDevices[0].deviceId;
                    mediaOptions.push("audio");
                    setAudioInputDevices(_audioInputDevices);
                    setAudioInputSetting(_audioInputDevices[0].deviceId);
                }
                if (_cameraDevices.length > 0) {
                    if (isCameraOn) {
                        restOptions.cameraDeviceId = _cameraDevices[0].deviceId;
                        restOptions.maxFpx = 60;
                        restOptions.minFpx = 30;
                        if (_cameraDevices.length > 0) {
                            mediaOptions.push("video");
                        }
                    }
                    setCameraDevices(_cameraDevices);
                    setCameraSetting(_cameraDevices[0].deviceId);
                }
            });
        } else {
            console.log("Please select");
        }

        if (
            user.role === UserRole.LITE_USER ||
            user.role === UserRole.WEB_USER
        ) {
        } else {
            JitsiMeetJS.createLocalTracks({
                devices: mediaOptions,
                ...restOptions
            })
                .then(onLocalTracks)
                .catch(error => {
                    console.log(error);
                });
        }
    }

    /**
     * Handles local tracks.
     * @param tracks Array with JitsiTrack objects
     */
    function onLocalTracks(tracks) {
        localTracks.current = tracks;
        for (let i = 0; i < localTracks.current.length; i++) {
            if (localTracks.current[i].getType() === "video") {
                localTracks.current[i].attach(
                    document.getElementById("selfVideo")
                );
                localTracks.current[i].attach(
                    document.getElementById("bigVideo")
                );
                bigVideoTrack.current = localTracks.current[i];
            } else {
                if (isMuted) {
                    localTracks.current[i].mute();
                } else {
                    localTracks.current[i].unmute();
                }
            }
            if (isJoined.current) {
                room.current.addTrack(localTracks.current[i]);
            }
        }
    }

    /**
     * That function is executed when the conference is joined
     */
    function onConferenceJoined() {
        isJoined.current = true;
    }

    async function onConferenceFailed(error) {
        try {
            await room.current.joinLobby(user.name, user.email);
        } catch (error) {
            console.info(error);
        }
    }

    /**
     *
     * @param id
     */
    function onUserJoined(id, participant) {
        if (!remoteTracks.current[id]) {
            remoteTracks.current[id] = [];
            setParticipants(room.current.getParticipants());
            if (user.role === UserRole.WEB_USER) {
                $("#remotePlace")
                    .append(`<div id="${id}Place" class="${classes.participantContainer}">
                                            <div class="${classes.videoContainer} videoContainer ${classes.noVideo}"></div>
                                        </div>`);
            } else {
                $("#remotePlace").append(`<div id="${id}Place" class="${
                    classes.participantContainer
                }">
                                            <div class="${
                                                classes.videoContainer
                                            } videoContainer ${
                    _isInterpreter(id) ? "novideo" : ""
                }"></div>
                                            <div class="displayName">${
                                                participant
                                                    ? participant.getDisplayName()
                                                    : ""
                                            }</div>
                                        </div>`);
            }
            $(`#${id}Place`).bind("click", _handleClickSidePlace);
        }
    }
    /**
     *
     * @param id
     */
    function onUserLeft(id) {
        if (!remoteTracks.current[id]) {
            return;
        }
        if (!room.current.myUserId()) {
            return;
        }
        setParticipants(room.current.getParticipants());

        if (videoParticipants.current.find(elem => elem === id)) {
            delete videoParticipants.current[id];
            room.current.selectParticipants([...videoParticipants.current]);
        }
        delete remoteTracks.current[id];
        $(`#${id}Place`).remove();
    }

    /**
     * Handles remote tracks
     * @param track JitsiTrack object
     */
    function onRemoteTrack(track) {
        if (track.isLocal()) {
            return;
        }

        const participant = track.getParticipantId();

        if (!remoteTracks.current[participant]) {
            if (!remoteTracks.current[participant]) {
                remoteTracks.current[participant] = [];
                const currentParticipant = _getParticipantFromTrack(track);
                if (user.role === UserRole.WEB_USER) {
                    $("#remotePlace")
                        .append(`<div id="${participant}Place" class="${classes.participantContainer}">
                                    <div class="${classes.videoContainer} videoContainer ${classes.noVideo}"></div>
                                </div>`);
                } else {
                    $("#remotePlace")
                        .append(`<div id="${participant}Place" class="${
                        classes.participantContainer
                    }">
                                    <div class="${
                                        classes.videoContainer
                                    } videoContainer ${
                        _isInterpreter(track.getParticipantId())
                            ? "novideo"
                            : ""
                    }"></div>
                                    <div class="displayName">${
                                        currentParticipant
                                            ? currentParticipant.getDisplayName()
                                            : ""
                                    }</div>
                                </div>`);
                }

                $(`#${participant}Place`).bind("click", _handleClickSidePlace);
            }
        }
        const idx = remoteTracks.current[participant].push(track);

        if (track.getType() === "video") {
            if (event.event_type === EventType.WEB) {
                return;
            }
            $(`#${participant}Place .videoContainer`).append(
                `<video muted id="${participant}video${idx}" autoPlay="1" class="${classes.video}" />`
            );
            if (
                !videoParticipants.current.find(
                    elem => elem === track.getParticipantId()
                )
            ) {
                room.current.selectParticipants([
                    ...videoParticipants.current,
                    track.getParticipantId()
                ]);
                videoParticipants.current.push(track.getParticipantId());
            } else {
                room.current.selectParticipants([...videoParticipants.current]);
            }
            track.attach($(`#${participant}video${idx}`)[0]);
        } else {
            changeLangTrack(currentRoomname.current);
        }
    }

    function onTrackRemoved(track) {
        const containers = track.containers;
        if (containers.length > 0) {
            if (track.getType() === "video") {
                if (
                    videoParticipants.current.find(
                        elem => elem === track.getParticipantId()
                    )
                ) {
                    const newVideoParticipants = videoParticipants.current.filter(
                        elem => elem !== track.getParticipantId()
                    );
                    room.current.selectParticipants(newVideoParticipants);
                    videoParticipants.current = newVideoParticipants;
                } else {
                    room.current.selectParticipants(videoParticipants.current);
                }
            }
            const container_id = containers[0].id;
            if (track.disposed) {
                track.detach($(`#${container_id}`)[0]);
            }

            if (container_id !== "bigVideo") {
                $(`#${container_id}`).remove();
            }
        }
    }

    function _getParticipantFromTrack(track) {
        const currentParticipants = room.current.getParticipants();
        for (let j = 0; j < currentParticipants.length; j++) {
            if (currentParticipants[j].getId() === track.getParticipantId()) {
                return currentParticipants[j];
            }
        }
        return null;
    }

    /**
     * That function is called when connection is established successfully
     */
    function onConnectionSuccess() {
        room.current = connection.current.initJitsiConference(
            event ? event.event_name : "conference",
            { ...confOptions, statisticsId: user.email }
        );
        room.current.setReceiverVideoConstraint(720);
        room.current.setSenderVideoConstraint(720);
        room.current.setDisplayName(user.name);
        room.current.on(
            JitsiMeetJS.events.conference.CONFERENCE_JOINED,
            onConferenceJoined
        );
        room.current.on(
            JitsiMeetJS.events.conference.CONFERENCE_FAILED,
            onConferenceFailed
        );
        room.current.on(
            JitsiMeetJS.events.conference.USER_JOINED,
            onUserJoined
        );
        room.current.on(
            JitsiMeetJS.events.conference.TRACK_ADDED,
            onRemoteTrack
        );
        room.current.on(
            JitsiMeetJS.events.conference.TRACK_REMOVED,
            onTrackRemoved
        );
        room.current.on(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
        room.current.on(
            JitsiMeetJS.events.conference.MESSAGE_RECEIVED,
            (id, text, ts) => onMessageReceived(id, text, ts)
        );
        room.current.on(
            JitsiMeetJS.events.conference.PRIVATE_MESSAGE_RECEIVED,
            (id, text, ts) => onMessageReceived(id, text, ts, true)
        );
        room.current.on(
            JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
            (track, participantThatMutedUs) => {
                console.log(participantThatMutedUs);
                if (participantThatMutedUs) {
                    changeMute(true);
                }
            }
        );
        room.current.on(JitsiMeetJS.events.conference.KICKED, () => {
            console.log("kicked");
            showNotification("error", "You are kicked by admin");
        });

        room.current.on(
            JitsiMeetJS.events.conference.START_MUTED_POLICY_CHANGED,
            policy => {
                changeMute(policy.audio);
                if (policy.video) {
                }
            }
        );
        room.current.addCommandListener("moderator", handleModeratorEvent);
        room.current.on(
            JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
            handleFeatureChange
        );

        room.current.on(
            JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
            (userID, displayName) => {}
        );
        room.current.on(
            JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
            (userID, audioLevel) => {}
        );
        room.current.on(
            JitsiMeetJS.events.conference.PHONE_NUMBER_CHANGED,
            () => {}
        );

        room.current.setLocalParticipantProperty(
            "role",
            user.role === UserRole.LITE_USER
                ? UserRole.LITE_USER
                : UserRole.OBSERVER
        );

        room.current.join();
    }

    function handleModeratorEvent(results) {
        const value = results.value;
        if (value === room.current.myUserId()) {
            if (results.attributes.actionType === "allow") {
                if (parseInt(results.attributes.content) === 1) {
                    room.current.setLocalParticipantProperty(
                        "role",
                        user.role === UserRole.LITE_USER
                            ? UserRole.LITE_USER
                            : UserRole.USER
                    );
                    setDisableCamera(false);
                    setDisableMic(false);
                    for (let i = 0; i < localTracks.current.length; i++) {
                        localTracks.current[i].dispose();
                    }

                    JitsiMeetJS.createLocalTracks({
                        devices: ["audio"],
                        maxFps: 60,
                        minFps: 30
                    })
                        .then(onLocalTracks)
                        .catch(error => {
                            throw error;
                        });
                } else {
                    for (let i = 0; i < localTracks.current.length; i++) {
                        localTracks.current[i].dispose();
                    }
                    room.current.setLocalParticipantProperty(
                        "role",
                        user.role === UserRole.LITE_USER
                            ? UserRole.LITE_USER
                            : UserRole.OBSERVER
                    );
                    setDisableCamera(true);
                    setDisableMic(true);
                    changeCameraOn(false);
                    changeMute(false);
                }
            }

            if (results.attributes.actionType === "notify") {
                const content = results.attributes.content;
                console.log(content);
            }
        }
    }

    function handleFeatureChange(participant, name, oldValue, newValue) {
        if (name === "role") {
            if (parseInt(participant.getProperty("role")) === UserRole.USER) {
                $(`#${participant.getId()}Place .hoverPlace`).hide();
            } else if (
                parseInt(participant.getProperty("role")) === UserRole.OBSERVER
            ) {
                $(`#${participant.getId()}Place .hoverPlace`).show();
            }

            if (
                parseInt(participant.getProperty("role")) ===
                UserRole.INTERPRETER
            ) {
                $(`#${participant.getId()}Place`).addClass(
                    classes.interpreterPlace
                );
            }
        }

        if (name === "hand") {
            if (participant.getProperty("hand")) {
                if (!document.getElementById(`${participant.getId()}hand`)) {
                    $(`#${participant.getId()}Place`).append(
                        `<div id="${participant.getId()}hand" class="${
                            classes.hand
                        }">
                        <i class="fas fa-hand-paper"></i>
                        </div>`
                    );
                }
            } else {
                $(`#${participant.getId()}hand`).remove();
            }
        } else {
            changeLangTrack(currentRoomname.current);
        }
    }

    /**
     * This function is called when the connection fail.
     */
    function onConnectionFailed() {}

    /**
     * This function is called when the connection fail.
     */
    function onDeviceListChanged(devices) {}

    /**
     * This function is called when we disconnect.
     */
    function disconnect() {
        connection.current.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            onConnectionSuccess
        );
        connection.current.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            onConnectionFailed
        );
        connection.current.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            disconnect
        );
    }

    /**
     *
     */
    async function unload() {
        if (room.current && room.current.room) {
            const currentTracks = room.current.getLocalTracks();
            for (let i = 0; i < currentTracks.length; i++) {
                if (!currentTracks[i].disposed) {
                    try {
                        await currentTracks[i].dispose();
                    } catch (error) {
                        console.info(error);
                    }
                }
            }
            try {
                await room.current.leave();
            } catch (error) {
                console.info(error);
            }
            await connection.current.disconnect();
            postStat(2);
        }
        return;
    }

    function postStat(status) {
        const currentRoom = event.event_rooms.filter(
            event_room => event_room.room.room_name === currentRoomname.current
        );
        let stat = {
            event_id: event.id,
            status: status,
            room_id: currentRoom.length > 0 ? currentRoom[0].room_id : 0,
            start_stat_id: startStatId ? startStatId : 0
        };
        addStat(stat);
    }

    function _handleClickSidePlace(event) {
        if (!room.current) return;
        const video = event.currentTarget.querySelector("video");
        const id = video ? video.id : null;

        const containers = bigVideoTrack.current
            ? bigVideoTrack.current.containers
            : null;
        if (containers && containers.length > 0) {
            bigVideoTrack.current.detach($(`#${containers[0].id}`));
            bigVideoTrack.current = null;
        }
        if (!id) {
            return;
        }
        if (id === "selfVideo") {
            if (room.current.getLocalVideoTrack())
                room.current
                    .getLocalVideoTrack()
                    .attach(document.getElementById("bigVideo"));
        } else {
            const id_arr = id.split("video");
            if (id_arr[1]) {
                if (
                    parseInt(
                        _getParticipantFromTrack(
                            remoteTracks.current[id_arr[0]][id_arr[1] - 1]
                        ).getProperty("role")
                    ) !== UserRole.OBSERVER
                ) {
                    remoteTracks.current[id_arr[0]][id_arr[1] - 1].attach(
                        document.getElementById("bigVideo")
                    );
                }
            }
        }
    }

    function handleHoverEvent(event) {}

    function handleLeaveEvent(event) {}

    function handleRaiseHand(_isRaise) {
        if (room.current) {
            room.current.setLocalParticipantProperty("hand", _isRaise);
        }
    }

    async function handleCameraAndShare() {
        if (!room.current) {
            return;
        }
        const currentLocalTrack = room.current.getLocalVideoTrack();
        if (isVideo) {
            if (isCameraOn) {
                if (currentLocalTrack) {
                    try {
                        await currentLocalTrack.setEffect(undefined);
                    } catch (error) {
                        console.log(error);
                    }
                    await currentLocalTrack.dispose();
                }
                if (isShareOtherCamera) {
                    const otherCameraDevice = _getOtherCamera(
                        cameraDevices,
                        cameraSetting
                    );
                    _handleLocalDeviceTrack(
                        otherCameraDevice.deviceId,
                        cameraSetting
                    );
                } else {
                    _handleLocalDeviceTrack(cameraSetting);
                }
            } else {
                if (currentLocalTrack) {
                    await currentLocalTrack.setEffect(undefined);
                    if (!isShareOtherCamera) {
                        await currentLocalTrack.dispose();
                    } else if (currentLocalTrack.videoType === "desktop") {
                        await currentLocalTrack.dispose();
                        const otherCameraDevice = _getOtherCamera(
                            cameraDevices,
                            cameraSetting
                        );
                        _handleLocalDeviceTrack(otherCameraDevice.deviceId);
                    }
                } else {
                    console.log(cameraDevices, cameraSetting);
                    const otherCameraDevice = _getOtherCamera(
                        cameraDevices,
                        cameraSetting
                    );
                    _handleLocalDeviceTrack(otherCameraDevice.deviceId);
                }
            }
        } else {
            if (isCameraOn) {
                if (currentLocalTrack.videoType === "desktop") {
                    const effect = await mutePresenterVideo(
                        currentLocalTrack,
                        cameraSetting
                    );
                    await currentLocalTrack.setEffect(effect);
                    return;
                }
                _handleShareDesktop(currentLocalTrack, cameraSetting);
            } else {
                if (currentLocalTrack) {
                    currentLocalTrack.setEffect(undefined);
                    if (currentLocalTrack.videoType !== "desktop") {
                        _handleShareDesktop(currentLocalTrack);
                    }
                } else {
                    _handleShareDesktop();
                }
            }
        }
    }

    /**
     * Add desktop sharing track
     * @param {*} currentLocalTrack
     * @param {*} _cameraSetting if not null, desktop track is set effect
     */
    function _handleShareDesktop(
        currentLocalTrack = null,
        _cameraSetting = null
    ) {
        JitsiMeetJS.createLocalTracks({
            devices: ["desktop"],
            maxFps: 60,
            minFps: 30
        })
            .then(async ([newVideoTrack]) => {
                if (currentLocalTrack) {
                    if (_cameraSetting) {
                        await currentLocalTrack.setEffect(undefined);
                    }
                    await currentLocalTrack.dispose();
                }
                if (_cameraSetting) {
                    const effect = await mutePresenterVideo(
                        newVideoTrack,
                        _cameraSetting
                    );
                    await newVideoTrack.setEffect(effect);
                }
                newVideoTrack.addEventListener(
                    JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
                    () => {
                        console.log(isVideo);
                        setIsVideo(true);
                    }
                );
                newVideoTrack.attach(document.getElementById("bigVideo"));
                newVideoTrack.attach($("#selfVideo")[0]);
                room.current.addTrack(newVideoTrack);
            })
            .catch(err => {
                setIsVideo(true);
            });
    }

    /**
     * Add local video track with deviceId
     * @param {*} deviceId
     * @param {*} _cameraSetting if not null, local video track is set effect.
     */
    async function _handleLocalDeviceTrack(deviceId, _cameraSetting = null) {
        console.log(_cameraSetting);
        const [newVideoTrack] = await JitsiMeetJS.createLocalTracks({
            devices: ["video"],
            maxFps: 60,
            minFps: 30,
            cameraDeviceId: deviceId
        });
        if (_cameraSetting) {
            const effect = await mutePresenterVideo(
                newVideoTrack,
                _cameraSetting
            );
            await newVideoTrack.setEffect(effect);
        }
        newVideoTrack.attach(document.getElementById("bigVideo"));
        newVideoTrack.attach($("#selfVideo")[0]);
        await room.current.addTrack(newVideoTrack);
    }

    function _getOtherCamera(_cameraDevices, _cameraSetting) {
        const otherCameras = _cameraDevices.filter(
            device => device.deviceId !== _cameraSetting
        );
        return otherCameras;
    }

    function handleMute() {
        if (!room.current || !room.current.room) {
            return;
        }
        const isStartMutedPolicy = room.current.getStartMutedPolicy().audio;
        const localAudio = room.current.getLocalAudioTrack();
        if (localAudio) {
            if (isStartMutedPolicy || isMuted) {
                localAudio.mute();
                return;
            } else {
                localAudio.unmute();
            }
        }
    }

    /**
     * Handle CameraSetting Change
     * @param {String} value
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleVideoSettingChange(value, isOpen = true) {
        const selectCameraInput = value;
        if (isOpen) {
            setCameraSetting(selectCameraInput);
        }
        setOpenVideoSettingDlg(isOpen);
    }

    /**
     * Handle AudioOutputSetting Change
     * @param {String} value
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleAudioOutputSettingChange(value, isOpen = true) {
        const selectAudioOutput = value;
        if (isOpen) {
            setAudioOutputSetting(selectAudioOutput);
            JitsiMeetJS.mediaDevices.setAudioOutputDevice(selectAudioOutput);
        }
        setOpenAudioOutputSettingDlg(isOpen);
    }

    /**
     * Handle AudioInputSetting Change
     * @param {String} value
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    async function handleAudioInputSettingChange(value, isOpen = true) {
        const selectAudioInput = value;
        if (isOpen) {
            setAudioInputSetting(selectAudioInput);

            if (localTracks.current[0]) {
                localTracks.current[0].dispose();
            }

            const [audioTrack] = await JitsiMeetJS.createLocalTracks({
                devices: ["audio"],
                micDeviceId: selectAudioInput
            });
            localTracks.current[0] = audioTrack;

            if (isMuted) {
                localTracks.current[0].mute();
            } else {
                localTracks.current[0].unmute();
            }
            room.current.addTrack(localTracks.current[0]);
        }
        setOpenAudioInputSettingDlg(isOpen);
    }

    function sendTextMessage(id, message) {
        if (id === "all") {
            room.current.sendTextMessage(message);
        } else {
            room.current.sendPrivateTextMessage(id, message);
        }
    }

    function onMessageReceived(id, text, ts, isPrivate = false) {
        if (room.current.myUserId() !== id) {
            changeMessageStatus(true);
            setReceiveMessageObject({ id, text, ts, isPrivate });
            updateMessage();
        }
    }

    function handleMosaicView() {
        if (!room.current) return;
        let _participants = room.current.getParticipants();
        _participants = _participants.filter(p => {
            return !_isInterpreter(p.getId());
        });
        calcWidth(isMosaic, _participants.length);
        calcSidePlaceHeight();
    }

    return (
        <>
            <MeetContainer className="row">
                <div className="col-md-12">
                    <div
                        id="localPlace"
                        className={classes.localPlace}
                        onMouseEnter={handleHoverEvent}
                        onMouseLeave={handleLeaveEvent}
                    >
                        <div className={classes.avatar}>
                            <img
                                src={
                                    event.pic
                                        ? process.env.REACT_APP_FILE_URL +
                                          event.pic
                                        : toAbsoluteUrl(
                                              "/media/logos/logo-trans.png"
                                          )
                                }
                                alt="avatar"
                            />
                        </div>

                        <ToolBar
                            disableMic={disableMic}
                            disableCamera={disableCamera}
                            disableShare={disableCamera}
                        />
                        {user.role === UserRole.WEB_USER &&
                        event.event_type === EventType.WEB ? (
                            <div className={`${classes.bigVideo}`}>
                                <Iframe
                                    url={event.extra_url}
                                    width="100%"
                                    height="100%"
                                    id="myId"
                                    frameBorder={0}
                                    className="myClassname"
                                    display="initial"
                                    position="relative"
                                    allow="microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                />
                            </div>
                        ) : (
                            <video
                                muted
                                autoPlay="1"
                                id="bigVideo"
                                className={`${classes.bigVideo}`}
                            />
                        )}
                        <div
                            className={
                                isMosaic
                                    ? classes.mosaicOverlay
                                    : classes.hideSelfVideo
                            }
                        />
                        {user.role === UserRole.WEB_USER &&
                        event.event_type === EventType.WEB ? (
                            ""
                        ) : (
                            <div
                                id="sidePlace"
                                className={
                                    isMosaic
                                        ? classes.mosaicRemoteContainer
                                        : classes.sidePlace
                                }
                            >
                                <div className="sideWrapper">
                                    <div
                                        id="remotePlace"
                                        className={classes.remotePlace}
                                    >
                                        {user.role === UserRole.USER && (
                                            <div
                                                id="selfPlace"
                                                className={
                                                    classes.participantContainer
                                                }
                                                onClick={_handleClickSidePlace}
                                            >
                                                <div
                                                    className={
                                                        classes.videoContainer
                                                    }
                                                >
                                                    <video
                                                        muted
                                                        autoPlay="1"
                                                        id="selfVideo"
                                                        className={
                                                            classes.video
                                                        }
                                                    />
                                                </div>
                                                <div className="displayName">
                                                    {user.name}(
                                                    {intl.formatMessage({
                                                        id: "CHAT.ME"
                                                    })}
                                                    )
                                                </div>
                                                {isRaise && (
                                                    <div
                                                        className={classes.hand}
                                                    >
                                                        <i className="fas fa-hand-paper"></i>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={classes.chatContainer}>
                            <Chat
                                open={isChatBoxOpen}
                                setIsChatDlg={openChatBox}
                                sendTextMessage={sendTextMessage}
                                receiveMessageObject={receiveMessageObject}
                                participants={participants}
                            />
                        </div>

                        {/* Camera Setting Dialog */}
                        <SelectDeviceDialog
                            id="cameraSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.CAMERA.TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.CAMERA"
                            })}
                            isOpen={openVideoSettingDlg}
                            devices={cameraDevices}
                            currentValue={cameraSetting}
                            onChange={handleVideoSettingChange}
                        />

                        {/* Audio Output Setting Dialog */}
                        <SelectDeviceDialog
                            id="audioOutputSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_OUPUTS.TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_OUPUTS"
                            })}
                            isOpen={openAudioOutputSettingDlg}
                            devices={audioOutputDevices}
                            currentValue={audioOutputSetting}
                            onChange={handleAudioOutputSettingChange}
                        />

                        {/* Audio Input Setting Dialog */}
                        <SelectDeviceDialog
                            id="audioInputSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_INPUTS_TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_INPUTS"
                            })}
                            isOpen={openAudioInputSettingDlg}
                            devices={audioInputDevices}
                            currentValue={audioInputSetting}
                            onChange={handleAudioInputSettingChange}
                        />
                    </div>
                </div>
            </MeetContainer>
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        authToken: state.auth.authToken,
        isEndMeeting: state.event.isEndMeeting,
        startStatId: state.event.startStatId,
        isMuted: state.event.isMuted,
        isCameraOn: state.event.isCameraOn,
        cameraDevices: state.event.cameraDevices,
        isChatBoxOpen: state.event.isChatBoxOpen,
        openVideoSettingDlg: state.event.openVideoSettingDlg,
        openAudioOutputSettingDlg: state.event.openAudioOutputSettingDlg,
        openAudioInputSettingDlg: state.event.openAudioInputSettingDlg,
        isRaise: state.event.isRaise,
        isMosaic: state.event.isMosaic,
        isVideo: state.event.isVideo,
        isShareOtherCamera: state.event.isShareOtherCamera
    };
};

const mapDispatchToProps = dispatch => ({
    endMeetingSuccess: () => dispatch(eventStore.actions.endMeetingSuccess()),
    changeMute: isMuted => dispatch(eventStore.actions.changeMute(isMuted)),
    changeCameraOn: isCameraOn =>
        dispatch(eventStore.actions.changeCameraOn(isCameraOn)),
    updateMessage: () => dispatch(eventStore.actions.updateMessage()),
    addStat: data => dispatch(eventStore.actions.addStat(data)),
    showNotification: (type, content) =>
        dispatch(eventStore.actions.showNotification(type, content)),
    setCameraDevices: cameraDevices =>
        dispatch(eventStore.actions.setCameraDevices(cameraDevices)),
    openChatBox: isChatBoxOpen =>
        dispatch(eventStore.actions.openChatBox(isChatBoxOpen)),
    changeMessageStatus: isNewMsg =>
        dispatch(eventStore.actions.changeMessageStatus(isNewMsg)),
    setOpenAudioInputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioInputSettingDlg(isOpen)),
    setOpenAudioOutputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen)),
    setOpenVideoSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenVideoSettingDlg(isOpen)),
    setIsVideo: isVideo => dispatch(eventStore.actions.setIsVideo(isVideo))
});

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(WebinarVideo)
);
