import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import objectPath from "object-path";
import HMenuItem from "../../../../../_metronic/layout/header/HMenu/HMenuItem";
import * as builder from "../../../../../_metronic/ducks/builder";
import { toAbsoluteUrl } from "../../../../../_metronic";
import KTMenu from "../../../../../_metronic/_assets/js/menu";
import KTOffcanvas from "../../../../../_metronic/_assets/js/offcanvas";
import Topbar from "../../../../../_metronic/layout/header/Topbar";
import GroupTranslator from "./GroupTranslator";

const offcanvasOptions = {
    overlay: true,
    baseClass: "kt-header-menu-wrapper",
    closeBy: "kt_header_menu_mobile_close_btn",
    toggleBy: {
        target: "kt_header_mobile_toggler",
        state: "kt-header-mobile__toolbar-toggler--active"
    }
};

class Sidebar extends React.Component {
    offCanvasCommonRef = React.createRef();
    ktMenuCommonRef = React.createRef();

    getHeaderLogo() {
        let result = "logo-md.png";
        if (this.props.headerSelfSkin && this.props.headerSelfSkin !== "dark") {
            result = "logo-md.png";
        }
        return toAbsoluteUrl(`/media/logos/${result}`);
    }

    get currentUrl() {
        return this.props.location.pathname.split(/[?#]/)[0];
    }

    componentDidMount() {
        // Canvas
        this.initOffCanvas();
        // Menu
        this.initKTMenu();
    }

    initOffCanvas = () => {
        // eslint-disable-next-line no-undef
        new KTOffcanvas(this.offCanvasCommonRef.current, offcanvasOptions);
    };

    initKTMenu = () => {
        let menuOptions = {
            submenu: {
                desktop: "dropdown",
                tablet: "accordion",
                mobile: "accordion"
            },
            accordion: {
                slideSpeed: 200, // accordion toggle slide speed in milliseconds
                expandAll: false // allow having multiple expanded accordions in the menu
            },
            dropdown: {
                timeout: 50
            }
        };

        let menuDesktopMode = "accordion";
        if (
            this.ktMenuCommonRef.current.getAttribute(
                "data-ktmenu-dropdown"
            ) === "1"
        ) {
            menuDesktopMode = "dropdown";
        }

        if (
            typeof objectPath.get(menuOptions, "submenu.desktop") === "object"
        ) {
            menuOptions.submenu.desktop = {
                default: menuDesktopMode
            };
        }

        // eslint-disable-next-line no-undef
        new KTMenu(this.ktMenuCommonRef.current, menuOptions);
    };

    render() {
        const {
            disabledAsideSelfDisplay,
            ktMenuClasses,
            ulClasses,
            rootArrowEnabled,
            event
        } = this.props;
        const items = this.props.menuConfig.header.items;
        return (
            <Grid container justifyContent="center">
                <Topbar />
                {disabledAsideSelfDisplay && (
                    <div className="kt-header-logo">
                        <Link to="/">
                            <img alt="logo" src={this.getHeaderLogo()} />
                        </Link>
                    </div>
                )}

                <div
                    id="kt_header_menu"
                    className={`kt-header-menu kt-header-menu-mobile ${ktMenuClasses}`}
                    ref={this.ktMenuCommonRef}
                    style={{ margin: 0, marginTop: 20 }}
                >
                    <ul className={`kt-menu__nav ${ulClasses}`}>
                        {items.map((item, index) => {
                            return (
                                <React.Fragment key={`hmenuList${index}`}>
                                    {item.title && (
                                        <HMenuItem
                                            item={item}
                                            currentUrl={this.currentUrl}
                                            rootArrowEnabled={rootArrowEnabled}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                    <Grid item xs={6} style={{ alignSelf: "center" }}>
                        <Typography variant="h6">{event.title}</Typography>
                    </Grid>
                </div>
                <Typography
                    // className={classes.description}
                    variant="subtitle1"
                    style={{ margin: 0, marginBottom: 20 }}
                >
                    {event.detail}
                </Typography>

                <GroupTranslator />
            </Grid>
        );
    }
}

const mapStateToProps = store => ({
    config: store.builder.layoutConfig,
    menuConfig: store.builder.menuConfig,
    ktMenuClasses: builder.selectors.getClasses(store, {
        path: "header_menu",
        toString: true
    }),
    rootArrowEnabled: builder.selectors.getConfig(
        store,
        "header.menu.self.root-arrow"
    ),
    headerSelfSkin: builder.selectors.getConfig(store, "header.self.skin"),
    ulClasses: builder.selectors.getClasses(store, {
        path: "header_menu_nav",
        toString: true
    }),
    disabledAsideSelfDisplay:
        objectPath.get(store.builder.layoutConfig, "aside.self.display") ===
        false
});

export default withRouter(connect(mapStateToProps)(Sidebar));
