import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Formik } from "formik";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core";

import * as customersStore from "../../../../store/ducks/customers.duck";
import { UserRole } from "../../../../components/utils/UserRole";

function CustomerDlg(props) {
    const { onClose, open, customerId, customerValue, dispatch, intl } = props;

    const [customer, setCustomer] = useState({ name: "", email: "" });

    useEffect(() => {
        if (customerId && customerId !== -1 && customerValue) {
            setCustomer({
                name: customerValue.name,
                email: customerValue.email,
                isLight: customerValue.role - UserRole.USER
            });
        }
    }, [customerId, customerValue]);

    const handleClose = () => {
        setCustomer({ name: "", email: "" });
        onClose();
    };

    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
        paddingRight: "2.5rem"
    });
    // Submit Button Effects
    const enableLoading = () => {
        setLoading(true);
        setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
        setLoading(false);
        setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };
    // Handle Change Customer Values
    const handleCustomerNameChange = e => {
        const newValue = e.target.value;
        setCustomer(prevCustomer => {
            return { ...prevCustomer, name: newValue };
        });
    };

    const handleCustomerEmailChange = e => {
        const newValue = e.target.value;
        setCustomer(prevCustomer => {
            return { ...prevCustomer, email: newValue };
        });
    };

    const handleCustomerRoleChange = e => {
        const newValue = e.target.value;
        setCustomer(prevCustomer => {
            return { ...prevCustomer, isLight: newValue };
        });
    };

    return (
        <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                {intl.formatMessage({ id: "CUSTOMER.DETAIL.DLG.TITLE" })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {intl.formatMessage({ id: "EVENTDLG.DESC" })}
                </DialogContentText>
                <Formik
                    initialValues={customer}
                    enableReinitialize
                    validate={values => {
                        const errors = {};

                        if (!values.name) {
                            errors.name = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.email) {
                            errors.email = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        enableLoading();
                        setTimeout(() => {
                            if (customerId !== -1) {
                                dispatch(
                                    customersStore.actions.updateCustomers(
                                        Object.assign(customerValue, customer)
                                    )
                                );
                                disableLoading();
                                onClose();
                            } else {
                                dispatch(
                                    customersStore.actions.addCustomer(customer)
                                );
                                disableLoading();
                                onClose();
                            }
                        }, 1000);
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form
                            noValidate={true}
                            autoComplete="off"
                            className="kt-form"
                            onSubmit={handleSubmit}
                        >
                            {status ? (
                                <div
                                    role="alert"
                                    className="alert alert-danger"
                                >
                                    <div className="alert-text">{status}</div>
                                </div>
                            ) : (
                                ""
                            )}

                            <FormControl>
                                <InputLabel id="isLight-label">
                                    {intl.formatMessage({
                                        id: "CUSTOMER.FIELD.ROLE"
                                    })}
                                </InputLabel>
                                <Select
                                    labelId="isLight-label"
                                    id="isLight"
                                    value={values.isLight ? values.isLight : 0}
                                    onChange={handleCustomerRoleChange}
                                    name="isLight"
                                >
                                    <MenuItem value={0}>General User</MenuItem>
                                    <MenuItem value={1}>Web User</MenuItem>
                                    <MenuItem value={2}>Lite User</MenuItem>
                                </Select>
                            </FormControl>

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "USER.FIELD.NAME"
                                    })}
                                    className="kt-width-full"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleCustomerNameChange}
                                    value={values.name}
                                    helperText={touched.name && errors.name}
                                    error={Boolean(touched.name && errors.name)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "USER.FIELD.EMAIL"
                                    })}
                                    className="kt-width-full"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleCustomerEmailChange}
                                    value={values.email}
                                    helperText={touched.email && errors.email}
                                    error={Boolean(
                                        touched.email && errors.email
                                    )}
                                />
                            </div>

                            <div className="kt-login__actions">
                                <DialogActions>
                                    <Button
                                        color="primary"
                                        onClick={handleClose}
                                    >
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.CANCEL"
                                        })}
                                    </Button>
                                    <button
                                        id="kt_login_signin_submit"
                                        type="submit"
                                        disabled={isSubmitting}
                                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                            {
                                                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                            }
                                        )}`}
                                        style={loadingButtonStyle}
                                    >
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.SUBMIT"
                                        })}
                                    </button>
                                </DialogActions>
                            </div>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

CustomerDlg.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    customerId: PropTypes.any
};

export default injectIntl(connect()(CustomerDlg));
