import store from "../store/store";
import axios from "axios";

import { actions } from '../store/ducks/auth.duck'
export const apiGetRequest = async (url) => {
    try {
        const response = await axios.get(url);
        if (response.data.error === 'Unauthorized') {
            store.dispatch(actions.logout());
        } else {
            return response;
        }
    } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
            store.dispatch(actions.logout());
        } else {
            return error.response;
        }
    }
}

export const apiPostRequest = async (url, data = {}) => {
    try {
        const response = await axios.post(url, data);
        return response;
    } catch (error) {
        if (error.response.status === 401) {
            store.dispatch(actions.logout());
        } else {
            return error.response;
        }
    }
}