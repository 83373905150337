import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
// import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";

import Events from "./superadmins/events/Events";
import Settings from "./superadmins/settings/Settings";
import Users from "./superadmins/users/Users";
import Profile from "../profile/Profile";

const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);

export default function HomePage() {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/events" component={Events} />
        <Route path="/settings" component={Settings} />
        <Route path="/users" component={Users} />
        <Route path="/profile" component={Profile} />

        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        {/* <Route path="/docs" component={DocsPage} /> */}
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
