import { calcWidth, calcSidePlaceHeight } from "./VideoLayoutUtils";
import { isInterpreter } from "../../../../components/utils/RoomUtils";
import { useEffect } from "react";
import store from "../../../../store/store";
import { UserRole } from "../../../../components/utils/UserRole";

function useHandleMosaicView(
    isMosaic,
    screenMode,
    participants,
    isSubTitle,
    isChatBoxOpen
) {
    useEffect(() => {
        const state = store.getState();
        const room = state.event.room;
        if (!room) {
            calcWidth(isMosaic, 0);
            calcSidePlaceHeight();
            return;
        }
        let _participants = room.getParticipants();
        _participants = _participants.filter(p => {
            if (isInterpreter(_participants, p.getId())) {
                return (
                    state.auth.user &&
                    [
                        UserRole.SUPERADMIN,
                        UserRole.ADMIN,
                        UserRole.EVENT_MANAGER
                    ].indexOf(state.auth.user.role) !== -1
                );
            } else {
                return true;
            }
        });
        calcWidth(isMosaic, _participants.length, isSubTitle);
        calcSidePlaceHeight();
    }, [isMosaic, screenMode, participants, isSubTitle, isChatBoxOpen]);
}

export default useHandleMosaicView;
