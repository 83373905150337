import React from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { resetPasswordGuest } from "../../crud/auth.crud";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPassword(props) {
  const { intl } = props;
  let query = useQuery();
  const email = query.get('email');
  const token = query.get('token');

  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>
            <FormattedMessage id="AUTH.RESET.TITLE" />
          </h3>
        </div>

        <Formik
          initialValues={{
            password: "",
            password_confirmation: ""
          }}
          validate={values => {
            const errors = {};

            // if (!values.password) {
            //   errors.password = intl.formatMessage({
            //     id: "AUTH.VALIDATION.REQUIRED_FIELD"
            //   });
            // }

            // if (!values.password_confirmation) {
            //   errors.password_confirmation = intl.formatMessage({
            //     id: "AUTH.VALIDATION.REQUIRED_FIELD"
            //   });
            // } else if (values.password !== values.password_confirmation) {
            //   errors.password_confirmation =
            //     "Password and Confirm Password didn't match.";
            // }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            resetPasswordGuest({
              email: email,
              token: token,
            })
              .then(({ data: { accessToken } }) => {
                // props.register(accessToken);
                props.history.push('auth/login')
              })
              .catch(() => {
                setSubmitting(false);
                setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_LOGIN"
                  })
                );
              });
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group mb-0">
                  <TextField
                    type="email"
                    margin="normal"
                    label="Email"
                    className="kt-width-full"
                    // name="password"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={email}
                  // helperText={touched.password && errors.password}
                  // error={Boolean(touched.password && errors.password)}
                  />
                </div>


                <div className="kt-login__actions">

                  <Link to="/auth">
                    <button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">
                      Back
                  </button>
                  </Link>

                  <button
                    disabled={isSubmitting}
                    className="btn btn-primary btn-elevate kt-login__btn-primary"
                  >
                    Reset
                </button>
                </div>
              </form>
            )}
        </Formik>
      </div>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(ResetPassword)
);
