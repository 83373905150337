import _ from "lodash";
import {
    initOptions,
    options,
    confOptions,
    isInterpreter
} from "./utils/RoomUtils";
import store from "../store/store";
import * as eventStore from "../store/ducks/event.duck";
import { UserRole } from "./utils/UserRole";
import { mutePresenterVideo } from "./utils/LocalVideoTrackUtils";
import { AudioMixer } from "./AudioMixer";
import { EventType } from "./utils/EventType";

const JitsiMeetJS = window.JitsiMeetJS;

export const ORIGINAL_ROOMNAME = "original";

class JitsiMeeting {
    constructor() {
        this.init();
    }

    init() {
        this.connection = null;
        this.room = null;
        this.localTracks = [];
        this.remoteTracks = {};
        this.inputRoomname = null;
        this.videoParticipants = [];
        this.receiveMessageObject = {};
        this.audioOutputDevices = [];
        this.audioInputDevices = [];
        this.focusedId = null;
        this.audioTracks = {};
        this.cameraSetting = "";
        this.isCameraOn = false;
        this.isVideo = true;
        this.isShareOtherCamera = false;
        this.isMicOn = false;
        this.isWithFloor = false;
        this.isMuted = false;
        this.isSubTitle = false;
        // Interpreter
        this.outputRoomname = "";
        this.selfRoomname = null;
        this.audioMixer = new AudioMixer();
        this.volume = 0.5;
        this.bass = 4;
        this.treble = 4;
    }

    async connect(appId, authToken, event, inputRoomname, otherOptions = {}) {
        this.init();

        this.event = event;
        this.user = store.getState().auth.user;
        this.inputRoomname = inputRoomname;
        if (otherOptions.isMicOn) {
            this.changeMicOn(otherOptions.isMicOn);
        }

        // Interpreter
        if (!_.isEmpty(otherOptions.selfRoomname)) {
            this.setSelfRoomname(otherOptions.selfRoomname);
        }
        if (!_.isEmpty(otherOptions.outputRoomname)) {
            this.setOutputRoomname(otherOptions.outputRoomname);
        }

        JitsiMeetJS.init(initOptions);
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);

        this.connection = new JitsiMeetJS.JitsiConnection(
            appId,
            authToken,
            options
        );

        this.onConnectionSuccess = this.onConnectionSuccess.bind(this);
        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            this.onConnectionSuccess
        );
        this.onConnectionFailed = this.onConnectionFailed.bind(this);
        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            this.onConnectionFailed
        );
        this.disconnect = this.disconnect.bind(this);
        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            this.disconnect
        );
        this.onDeviceListChanged = this.onDeviceListChanged.bind(this);
        JitsiMeetJS.mediaDevices.addEventListener(
            JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
            this.onDeviceListChanged
        );

        this.connection.connect();
        console.log(this.connection);
        this.getDevices();
    }

    getDevices() {
        let _audioOutputDevices = [],
            _audioInputDevices = [],
            _cameraDevices = [];
        let mediaOptions = []; // Initial mediaOptions
        let restOptions = {};

        // const tracks = await JitsiMeetJS.createLocalTracks({
        //     devices: ["audio", "video"]
        // });
        // tracks.forEach(async t => {
        //     await t.dispose();
        // });

        if (
            JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("output") ||
            JitsiMeetJS.mediaDevices.isDeviceListAvailable()
        ) {
            JitsiMeetJS.mediaDevices.enumerateDevices(devices => {
                console.info(devices);
                devices.forEach(device => {
                    switch (device.kind) {
                        case "audiooutput":
                            _audioOutputDevices.push(device);
                            break;
                        case "audioinput":
                            _audioInputDevices.push(device);
                            break;
                        case "videoinput":
                            _cameraDevices.push(device);
                            break;
                        default:
                            break;
                    }
                });

                // TODO: Check if states are updated below
                if (_audioOutputDevices.length > 0) {
                    this.setAudioOutputDevices(_audioOutputDevices);
                    this.setAudioOutputSetting(_audioOutputDevices[0].deviceId);
                }
                if (_audioInputDevices.length > 0) {
                    mediaOptions.push("audio");
                    restOptions.micDeviceId = _audioInputDevices[0].deviceId;
                    this.setAudioInputDevices(_audioInputDevices);
                    this.setAudioInputSetting(_audioInputDevices[0].deviceId);
                }
                if (_cameraDevices.length > 0) {
                    if (this.isCameraOn) {
                        restOptions.cameraDeviceId = _cameraDevices[0].deviceId;
                        if (_cameraDevices.length > 0) {
                            mediaOptions.push("video");
                        }
                    }
                    this.setCameraDevices(_cameraDevices);
                    this.setCameraSetting(_cameraDevices[0].deviceId);
                }

                this.onLocalTracks = this.onLocalTracks.bind(this);
                JitsiMeetJS.createLocalTracks({
                    devices: mediaOptions,
                    ...restOptions
                })
                    .then(this.onLocalTracks)
                    .catch(error => {
                        console.info(error);
                    });
            });
        } else {
            console.log("Please select");
        }
    }

    setAudioOutputSetting(newAudioOutput) {
        this.audioOutputSetting = newAudioOutput;
        JitsiMeetJS.mediaDevices.setAudioOutputDevice(newAudioOutput);
        store.dispatch(
            eventStore.actions.setAudioOutputSetting(newAudioOutput)
        );
    }

    async setAudioInputSetting(newAudioInputSetting = null) {
        if (newAudioInputSetting) {
            this.audioInputSetting = newAudioInputSetting;

            store.dispatch(
                eventStore.actions.setAudioInputSetting(this.audioInputSetting)
            );
        }

        const currentLocalAudioTrack = this.localTracks.find(
            track => track.getType() === "audio"
        );

        if (currentLocalAudioTrack && !currentLocalAudioTrack.disposed) {
            await currentLocalAudioTrack.dispose().catch(e => {
                console.log(e);
            });
        }

        if (this.isMicOn) {
            JitsiMeetJS.createLocalTracks({
                devices: ["audio"],
                micDeviceId: this.audioInputSetting
            })
                .then(async ([audioTrack]) => {
                    if (this.isMuted) {
                        audioTrack.mute();
                    } else {
                        audioTrack.unmute();
                    }

                    if (this.room && this.room.room) {
                        await this.room.addTrack(audioTrack).catch(err => {
                            console.log(err);
                        });
                    }

                    this.updateLocalTracks([audioTrack]);
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            this.removeLocalTrackbyType("audio");
        }
    }

    setCameraSetting(newCameraSetting, shouldDispatch = true) {
        this.cameraSetting = newCameraSetting;

        this.updateHandleCameraAndShare();
        if (shouldDispatch)
            store.dispatch(
                eventStore.actions.setCameraSetting(newCameraSetting)
            );
    }

    setCameraDevices(cameraDevices) {
        this.cameraDevices = cameraDevices;
        store.dispatch(eventStore.actions.setCameraDevices(this.cameraDevices));
    }

    setAudioOutputDevices(audioOutputDevices) {
        this.audioOutputDevices = audioOutputDevices;
        store.dispatch(
            eventStore.actions.setAudioOutputDevices(this.audioOutputDevices)
        );
    }

    setAudioInputDevices(audioInputDevices) {
        this.audioInputDevices = audioInputDevices;
        store.dispatch(
            eventStore.actions.setAudioInputDevices(this.audioInputDevices)
        );
    }

    onConnectionSuccess() {
        this.room = this.connection.initJitsiConference(
            this.event ? this.event.event_name : "conference",
            { ...confOptions, statisticsId: this.user.email }
        );
        this.room.setReceiverVideoConstraint(1080);
        this.room.setSenderVideoConstraint(1080); // After update, this should be set.

        let displayName = this.user.name;

        if (this.user.role === UserRole.INTERPRETER) {
            displayName = `(T) ${this.user.name}`;
            this.room.setLocalParticipantProperty(
                "d_output",
                this.selfRoomname
            );
            // Set default output roomname
            this.room.setLocalParticipantProperty(
                "output",
                this.outputRoomname
            );
        }

        this.room.setDisplayName(displayName);
        this.room.setLocalParticipantProperty("role", this.user.role);

        store.dispatch(eventStore.actions.setConference(this.room));

        this.onConferenceJoined = this.onConferenceJoined.bind(this);
        this.room.on(
            JitsiMeetJS.events.conference.CONFERENCE_JOINED,
            this.onConferenceJoined
        );
        this.onConferenceFailed = this.onConferenceFailed.bind(this);
        this.room.on(
            JitsiMeetJS.events.conference.CONFERENCE_FAILED,
            this.onConferenceFailed
        );
        this.onUserJoined = this.onUserJoined.bind(this);
        this.room.on(
            JitsiMeetJS.events.conference.USER_JOINED,
            this.onUserJoined
        );
        this.onUserRoleChanged = this.onUserRoleChanged.bind(this);
        this.room.on(
            JitsiMeetJS.events.conference.USER_ROLE_CHANGED,
            this.onUserRoleChanged
        );
        this.onRemoteTrack = this.onRemoteTrack.bind(this);
        this.room.on(
            JitsiMeetJS.events.conference.TRACK_ADDED,
            this.onRemoteTrack
        );
        this.onTrackRemoved = this.onTrackRemoved.bind(this);
        this.room.on(
            JitsiMeetJS.events.conference.TRACK_REMOVED,
            this.onTrackRemoved
        );
        this.onUserLeft = this.onUserLeft.bind(this);
        this.room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft);
        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.room.on(
            JitsiMeetJS.events.conference.MESSAGE_RECEIVED,
            (id, text, ts) => this.onMessageReceived(id, text, ts)
        );
        this.room.on(
            JitsiMeetJS.events.conference.PRIVATE_MESSAGE_RECEIVED,
            (id, text, ts) => this.onMessageReceived(id, text, ts, true)
        );
        this.onSpeakerChanged = this.onSpeakerChanged.bind(this);
        this.room.on(
            JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED,
            this.onSpeakerChanged
        );
        this.room.on(
            JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
            (track, actorParticipant) => {
                console.log(track.isLocal());
                console.log(track);
                console.log(actorParticipant);
                if (
                    actorParticipant &&
                    parseInt(actorParticipant.getProperty("role")) <=
                        UserRole.EVENT_MANAGER
                ) {
                    this.changeMicOn(false);
                }
                // if (track.isLocal()) {
                //     this.changeMute(true, false);
                // }

                store.dispatch(
                    eventStore.actions.setParticipants(
                        this.room.getParticipants()
                    )
                );
            }
        );
        this.onAudioLevelChanged = this.onAudioLevelChanged.bind(this);
        this.room.on(
            JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
            this.onAudioLevelChanged
        );
        this.handleFeatureChange = this.handleFeatureChange.bind(this);
        this.room.on(
            JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
            this.handleFeatureChange
        );
        this.handleModeratorEvent = this.handleModeratorEvent.bind(this);
        this.room.addCommandListener("moderator", this.handleModeratorEvent);
        this.room.on(
            JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
            (userID, displayName) => {}
        );
        this.room.on(
            JitsiMeetJS.events.conference.PHONE_NUMBER_CHANGED,
            () => {}
        );
        // this.room.on(
        //     JitsiMeetJS.events.conference.PARTICIPANT_KICKED,
        //     (actorParticipant, kickedParticipant, reason) => {
        //         console.log(actorParticipant);
        //         console.log(kickedParticipant);
        //         console.log(reason);
        //     }
        // );
        this.room.on(
            JitsiMeetJS.events.conference.KICKED,
            (actorParticipant, kickedParticipant, reason) => {
                console.log(actorParticipant);
                console.log(kickedParticipant);
                console.log(reason);
                store.dispatch(
                    eventStore.actions.showNotification(
                        "error",
                        "You are kicked by admin"
                    )
                );
                store.dispatch(eventStore.actions.endMeeting());
            }
        );
        this.room.join();
    }

    onConnectionFailed() {}

    disconnect() {
        this.connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            this.onConnectionSuccess
        );
        this.connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            this.onConnectionFailed
        );
        this.connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            this.disconnect
        );
    }

    onDeviceListChanged(devices) {
        console.log(devices);
        let _audioOutputDevices = [],
            _audioInputDevices = [],
            _cameraDevices = [];
        let mediaOptions = []; // Initial mediaOptions
        let restOptions = {};

        devices.forEach(device => {
            switch (device.kind) {
                case "audiooutput":
                    _audioOutputDevices.push(device);
                    break;
                case "audioinput":
                    _audioInputDevices.push(device);
                    break;
                case "videoinput":
                    _cameraDevices.push(device);
                    break;
                default:
                    break;
            }
        });

        // TODO: Check if states are updated below
        if (_audioOutputDevices.length > 0) {
            this.setAudioOutputDevices(_audioOutputDevices);

            if (this.audioOutputSetting) {
            } else {
                this.setAudioOutputSetting(_audioOutputDevices[0].deviceId);
            }
        }

        if (_audioInputDevices.length > 0) {
            mediaOptions.push("audio");
            this.setAudioInputDevices(_audioInputDevices);

            if (this.audioInputSetting) {
            } else {
                restOptions.micDeviceId = _audioInputDevices[0].deviceId;
                this.setAudioInputSetting(_audioInputDevices[0].deviceId);
            }
        }

        if (_cameraDevices.length > 0) {
            if (this.isCameraOn) {
                if (_cameraDevices.length > 0) {
                    mediaOptions.push("video");
                }
            }
            this.setCameraDevices(_cameraDevices);

            if (this.cameraSetting) {
            } else {
                restOptions.cameraDeviceId = _cameraDevices[0].deviceId;
                this.setCameraSetting(_cameraDevices[0].deviceId);
            }
        }
    }

    onLocalTracks(tracks) {
        console.log(tracks);
        for (let i = 0; i < tracks.length; i++) {
            tracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
                audioLevel => {
                    console.log(audioLevel);
                }
            );
            tracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
                () => {}
            );
            tracks[i].addEventListener(
                JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
                () => {}
            );
            tracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
                deviceId => {}
            );

            if (tracks[i].getType() === "video") {
            } else if (tracks[i].getType() === "audio") {
                if (this.isMuted) {
                    tracks[i].mute();
                } else {
                    tracks[i].unmute();
                }
            }

            if (this.isJoined === 2) {
                if (tracks[i].getType() === "audio") {
                    if (this.isMicOn) {
                        this.room.addTrack(tracks[i]);
                    }
                } else {
                    this.room.addTrack(tracks[i]);
                }
            }

            this.updateLocalTracks([tracks[i]]);
        }
    }

    async onConferenceJoined() {
        for (let i = 0; i < this.localTracks.length; i++) {
            if (this.localTracks[i].getType() === "audio" && this.isMuted) {
                this.localTracks[i].mute();
            }
            await this.room.addTrack(this.localTracks[i]).catch(error => {
                console.log(error);
            });
        }
    }

    onConferenceFailed(error) {
        console.info(error);

        // TODO: This conference failure does not detect isMembersOnly function
        // if (room.current.isMembersOnly()) {
        // }

        this.room
            .joinLobby(this.user.name, this.user.email)
            .then(result => {
                console.log(result);
            })
            .catch(error => {
                console.info(error);
            });
    }

    /**
     *
     * @param id
     */

    onUserJoined(id) {
        console.info(id);
        if (!this.remoteTracks[id]) {
            this.remoteTracks[id] = [];
            store.dispatch(
                eventStore.actions.setParticipants(this.room.getParticipants())
            );
        }
    }

    /**
     *
     * @param id
     */
    onUserLeft(id) {
        if (!this.remoteTracks[id]) {
            return;
        }
        if (!this.room.myUserId()) {
            return;
        }

        if (this.videoParticipants.find(elem => elem === id)) {
            delete this.videoParticipants[id];
            this.room.selectParticipants([...this.videoParticipants]);
        }
        delete this.remoteTracks[id];

        store.dispatch(
            eventStore.actions.setParticipants(this.room.getParticipants())
        );

        // remove grouptranslator if the left user is a group translator
        store.dispatch(eventStore.actions.setGroupTranslator(id));
    }

    onUserRoleChanged(id, role) {
        console.info(id, role);
        if (role === "moderator") {
            // if (this.room.isLobbySupported() && !this.room.isMembersOnly()) {
            //     await this.room.enableLobby();
            // }
        }
    }

    /**
     * Handles remote tracks
     * @param track JitsiTrack object
     */
    onRemoteTrack(track) {
        if (track.isLocal()) {
            store.dispatch(
                eventStore.actions.setParticipants(this.room.getParticipants())
            );
            return;
        }

        const participantId = track.getParticipantId();
        const participant = this.room.getParticipantById(participantId);

        if (!this.remoteTracks[participantId]) {
            this.remoteTracks[participantId] = [track];
        } else {
            for (let i = 0; i < this.remoteTracks[participantId].length; i++) {
                const _track = this.remoteTracks[participantId][i];
                if (_track.getType() === track.getType()) {
                    _track.dispose();
                    _.remove(
                        this.remoteTracks[participantId],
                        this.remoteTracks[participantId][i]
                    );
                    break;
                }
            }
            this.remoteTracks[participantId].push(track);
        }

        if (this.user.role === UserRole.INTERPRETER) {
            const that = this;
            track.addEventListener(
                JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
                t => {
                    if (
                        participant.getProperty("d_output") ===
                        that.selfRoomname
                    ) {
                        store.dispatch(
                            eventStore.actions.setGroupTranslator(participant)
                        );
                    }
                }
            );

            if (participant.getProperty("d_output") === this.selfRoomname) {
                try {
                    store.dispatch(
                        eventStore.actions.setGroupTranslator(participant)
                    );
                } catch (err) {
                    console.log(err);
                }
            }
        }

        store.dispatch(
            eventStore.actions.setParticipants(this.room.getParticipants())
        );
        if (track.getType() === "audio") {
            this.changeLangTrack(this.inputRoomname);
        }
    }

    onTrackRemoved(track) {
        const participantId = track.getParticipantId();
        const containers = track.containers;
        if (containers.length > 0) {
            if (track.getType() === "video") {
                if (
                    this.videoParticipants.find(elem => elem === participantId)
                ) {
                    const newVideoParticipants = this.videoParticipants.filter(
                        elem => elem !== track.getParticipantId()
                    );
                    this.room.selectParticipants(newVideoParticipants);
                    this.videoParticipants = newVideoParticipants;
                } else {
                    this.room.selectParticipants(this.videoParticipants);
                }

                // If video track is interpreter's, hide participant.
                // if (
                //     $(`#${track.getParticipantId()}Place`).hasClass(
                //         "interpreterPlace"
                //     )
                // ) {
                //     $(`#${track.getParticipantId()}Place`).addClass(
                //         `${classes.hiddenParticipant}`
                //     );
                // }
            }
            const container_id = containers[0].id;
            if (container_id) {
                if (track.disposed) {
                    if (this.remoteTracks[participantId]) {
                        for (
                            let i = 0;
                            i < this.remoteTracks[participantId].length;
                            i++
                        ) {
                            const _track = this.remoteTracks[participantId][i];
                            if (_track.getType() === track.getType()) {
                                _.remove(
                                    this.remoteTracks[participantId],
                                    this.remoteTracks[participantId][i]
                                );
                                break;
                            }
                        }
                    }
                }
            }
        }

        store.dispatch(
            eventStore.actions.setParticipants(this.room.getParticipants())
        );
    }

    async unload() {
        if (this.room && this.room.room) {
            const currentTracks = this.localTracks;
            for (let i = 0; i < currentTracks.length; i++) {
                if (!currentTracks[i].disposed) {
                    await currentTracks[i].dispose().catch(err => {
                        console.log(err);
                    });
                }
            }
            await this.room.leave().catch(error => {
                console.info(error);
            });
            await this.connection.disconnect().catch(err => {
                console.log(err);
            });
            store.dispatch(eventStore.actions.endMeetingSuccess());
        } else {
            store.dispatch(eventStore.actions.endMeetingSuccess());
        }
    }

    changeLangTrack = newRoomName => {
        this.inputRoomname = newRoomName;

        store.dispatch(
            eventStore.actions.changeInputRoomname(this.inputRoomname)
        );

        if (!this.room) {
            return;
        }
        const participants = this.room.getParticipants();
        let interpreter_ids = [];
        let mic_off_interpreters = [];

        for (let j = 0; j < participants.length; j++) {
            if (participants[j].getProperty("output") === this.inputRoomname) {
                console.log(participants[j]);
                interpreter_ids.push(participants[j].getId());
            }

            if (
                participants[j].getProperty("output") ===
                `non-${this.inputRoomname}`
            ) {
                mic_off_interpreters.push(participants[j].getId());
            }
        }

        this._resetAudioInputs([]);
        this.audioMixer.streamsToMix = [];

        if (this.audioMixer.channelMerger) {
            this.audioMixer.channelMerger.disconnect();
        }

        // Inter
        if (this.inputRoomname === this.outputRoomname) {
            const audioTrack = this.localTracks.find(
                t => t.getType() === "audio"
            );
            this.audioMixer.addMediaTrackStream(audioTrack);
        }

        if (this.inputRoomname === ORIGINAL_ROOMNAME) {
            for (let key in this.remoteTracks) {
                if (this.remoteTracks.hasOwnProperty(key)) {
                    const participant = this.room.getParticipantById(key);
                    if (participant) {
                        let _isInterpreter = isInterpreter(participants, key); // check if the participant is an interpreter
                        const audioTrack = this.remoteTracks[key].find(
                            t => t.getType() === "audio" && t.disposed === false
                        );

                        if (audioTrack) {
                            if (this.user.role <= UserRole.INTERPRETER) {
                                this.audioTracks[key] = {
                                    audioTrack: audioTrack,
                                    muted: _isInterpreter,
                                    volume: this.volume
                                };
                            } else {
                                if (!_isInterpreter) {
                                    this.audioTracks[key] = {
                                        audioTrack: audioTrack,
                                        muted: false,
                                        volume: this.volume
                                    };
                                }
                            }
                        }
                    }
                }
            }
        } else {
            interpreter_ids.forEach(interpreter_id => {
                this._addParticipantAudio(interpreter_id);
            });

            if (this.isWithFloor) {
                for (let key in this.remoteTracks) {
                    if (
                        this.remoteTracks.hasOwnProperty(key) &&
                        interpreter_ids.indexOf(key) === -1
                    ) {
                        const participant = this.room.getParticipantById(key);
                        if (participant) {
                            const audioTrack = this.remoteTracks[key].find(
                                t =>
                                    t.getType() === "audio" &&
                                    t.disposed === false
                            );

                            if (audioTrack) {
                                let _isInterpreter = isInterpreter(
                                    participants,
                                    key
                                ); // check if the participant is an another relay interpreter
                                if (_isInterpreter) {
                                    if (
                                        this.user.role <= UserRole.INTERPRETER
                                    ) {
                                        this.audioTracks[key] = {
                                            audioTrack: audioTrack,
                                            muted: true,
                                            volume: this.volume
                                        };
                                    }
                                } else {
                                    this.audioTracks[key] = {
                                        audioTrack: audioTrack,
                                        muted: false,
                                        volume: this.volume / 4
                                    };
                                }
                            }
                        }
                    }
                }
            }

            this.audioMixer.playFilter(this.room, this.isWithFloor);
        }

        store.dispatch(eventStore.actions.updateAudioTracks(this.audioTracks));
    };

    /**
     * Reset audio inputs except inputs
     * @param {Array} exceptParticipants - except participants ID to remain audio input
     */
    _resetAudioInputs = (exceptParticipants = []) => {
        let _remainedAudioTracks = {};

        if (exceptParticipants.length > 0) {
            for (var key in this.remoteTracks) {
                if (
                    this.remoteTracks.hasOwnProperty(key) &&
                    exceptParticipants.indexOf(key) !== -1
                ) {
                    _remainedAudioTracks = {
                        ..._remainedAudioTracks,
                        [key]: this.remoteTracks[key]
                    };
                }
            }
        }

        this.audioTracks = _remainedAudioTracks;
    };

    _addParticipantAudio = participantId => {
        for (var key in this.remoteTracks) {
            if (this.remoteTracks.hasOwnProperty(key)) {
                for (let j = 0; j < this.remoteTracks[key].length; j++) {
                    if (this.remoteTracks[key][j].getType() === "audio") {
                        this.audioTracks[key] = {
                            audioTrack: this.remoteTracks[key][j],
                            muted: key !== participantId,
                            volume: this.volume
                        };
                        this.audioMixer.addMediaTrackStream(
                            this.remoteTracks[key][j]
                        );
                        break;
                    }
                }
            }
        }
    };

    onMessageReceived(id, text, ts, isPrivate = false) {
        if (this.room.myUserId() !== id) {
            store.dispatch(eventStore.actions.changeMessageStatus(true));
            this.receiveMessageObject = { id, text, ts, isPrivate };
            store.dispatch(
                eventStore.actions.updateMessage(this.receiveMessageObject)
            );
        }
    }

    onSpeakerChanged(id) {
        const participant = this.room.getParticipantById(id);
        if (participant) {
            const role = parseInt(participant.getProperty("role"));
            if (this.event && this.event.event_type === EventType.CONFERENCE) {
                if (role && role === UserRole.EMITTER) {
                    this.setFocusedId(id);
                }
            } else {
                if (role && role !== UserRole.INTERPRETER) {
                    this.setFocusedId(id);
                }
            }
        }
    }

    setFocusedId(id) {
        this.focusedId = id;
        store.dispatch(eventStore.actions.setFocusedId(id));
    }

    onAudioLevelChanged(userId, audioLevel) {
        store.dispatch(
            eventStore.actions.setAudioLevelChanged({ userId, audioLevel })
        );
    }

    /**
     * Handle the participant property feature updates
     * @param {*} participant
     * @param {*} name Changed property
     * @param {*} oldValue
     * @param {*} newValue
     */
    handleFeatureChange(participant, name, oldValue, newValue) {
        console.log(name);
        console.log(participant);
        console.log(newValue);
        if (name === "roomname") {
            store.dispatch(
                eventStore.actions.setParticipants(this.room.getParticipants())
            );
        }

        if (name === "role") {
            if (parseInt(participant.getProperty("role")) <= UserRole.ADMIN) {
                // setIsStream(parseInt(participant.getProperty("stream")) === 1)
            }

            // Interpreter role seems not to be set in the beginning, so here check if interpreter has video track or not.
            if (parseInt(newValue) === UserRole.INTERPRETER) {
                store.dispatch(
                    eventStore.actions.setParticipants(
                        this.room.getParticipants()
                    )
                );
            }

            if (parseInt(participant.getProperty("role")) <= UserRole.USER) {
                // $(`#${participant.getId()}Place .hoverPlace`).hide();
            } else if (
                parseInt(participant.getProperty("role")) === UserRole.OBSERVER
            ) {
                // $(`#${participant.getId()}Place .hoverPlace`).show();
            }

            this.changeLangTrack(this.inputRoomname);
        }

        if (name === "output") {
            if (participant.getProperty("d_output") === this.selfRoomname) {
                store.dispatch(
                    eventStore.actions.setGroupTranslator(participant)
                );
            }
            this.changeLangTrack(this.inputRoomname);
        }

        if (name === "d_output") {
            if (newValue === this.selfRoomname) {
                store.dispatch(
                    eventStore.actions.setGroupTranslator(participant)
                );
            }
            this.changeLangTrack(this.inputRoomname);
        }
    }

    handleModeratorEvent(results) {
        const value = results.value;
        if (value === this.room.myUserId()) {
            if (results.attributes.actionType === "notify") {
                if (results.attributes.content === "msg") {
                    store.dispatch(
                        eventStore.actions.showNotification(
                            "info",
                            results.attributes.msg_en
                        )
                    );
                }
            }
        }
    }

    /**
     *
     * @param {Array} newTracks
     */
    async updateLocalTracks(newTracks) {
        for (let i = 0; i < newTracks.length; i++) {
            const sameTypeTrack = this.localTracks.find(
                ot => ot.disposed || ot.getType() === newTracks[i].getType()
            );
            if (sameTypeTrack) {
                if (!sameTypeTrack.disposed) {
                    await sameTypeTrack.dispose().catch(err => {
                        console.log(err);
                    });
                }
                _.remove(this.localTracks, sameTypeTrack);
            }
            if (!newTracks[i].disposed) {
                this.localTracks.push(newTracks[i]);
            }
        }

        store.dispatch(eventStore.actions.updateLocalTracks(this.localTracks));
    }

    removeLocalTrackbyType(type) {
        const newTracks = this.localTracks.filter(
            track => track.getType() !== type
        );
        this.localTracks = newTracks;
        store.dispatch(eventStore.actions.updateLocalTracks(this.localTracks));
    }

    async updateHandleCameraAndShare() {
        const currentLocalTrack = this.localTracks.find(
            track => track.getType() === "video"
        );
        if (this.isVideo) {
            if (this.isCameraOn) {
                if (currentLocalTrack && !currentLocalTrack.disposed) {
                    try {
                        console.log(currentLocalTrack);
                        await currentLocalTrack.setEffect(undefined);
                        await currentLocalTrack.dispose();
                    } catch (err) {
                        console.log(err);
                    }
                }
                if (this.isShareOtherCamera) {
                    const otherCameraDevice = this.cameraDevices.find(
                        device => device.deviceId !== this.cameraSetting
                    );
                    console.log("1");
                    this._handleLocalDeviceTrack(
                        otherCameraDevice.deviceId,
                        this.cameraSetting
                    );
                } else {
                    console.log("2");
                    this._handleLocalDeviceTrack(this.cameraSetting);
                }
            } else {
                const otherCameraDevice1 = this.cameraDevices.find(
                    device => device.deviceId !== this.cameraSetting
                );
                console.log(otherCameraDevice1);
                console.log(currentLocalTrack);
                if (currentLocalTrack && !currentLocalTrack.disposed) {
                    await currentLocalTrack.setEffect(undefined).catch(err => {
                        console.log(err);
                    });
                    if (!this.isShareOtherCamera) {
                        await currentLocalTrack.dispose().catch(err => {
                            console.log(err);
                        });
                        this.removeLocalTrackbyType("video");
                    } else if (currentLocalTrack.videoType === "desktop") {
                        await currentLocalTrack.dispose().catch(err => {
                            console.log(err);
                        });
                        const otherCameraDevice = this.cameraDevices.find(
                            device => device.deviceId !== this.cameraSetting
                        );
                        console.log("3");
                        this._handleLocalDeviceTrack(
                            otherCameraDevice.deviceId
                        );
                    }
                } else {
                    if (this.isShareOtherCamera) {
                        const otherCameraDevice = this.cameraDevices.find(
                            device => device.deviceId !== this.cameraSetting
                        );
                        console.log("4");
                        if (otherCameraDevice) {
                            this._handleLocalDeviceTrack(
                                otherCameraDevice.deviceId
                            );
                        }
                    }
                }
            }
        } else {
            if (this.isCameraOn) {
                if (
                    currentLocalTrack &&
                    !currentLocalTrack.disposed &&
                    currentLocalTrack.videoType === "desktop"
                ) {
                    const effect = await mutePresenterVideo(
                        currentLocalTrack,
                        this.cameraSetting
                    ).catch(err => {
                        console.log(err);
                    });
                    await currentLocalTrack.setEffect(effect).catch(err => {
                        console.log(err);
                    });
                    const newLocalTrack = this.room.getLocalVideoTrack();
                    // newLocalTrack.attach(document.getElementById('bigVideo'));
                    // newLocalTrack.attach(
                    //     document.getElementById("selfVideo")
                    // );
                    this.updateLocalTracks([newLocalTrack]);
                    return;
                }
                this._handleShareDesktop(currentLocalTrack, this.cameraSetting);
            } else {
                if (currentLocalTrack && !currentLocalTrack.disposed) {
                    await currentLocalTrack.setEffect(undefined).catch(err => {
                        console.log(err);
                    });
                    if (currentLocalTrack.videoType !== "desktop") {
                        this._handleShareDesktop(currentLocalTrack);
                    }
                } else {
                    this._handleShareDesktop();
                }
            }
        }
    }

    /**
     * Add local video track with deviceId
     * @param {*} deviceId
     * @param {*} _cameraSetting if not null, local video track is set effect.
     */
    _handleLocalDeviceTrack(deviceId, _cameraSetting = null) {
        JitsiMeetJS.createLocalTracks({
            devices: ["video"],
            maxFps: 60,
            minFps: 30,
            cameraDeviceId: deviceId
        })
            .then(async ([newVideoTrack]) => {
                if (_cameraSetting) {
                    const effect = await mutePresenterVideo(
                        newVideoTrack,
                        _cameraSetting
                    ).catch(err => {
                        console.log(err);
                    });
                    await newVideoTrack.setEffect(effect).catch(err => {
                        console.log(err);
                    });
                }
                // newVideoTrack.attach(document.getElementById('bigVideo'));
                // newVideoTrack.attach(document.getElementById("selfVideo"));
                if (this.room && this.room.room) {
                    console.log(this.room);
                    await this.room.addTrack(newVideoTrack).catch(err => {
                        console.log(err);
                    });
                }

                this.updateLocalTracks([newVideoTrack]);
            })
            .catch(error => {
                console.log(error);
            });
    }

    /**
     * Add desktop sharing track
     * @param {*} currentLocalTrack
     * @param {*} _cameraSetting if not null, desktop track is set effect
     */
    _handleShareDesktop(currentLocalTrack = null, _cameraSetting = null) {
        JitsiMeetJS.createLocalTracks({
            devices: ["desktop"],
            maxFps: 60,
            minFps: 30
        })
            .then(async ([newVideoTrack]) => {
                if (currentLocalTrack && !currentLocalTrack.disposed) {
                    if (_cameraSetting) {
                        await currentLocalTrack
                            .setEffect(undefined)
                            .catch(err => {
                                console.log(err);
                            });
                    }
                    await currentLocalTrack.dispose().catch(err => {
                        console.log(err);
                    });
                }
                if (_cameraSetting) {
                    const effect = await mutePresenterVideo(
                        newVideoTrack,
                        _cameraSetting
                    ).catch(err => {
                        console.log(err);
                    });
                    await newVideoTrack.setEffect(effect).catch(err => {
                        console.log(err);
                    });
                }
                newVideoTrack.addEventListener(
                    JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
                    () => {
                        this.setIsVideo(true);
                    }
                );
                // newVideoTrack.attach(document.getElementById('bigVideo'));
                // newVideoTrack.attach(document.getElementById("selfVideo"));
                if (this.room && this.room.room) {
                    this.room.addTrack(newVideoTrack);
                }

                this.updateLocalTracks([newVideoTrack]);
            })
            .catch(err => {
                this.setIsVideo(true);
            });
    }

    /**
     * True is Camera, False is Desktop
     * @param {boolean} value
     */
    setIsVideo(value, shouldDispatch = true) {
        if (!value) {
            this.isShareOtherCamera = false;
        }
        this.isVideo = value;

        this.updateHandleCameraAndShare();
        if (shouldDispatch)
            store.dispatch(eventStore.actions.setIsVideo(this.isVideo));
    }

    /**
     *
     * @param {boolean} value
     */
    changeShareOtherCamera(value, shouldDispatch = true) {
        if (value) {
            this.isVideo = true;
        }
        this.isShareOtherCamera = value;

        this.updateHandleCameraAndShare();
        if (shouldDispatch)
            store.dispatch(
                eventStore.actions.setIsShareOtherCamera(
                    this.isShareOtherCamera
                )
            );
    }

    /**
     *
     * @param {boolean} value
     */
    changeCameraOn(value, shouldDispatch = true) {
        this.isCameraOn = value;

        this.updateHandleCameraAndShare();
        if (shouldDispatch)
            store.dispatch(eventStore.actions.changeCameraOn(this.isCameraOn));
    }

    changeMicOn(isMicOn) {
        this.isMicOn = isMicOn;
        this.setAudioInputSetting();
        store.dispatch(eventStore.actions.changeMicOn(this.isMicOn));
        let outputRoomname = this.outputRoomname;
        outputRoomname = outputRoomname.replace("non-", "");
        if (!this.isMicOn && outputRoomname !== "") {
            outputRoomname = `non-${outputRoomname}`;
        }
        if (this.user.role === UserRole.INTERPRETER)
            this.setOutputRoomname(outputRoomname);
    }

    setIsWithFloor(value) {
        this.isWithFloor = value;
        this.changeLangTrack(this.inputRoomname);
        store.dispatch(eventStore.actions.setIsWithFloor(this.isWithFloor));
    }

    /**
     *
     * @param {*} outputRoomname
     */
    setOutputRoomname(outputRoomname) {
        this.outputRoomname = outputRoomname;
        if (this.room && this.room.room) {
            this.room.setLocalParticipantProperty("output", outputRoomname);
            this.changeLangTrack(this.inputRoomname);
        }

        store.dispatch(
            eventStore.actions.changeOutputRoomname(this.outputRoomname)
        );
    }

    /**
     *
     * @param {*} selfRoomname
     */
    setSelfRoomname(selfRoomname) {
        this.selfRoomname = selfRoomname;
        if (this.room && this.room.room) {
            this.room.setLocalParticipantProperty("d_output", selfRoomname);
        }
    }

    changeMute(isMuted, reset = true) {
        this.isMuted = isMuted;

        if (reset) {
            this.setAudioInputSetting();
        }

        store.dispatch(eventStore.actions.changeMute(this.isMuted));
        console.log(this.isMuted);
    }

    setIsSubTitle(value) {
        this.isSubTitle = value;
        store.dispatch(eventStore.actions.setIsSubTitle(this.isSubTitle));
    }

    changeVolume(value) {
        if (value > 0) {
            if (this.volume <= 0.9) {
                this.volume = this.volume + value;
            } else {
                this.volume = 1;
            }
        } else {
            if (this.volume >= 0.1) {
                this.volume = this.volume + value;
            } else {
                this.volume = 0;
            }
        }

        if (this.audioMixer.gainNode) {
            this.audioMixer.gainNode.gain.value = this.volume;
        }

        this.changeLangTrack(this.inputRoomname);
        store.dispatch(eventStore.actions.changeVolume(this.volume));
    }

    changeTreble(value) {
        if (value > 0) {
            if (this.treble <= 39) {
                this.treble = this.treble + value;
            } else {
                this.treble = 40;
            }
        } else {
            if (this.treble >= 1) {
                this.treble = this.treble + value;
            } else {
                this.treble = 0;
            }
        }

        if (this.audioMixer.trebleFilter) {
            this.audioMixer.trebleFilter.gain.value = this.treble;
        }

        this.changeLangTrack(this.inputRoomname);
        store.dispatch(eventStore.actions.changeTreble(this.treble));
    }

    changeBass(value) {
        if (value > 0) {
            if (this.bass <= 39) {
                this.bass = this.bass + value;
            } else {
                this.bass = 40;
            }
        } else {
            if (this.bass >= 1) {
                this.bass = this.bass + value;
            } else {
                this.bass = 0;
            }
        }

        if (this.audioMixer.bassFilter) {
            this.audioMixer.bassFilter.gain.value = this.bass;
        }

        this.changeLangTrack(this.inputRoomname);
        store.dispatch(eventStore.actions.changeBass(this.bass));
    }
}

export const jitsiMeeting = new JitsiMeeting();
