import { SUBTITLE_WIDTH } from "./CommonStyles";

/**
 * @param {*} isMosaic if True, now layout is mosaic.
 * @param {*} currentPartitions
 */
export function calcWidth(isMosaic, currentPartitionsCount, isSubTitle) {
    const mosaicELems = document.querySelectorAll("#remotePlace > div");

    let width = "100%";
    let maxWidth = "100%";
    if (isMosaic) {
        let currentPLength = currentPartitionsCount + 1;

        const lengthRoot = Math.sqrt(currentPLength);
        if (Math.floor(lengthRoot) === lengthRoot) {
            currentPLength = lengthRoot;
        } else {
            currentPLength = Math.floor(lengthRoot) + 1;
        }
        // 56 * 16 / 9 = 100
        width = `calc(${100 / currentPLength}% - 20px)`;

        const pWrapperElem = document.getElementById("pWrapper");
        console.log(pWrapperElem.offsetWidth);
        console.log(pWrapperElem.offsetWidth / pWrapperElem.offsetHeight);
        if (
            pWrapperElem.offsetWidth /
                (pWrapperElem.offsetHeight -
                    (isSubTitle ? SUBTITLE_WIDTH : 0)) >
            16 / 9
        ) {
            maxWidth = `${((pWrapperElem.offsetHeight -
                (isSubTitle ? SUBTITLE_WIDTH : 0) -
                30) *
                16) /
                9 /
                currentPLength}px`;
            console.log(maxWidth);
        }
    }

    for (let i = 0; i < mosaicELems.length; i++) {
        mosaicELems[i].style.width = width;
        mosaicELems[i].style.maxWidth = maxWidth;
    }
}

export function calcSidePlaceHeight() {
    const remotePlaceElem = document.getElementById("remotePlace");
    const pWrapperElem = document.getElementById("pWrapper");

    remotePlaceElem.style.maxHeight = `${pWrapperElem.offsetHeight}px`;
}
