import { put, takeEvery } from 'redux-saga/effects';

import { get_stat_conferences_counts, get_stat_events, get_stat_devices, get_stat_systems, get_stat_browsers, get_stat_events_details } from "../../crud/dashboard.crud"

export const actionTypes = {
  GET_STAT_CONFERENCE_COUNTS: "GET_STAT_CONFERENCE_COUNTS",
  GET_STAT_CONFERENCE_COUNTS_SUCCESS: "GET_STAT_CONFERENCE_COUNTS_SUCCESS",
  GET_STAT_EVENTS: "GET_STAT_EVENTS",
  GET_STAT_EVENTS_SUCCESS: "GET_STAT_EVENTS_SUCCESS",
  GET_STAT_EVENTS_DETAILS: "GET_STAT_EVENTS_DETAILS",
  GET_STAT_EVENTS_DETAILS_SUCCESS: "GET_STAT_EVENTS_DETAILS_SUCCESS",
  GET_STAT_DEVICES: "GET_STAT_DEVICES",
  GET_STAT_DEVICES_SUCCESS: "GET_STAT_DEVICES_SUCCESS",
  GET_STAT_SYSTEMS: "GET_STAT_SYSTEMS",
  GET_STAT_SYSTEMS_SUCCESS: "GET_STAT_SYSTEMS_SUCCESS",
  GET_STAT_BROWSERS: "GET_STAT_BROWSERS",
  GET_STAT_BROWSERS_SUCCESS: "GET_STAT_BROWSERS_SUCCESS",
};


export const actions = {
  getStatConferencesCounts: () => ({ type: actionTypes.GET_STAT_CONFERENCE_COUNTS }),
  getStatConferencesCountsSuccess: (total_counts, total, terminated) => ({ type: actionTypes.GET_STAT_CONFERENCE_COUNTS_SUCCESS, total_counts: total_counts, total: total, terminated: terminated }),
  getStatEvents: () => ({ type: actionTypes.GET_STAT_EVENTS }),
  getStatEventsSuccess: (events) => ({ type: actionTypes.GET_STAT_EVENTS_SUCCESS, events: events }),
  getStatEventsDetails: () => ({ type: actionTypes.GET_STAT_EVENTS_DETAILS }),
  getStatEventsDetailsSuccess: (eventsDetails) => ({ type: actionTypes.GET_STAT_EVENTS_DETAILS_SUCCESS, eventsDetails: eventsDetails }),
  getStatDevices: () => ({ type: actionTypes.GET_STAT_DEVICES }),
  getStatDevicesSuccess: (devices) => ({ type: actionTypes.GET_STAT_DEVICES_SUCCESS, devices: devices }),
  getStatSystems: () => ({ type: actionTypes.GET_STAT_SYSTEMS }),
  getStatSystemsSuccess: (systems) => ({ type: actionTypes.GET_STAT_SYSTEMS_SUCCESS, systems: systems }),
  getStatBrowsers: () => ({ type: actionTypes.GET_STAT_BROWSERS }),
  getStatBrowsersSuccess: (browsers) => ({ type: actionTypes.GET_STAT_BROWSERS_SUCCESS, browsers: browsers }),
};

const initialTransState = {
  total_counts: [
    { 'total_count': 0, 'terminated': 0, 'event_type': 1 },
    { 'total_count': 0, 'terminated': 0, 'event_type': 2 },
    { 'total_count': 0, 'terminated': 0, 'event_type': 3 },
    { 'total_count': 0, 'terminated': 0, 'event_type': 4 }
  ],
  total: 0,
  terminated: 0,
  isGetStatConference: false,
  events: [],
  eventsDetails: [],
  devices: [],
  systems: [],
  browsers: []
};


export const reducer = (state = initialTransState, action) => {
  switch (action.type) {
    case actionTypes.GET_STAT_CONFERENCE_COUNTS_SUCCESS: {
      return {
        ...state,
        total_counts: action.total_counts,
        total: action.total,
        terminated: action.terminated,
        isGetStatConference: false
      }
    }
    case actionTypes.GET_STAT_EVENTS_SUCCESS: {
      return {
        ...state,
        events: action.events
      }
    }
    case actionTypes.GET_STAT_EVENTS_DETAILS_SUCCESS: {
      return {
        ...state,
        eventsDetails: action.eventsDetails
      }
    }
    case actionTypes.GET_STAT_DEVICES_SUCCESS: {
      return {
        ...state,
        devices: action.devices
      }
    }
    case actionTypes.GET_STAT_SYSTEMS_SUCCESS: {
      return {
        ...state,
        systems: action.systems
      }
    }
    case actionTypes.GET_STAT_BROWSERS_SUCCESS: {
      return {
        ...state,
        browsers: action.browsers
      }
    }
    default:
      return state;
  }
};

export function* dashboardSaga() {
  yield takeEvery(actionTypes.GET_STAT_CONFERENCE_COUNTS, function* getStatConferencesCountsSaga() {
    try {
      const response = yield get_stat_conferences_counts();
      const data = yield response.data;
      yield put(actions.getStatConferencesCountsSuccess(data.total_counts, data.total, data.terminated));
    } catch {
      yield put(actions.getStatConferencesCountsSuccess(initialTransState.total_counts, initialTransState.total, initialTransState.terminated));
    }
  });

  yield takeEvery(actionTypes.GET_STAT_EVENTS, function* getStatEventSaga() {
    try {
      const response = yield get_stat_events()
      const array_data = response.data.events.map(event => {
        return [event.event_date, event.p_count]
      })

      yield put(actions.getStatEventsSuccess(array_data));
    } catch {
      yield put(actions.getStatEventsSuccess([]));
    }
  });

  yield takeEvery(actionTypes.GET_STAT_EVENTS_DETAILS, function* getStatEventsDetailsSaga() {
    try {
      const response = yield get_stat_events_details();
      const data = yield response.data;
      yield put(actions.getStatEventsDetailsSuccess(data.events));
    } catch {
      yield put(actions.getStatEventsDetailsSuccess([]));
    }
  });

  yield takeEvery(actionTypes.GET_STAT_DEVICES, function* getStatDevicesSaga() {
    try {
      const response = yield get_stat_devices();
      const data = yield response.data ?? [];
      yield put(actions.getStatDevicesSuccess(data.devices));
    } catch {
      yield put(actions.getStatDevicesSuccess([]));
    }
  });

  yield takeEvery(actionTypes.GET_STAT_SYSTEMS, function* getStatSystemsSaga() {
    try {
      const response = yield get_stat_systems();
      const data = yield response.data ?? [];
      yield put(actions.getStatSystemsSuccess(data.systems));
    } catch {
      yield put(actions.getStatSystemsSuccess([]));
    }
  });

  yield takeEvery(actionTypes.GET_STAT_BROWSERS, function* getStatBrowsersSaga() {
    try {
      const response = yield get_stat_browsers();
      const data = yield response.data ?? [];
      yield put(actions.getStatBrowsersSuccess(data.browsers));
    } catch {
      yield put(actions.getStatBrowsersSuccess([]));
    }
  });

}
