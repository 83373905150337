import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

function ConferencesWidget(props) {
  const { conferenceData } = props
  const { totalData, terminatedData, upcomingData } = conferenceData
  return (
    <div className="kt-widget1">
      <div className="kt-widget1__item ng-star-inserted">

        <div className="kt-widget1__info">
          <h3 className="kt-widget1__title">{totalData.label}</h3>
          {/* <span className="kt-widget1__desc">Weekly Customer Orders</span> */}
        </div>

        <span className="kt-widget1__number kt-font-danger">{totalData.amount}</span>
      </div>

      <div className="kt-widget1__item ng-star-inserted">
        <div className="kt-widget1__info">
          <h3 className="kt-widget1__title">{terminatedData.label}</h3>
          {/* <span className="kt-widget1__desc">Awerage Weekly Profit</span> */}
        </div>
        <span className="kt-widget1__number kt-font-brand">{terminatedData.amount}</span>
      </div>

      <div className="kt-widget1__item ng-star-inserted">
        <div className="kt-widget1__info">
          <h3 className="kt-widget1__title">{upcomingData.label}</h3>
          {/* <span className="kt-widget1__desc">System bugs and issues</span> */}
        </div>
        <span className="kt-widget1__number kt-font-success">{upcomingData.amount}</span>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    // user: state.auth.user,
    // isEndMeeting: state.customerReducer.isEndMeeting,
    // roomList: state.customerReducer.roomList,
  };
};

const mapDispatchToProps = dispatch => ({
  // endMeetingSuccess: () => dispatch(eventsStore.actions.endMeetingSuccess()),
  // endMeeting: () => dispatch(eventsStore.actions.endMeeting()),
  // updateRoomList: (roomList) => dispatch(eventsStore.actions.updateRoomList(roomList)),
  // addStat: (data) => dispatch(eventsStore.actions.addStat(data)),
})

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConferencesWidget)
);