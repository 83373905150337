import React, { useEffect } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types'
import { injectIntl } from "react-intl";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import * as dashboardStore from "../store/ducks/dashboard.duck";

function StatisticsChart(props) {
    const { getStatEvents, events, intl } = props

    useEffect(() => {
        getStatEvents()
    }, [getStatEvents])

    const options = {
        title: {
            text: intl.formatMessage({ id: "DASHBOARD.STATISTICS.TITLE" })
        },
        chart: {
            zoomType: 'x',
            type: 'column',
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            title: {
                text: intl.formatMessage({ id: "DASHBOARD.STATISTICS.COLUMN" })
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0,
                borderWidth: 0
            }
        },
        series: [{
            data: events,
            name: intl.formatMessage({ id: "DASHBOARD.STATISTICS.COLUMN" })

        }]
    }


    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}


const mapStateToProps = state => {
    return {
        events: state.dashboard.events,
    };
};

const mapDispatchToProps = dispatch => ({
    getStatEvents: () => dispatch(dashboardStore.actions.getStatEvents()),
})

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(StatisticsChart)
);