import { apiGetRequest, apiPostRequest } from "./helper"
const BASE_URL = process.env.REACT_APP_BASE_URL
export const CHANNEL_URL = BASE_URL + "channel_list";
export const CHANNEL_EDIT_URL = BASE_URL + "channels";

export function get_channels() {
  return apiGetRequest(CHANNEL_URL);
}

export function get_channel(id) {
  return apiGetRequest(CHANNEL_EDIT_URL + `/${id}`);
}

export function update_channel(id, newChannel) {
  return apiPostRequest(CHANNEL_EDIT_URL + `/${id}`, newChannel);
}

export function add_channel(channel) {
  return apiPostRequest(BASE_URL + `create_channel`, channel);
}

export function remove_channel(id) {
  return apiPostRequest(BASE_URL + `delete_channel/${id}`);
}
