import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";

import * as eventsStore from "../../../../store/ducks/customers/index.duck";

import Conference from "./Conference/Conference";
import Meeting from "./Meeting/Meeting";
import Webinar from "./Webinar/Webinar";
import { UserRole } from "../../../../components/utils/UserRole";

function Event(props) {
    const { event, getEvent, isEvent, intl } = props;
    const event_id = props.match.params.id;
    const [currentEvent, setCurrentEvent] = useState(null);
    const [loading, setLoading] = useState(false);
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        getEvent(event_id);
        setLoading(true);
        return () => {
            setLoading(false);
        };
    }, [event_id, getEvent]);

    useEffect(() => {
        if (isEvent) {
            setCurrentEvent(event);
        }
    }, [isEvent, event]);

    return (
        <>
            {!currentEvent ? (
                <>
                    <div className="kt-error-v3">
                        <div className="kt-error_container">
                            <p className="kt-error_subtitle">
                                {loading && isEvent
                                    ? intl.formatMessage({
                                        id: "EVENTS.EVENT_ERROR"
                                    })
                                    : ""}
                            </p>
                        </div>
                    </div>
                </>
            ) : currentEvent.event_type === 1 ||
                user.role === UserRole.LITE_USER ? (
                <Conference event={currentEvent} />
            ) : currentEvent.event_type === 2 ? (
                <Meeting event={currentEvent} />
            ) : (
                <Webinar event={currentEvent} />
            )}
        </>
    );
}

const mapStateToProps = state => {
    return {
        event: state.customerReducer.event,
        isEvent: state.customerReducer.isEvent
    };
};

const mapDispatchToProps = dispatch => ({
    getEvent: id => dispatch(eventsStore.actions.getCustomerEvent(id))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Event));
