import { createPresenterEffect } from '../presenter'
const JitsiMeetJS = window.JitsiMeetJS

export async function mutePresenterVideo(localTrack, cameraDeviceId) {
    const height = localTrack.track.getSettings().height
    const effect = await _createPresenterStreamEffect(height, cameraDeviceId);
    return effect
}

async function _createPresenterStreamEffect(height = null, cameraDeviceId = null) {
    const localPresenterVideo = await _createLocalPresenterTrack({ cameraDeviceId }, height)
    const effect = await createPresenterEffect(localPresenterVideo.stream);
    return effect
}

async function _createLocalPresenterTrack(options, desktopHeight) {
    console.log(desktopHeight)
    const { cameraDeviceId } = options;
    const cameraHeights = [180, 270, 360, 540, 720, 1080, 2160];
    const proportion = 5;
    const result = cameraHeights.find(
        height => (desktopHeight / proportion) <= height);
    const constraints = {
        video: {
            aspectRatio: 16 / 9,
            height: {
                ideal: result
            }
        }
    };
    const [videoTrack] = await JitsiMeetJS.createLocalTracks(
        {
            cameraDeviceId,
            constraints,
            devices: ['video']
        });
    return videoTrack;
}