import { put, takeEvery } from 'redux-saga/effects';

import { get_events, get_event, update_event, add_event, remove_event, get_participants_list, add_participants, remove_participant } from "../../../crud/admins/event.crud"

export const actionTypes = {
  GET_ADMIN_EVENTS: "GET_ADMIN_EVENTS",
  RENDER_EVENT_LIST: "RENDER_EVENT_LIST",
  ADMIN_UPDATE_EVENTS: "ADMIN_UPDATE_EVENTS",
  ADMIN_UPDATE_EVENTS_SUCCESS: "ADMIN_UPDATE_EVENTS_SUCCESS",
  ADMIN_ADD_EVENT: "ADMIN_ADD_EVENT",
  ADMIN_ADD_EVENT_SUCCESS: "ADMIN_ADD_EVENT_SUCCESS",
  ADMIN_REMOVE_EVENT: "ADMIN_REMOVE_EVENT",
  ADMIN_REMOVE_EVENT_SUCCESS: "ADMIN_REMOVE_EVENT_SUCCESS",
  GET_PARTICIPANTS_LIST: "GET_PARTICIPANTS_LIST",
  GET_PARTICIPANTS_LIST_SUCCESS: "GET_PARTICIPANTS_LIST_SUCCESS",
  ADMIN_ADD_PARTICIPANTS: "ADMIN_ADD_PARTICIPANTS",
  ADMIN_ADD_PARTICIPANTS_SUCCESS: "ADMIN_ADD_PARTICIPANTS_SUCCESS",
  ADMIN_REMOVE_PARTICIPANT: "ADMIN_REMOVE_PARTICIPANT",
  ADMIN_REMOVE_PARTICIPANT_SUCCESS: "ADMIN_REMOVE_PARTICIPANT_SUCCESS",
  GET_ADMIN_EVENT: "GET_ADMIN_EVENT",
  GET_ADMIN_EVENT_SUCCESS: "GET_ADMIN_EVENT_SUCCESS",
  SET_MUTE_ALL: "SET_MUTE_ALL"
};


export const actions = {
  getEvents: () => ({ type: actionTypes.GET_ADMIN_EVENTS }),
  getEvent: (id) => ({ type: actionTypes.GET_ADMIN_EVENT, event_id: id }),
  getEventSuccess: event => ({ type: actionTypes.GET_ADMIN_EVENT_SUCCESS, event: event }),
  renderEventList: eventList => ({ type: actionTypes.RENDER_EVENT_LIST, eventList: eventList }),
  updateEvents: event => ({ type: actionTypes.ADMIN_UPDATE_EVENTS, newEvent: event }),
  updateEventsSuccess: (event) => ({ type: actionTypes.ADMIN_UPDATE_EVENTS_SUCCESS, newEvent: event }),
  addEvent: event => ({ type: actionTypes.ADMIN_ADD_EVENT, newEvent: event }),
  addEventSuccess: event => ({ type: actionTypes.ADMIN_ADD_EVENT_SUCCESS, newEvent: event }),
  removeEvent: eventId => ({ type: actionTypes.ADMIN_REMOVE_EVENT, eventId: eventId }),
  removeEventSuccess: eventId => ({ type: actionTypes.ADMIN_REMOVE_EVENT_SUCCESS, eventId: eventId }),
  getParticipantsList: eventId => ({ type: actionTypes.GET_PARTICIPANTS_LIST, eventId: eventId }),
  getParticipantsListSuccess: participantsList => ({ type: actionTypes.GET_PARTICIPANTS_LIST_SUCCESS, participantsList: participantsList }),
  addParticipants: (eventId, userIds) => ({ type: actionTypes.ADMIN_ADD_PARTICIPANTS, eventId: eventId, userIds: userIds }),
  addParticipantsSuccess: () => ({ type: actionTypes.ADMIN_ADD_PARTICIPANTS_SUCCESS }),
  removeParticipant: participantId => ({ type: actionTypes.ADMIN_REMOVE_PARTICIPANT, participantId: participantId }),
  removeParticipantSuccess: participantId => ({ type: actionTypes.ADMIN_REMOVE_PARTICIPANT_SUCCESS, participantId: participantId }),
  setMuteAll: isMuteAll => ({ type: actionTypes.SET_MUTE_ALL, isMuteAll: isMuteAll })
};

const initialEventsState = {
  eventList: [],
  participantsList: [],
  isEvent: false,
  isMuteAll: false
};


export const reducer = (state = initialEventsState, action) => {
  switch (action.type) {
    case actionTypes.RENDER_EVENT_LIST: {
      return {
        ...state,
        eventList: action.eventList
      }
    }
    case actionTypes.GET_ADMIN_EVENT: {
      return {
        ...state,
        isEvent: false
      }
    }
    case actionTypes.GET_ADMIN_EVENT_SUCCESS: {
      return {
        ...state,
        event: action.event,
        isEvent: true
      }
    }
    case actionTypes.ADMIN_ADD_EVENT_SUCCESS: {
      let newEventList = [
        ...state.eventList,
        {
          ...action.newEvent
        }
      ];
      return {
        ...state,
        eventList: newEventList
      }
    }
    case actionTypes.ADMIN_UPDATE_EVENTS_SUCCESS: {
      let oldEventList = state.eventList
      const newEventList = oldEventList.map(event => {
        if (event.id === action.newEvent.id) {
          return action.newEvent
        } else {
          return event
        }
      })
      return {
        ...state,
        eventList: newEventList
      }
    }
    case actionTypes.ADMIN_REMOVE_EVENT_SUCCESS: {
      let oldEventList = [...state.eventList];
      const newEventList = oldEventList.filter(event => event.id !== action.eventId);
      return {
        ...state,
        eventList: newEventList
      }
    }
    case actionTypes.GET_PARTICIPANTS_LIST_SUCCESS: {
      return {
        ...state,
        participantsList: action.participantsList
      }
    }
    case actionTypes.ADMIN_REMOVE_PARTICIPANT_SUCCESS: {
      let oldParticipantsList = [...state.participantsList];
      const newParticipantsList = oldParticipantsList.filter(participant => participant.id !== action.participantId);
      return {
        ...state,
        participantsList: newParticipantsList
      }
    }
    case actionTypes.SET_MUTE_ALL: {
      return {
        ...state,
        isMuteAll: action.isMuteAll
      }
    }
    default:
      return state;
  }
};

export function* adminsModuleSaga() {
  yield takeEvery(actionTypes.GET_ADMIN_EVENTS, function* getEventsSaga() {
    try {

      const response = yield get_events();
      const data = yield response.data ?? [];
      yield put(actions.renderEventList(data.data));
    } catch {
      yield put(actions.renderEventList([]));
    }
  });

  yield takeEvery(actionTypes.GET_ADMIN_EVENT, function* getEventSaga(action) {
    const response = yield get_event(action.event_id);
    const data = yield response.data;
    yield put(actions.getEventSuccess(data.data));
  });


  yield takeEvery(actionTypes.ADMIN_UPDATE_EVENTS, function* updateEventsSaga(action) {
    const newEvent = action.newEvent
    const response = yield update_event(newEvent.id, newEvent)
    yield put(actions.updateEventsSuccess(response.data.event));
  });

  yield takeEvery(actionTypes.ADMIN_ADD_EVENT, function* addEventSaga(action) {
    const newEvent = action.newEvent
    const response = yield add_event(newEvent)
    yield put(actions.addEventSuccess(response.data.data));
  });

  yield takeEvery(actionTypes.ADMIN_REMOVE_EVENT, function* removeEventSaga(action) {
    const eventId = action.eventId
    yield remove_event(eventId)
    yield put(actions.removeEventSuccess(eventId));
  });

  yield takeEvery(actionTypes.GET_PARTICIPANTS_LIST, function* getParticipantsSaga(action) {
    const response = yield get_participants_list(action.eventId);
    const data = yield response.data ?? [];
    yield put(actions.getParticipantsListSuccess(data.participants));
  });

  yield takeEvery(actionTypes.ADMIN_ADD_PARTICIPANTS, function* addParticipantsSaga(action) {
    const userIds = action.userIds
    const eventId = action.eventId;
    yield add_participants(eventId, userIds)
    yield put(actions.getParticipantsList(eventId));
  });

  yield takeEvery(actionTypes.ADMIN_REMOVE_PARTICIPANT, function* removeParticipantsSaga(action) {
    const participantId = action.participantId
    yield remove_participant(participantId)
    yield put(actions.removeParticipantSuccess(participantId));
  });
}
