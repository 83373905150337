import React, { useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import {
    makeStyles,
    Paper,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    IconButton,
    TablePagination,
    Chip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";

import Container from "../../../../partials/content/Container";
import {
    EnhancedTableToolbar,
    EnhancedTableHead,
    stableSort,
    getSorting,
    searchArray
} from "../../../../components/EnhancedTable";
import * as managersStore from "../../../../store/ducks/managers.duck";
import ManagerDlg from "./ManagerDlg";
import ConfirmDlg from "../../../../components/ConfirmDlg";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750
    },
    tableWrapper: {
        overflowX: "auto"
    }
}));

function Managers(props) {
    const {
        managerList,
        getManagers,
        removeManager,
        activeManager,
        intl
    } = props;
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Table Keys
    const [openFormDlg, setOpenFormDlg] = React.useState(false);
    const [openDel, setOpenDel] = React.useState(false);
    const [openActive, setOpenActive] = React.useState(false);
    const [clickedValue, setClickedValue] = React.useState(-1);
    const [clickedManagerValue, setClickedManagerValue] = React.useState({
        name: "",
        email: ""
    });
    const [description, setDescription] = React.useState("");
    const [searchKey, setSearchKey] = React.useState("");

    const headRows = [
        {
            id: "name",
            numeric: false,
            disablePadding: false,
            label: intl.formatMessage({ id: "MANAGERS.FIELD.NAME" })
        },
        {
            id: "email",
            numeric: false,
            disablePadding: false,
            label: intl.formatMessage({ id: "MANAGERS.FIELD.EMAIL" })
        },
        {
            id: "is_active",
            numeric: false,
            disablePadding: false,
            label: intl.formatMessage({ id: "USERS.FIELD.STATUS" })
        }
    ];

    const searchable = ["name", "email"];

    useEffect(() => {
        getManagers();
    }, [getManagers]);

    // Handle Tables Events
    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, managerList.length - page * rowsPerPage);

    const isSelected = id => selected.indexOf(id) !== -1;

    function handleCheckClick(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = managerList.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }

    function handleAdd(event) {
        setClickedValue(-1);
        setOpenFormDlg(true);
    }

    function handleEdit(event, id) {
        const selectedManager = _.find(managerList, ["id", id]);
        setClickedManagerValue(selectedManager);
        setClickedValue(id);
        setOpenFormDlg(true);
    }

    function handleDeleteClick(event, id) {
        setDescription(intl.formatMessage({ id: "MANAGER.ALERT.DEL.DESC" }));
        setClickedValue(id);
        setOpenDel(true);
    }

    function handleClose(value) {
        setClickedManagerValue({ name: "", email: "" });
        setClickedValue(-1);
        setOpenFormDlg(false);
    }

    function handleDelete(value) {
        if (value) {
            removeManager(clickedValue);
        }
        setOpenDel(false);
    }

    function handleActiveClick(id, is_active) {
        setClickedValue(id);
        if (is_active === 0) {
            setDescription(intl.formatMessage({ id: "MANAGER.ALERT.ACTIVE" }));
        } else {
            setDescription(
                intl.formatMessage({ id: "MANAGER.ALERT.INACTIVE" })
            );
        }
        setOpenActive(true);
    }

    function handleActive(value) {
        if (value) {
            activeManager(clickedValue);
        }
        setOpenActive(false);
    }

    const onHandleChange = e => {
        setSearchKey(e.target.value);
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <Container
                        beforeCodeTitle={intl.formatMessage({
                            id: "MENU.USERS.MANAGERS"
                        })}
                        handleSearch={onHandleChange}
                        handleAdd={handleAdd}
                    >
                        <div className="kt-section">
                            <div className="kt-section__content">
                                <div className={classes.root}>
                                    <Paper className={classes.paper}>
                                        <EnhancedTableToolbar
                                            numSelected={selected.length}
                                        />
                                        <div className={classes.tableWrapper}>
                                            <Table
                                                className={classes.table}
                                                aria-labelledby="tableTitle"
                                                size="medium"
                                            >
                                                <EnhancedTableHead
                                                    numSelected={
                                                        selected.length
                                                    }
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onSelectAllClick={
                                                        handleSelectAllClick
                                                    }
                                                    onRequestSort={
                                                        handleRequestSort
                                                    }
                                                    rowCount={
                                                        managerList.length
                                                    }
                                                    headRows={headRows}
                                                />
                                                <TableBody>
                                                    {stableSort(
                                                        searchArray(
                                                            managerList,
                                                            searchKey,
                                                            searchable
                                                        ),
                                                        getSorting(
                                                            order,
                                                            orderBy
                                                        )
                                                    )
                                                        .slice(
                                                            page * rowsPerPage,
                                                            page * rowsPerPage +
                                                                rowsPerPage
                                                        )
                                                        .map((row, index) => {
                                                            const isItemSelected = isSelected(
                                                                row.id
                                                            );
                                                            const labelId = `enhanced-table-checkbox-${index}`;

                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    aria-checked={
                                                                        isItemSelected
                                                                    }
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                    key={row.id}
                                                                    selected={
                                                                        isItemSelected
                                                                    }
                                                                >
                                                                    <TableCell padding="checkbox">
                                                                        <Checkbox
                                                                            checked={
                                                                                isItemSelected
                                                                            }
                                                                            onClick={event =>
                                                                                handleCheckClick(
                                                                                    event,
                                                                                    row.id
                                                                                )
                                                                            }
                                                                            inputProps={{
                                                                                "aria-labelledby": labelId
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        id={
                                                                            labelId
                                                                        }
                                                                        scope="row"
                                                                        padding="none"
                                                                    >
                                                                        {
                                                                            row.name
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {
                                                                            row.email
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {row.is_active ===
                                                                        1 ? (
                                                                            <Chip
                                                                                label={intl.formatMessage(
                                                                                    {
                                                                                        id:
                                                                                            "USERS.FIELD.ACTIVE"
                                                                                    }
                                                                                )}
                                                                                onDelete={() =>
                                                                                    handleActiveClick(
                                                                                        row.id,
                                                                                        row.is_active
                                                                                    )
                                                                                }
                                                                                className={
                                                                                    classes.chip
                                                                                }
                                                                                color="secondary"
                                                                            />
                                                                        ) : (
                                                                            <Chip
                                                                                label={intl.formatMessage(
                                                                                    {
                                                                                        id:
                                                                                            "USERS.FIELD.INACTIVE"
                                                                                    }
                                                                                )}
                                                                                onDelete={() =>
                                                                                    handleActiveClick(
                                                                                        row.id,
                                                                                        row.is_active
                                                                                    )
                                                                                }
                                                                                className={
                                                                                    classes.chip
                                                                                }
                                                                                deleteIcon={
                                                                                    <DoneIcon />
                                                                                }
                                                                            />
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <IconButton
                                                                            aria-label="Edit"
                                                                            onClick={event =>
                                                                                handleEdit(
                                                                                    event,
                                                                                    row.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <EditIcon />
                                                                        </IconButton>

                                                                        <IconButton
                                                                            aria-label="Delete"
                                                                            onClick={event =>
                                                                                handleDeleteClick(
                                                                                    event,
                                                                                    row.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    {emptyRows > 0 && (
                                                        <TableRow
                                                            style={{
                                                                height:
                                                                    49 *
                                                                    emptyRows
                                                            }}
                                                        >
                                                            <TableCell
                                                                colSpan={8}
                                                            />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </div>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={
                                                searchArray(
                                                    managerList,
                                                    searchKey,
                                                    searchable
                                                ).length
                                            }
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            backIconButtonProps={{
                                                "aria-label": "Previous Page"
                                            }}
                                            nextIconButtonProps={{
                                                "aria-label": "Next Page"
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={
                                                handleChangeRowsPerPage
                                            }
                                        />
                                    </Paper>
                                </div>
                            </div>
                            <div>
                                <ManagerDlg
                                    managerId={clickedValue}
                                    managerValue={clickedManagerValue}
                                    open={openFormDlg}
                                    onClose={handleClose}
                                />
                            </div>
                            <div>
                                {openActive && (
                                    <ConfirmDlg
                                        isOpen={openActive}
                                        title={"Warning!"}
                                        description={description}
                                        handleConfirm={handleActive}
                                    />
                                )}
                                {openDel && (
                                    <ConfirmDlg
                                        isOpen={openDel}
                                        title={"Warning!"}
                                        description={description}
                                        handleConfirm={handleDelete}
                                    />
                                )}
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
}

Managers.propTypes = {
    managerList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired
        }).isRequired
    ).isRequired
};

const mapStateToProps = state => {
    return {
        managerList: state.managers.managerList
    };
};

export default injectIntl(
    connect(mapStateToProps, managersStore.actions)(Managers)
);
