import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../../../_metronic";
import {
    Paper,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Grid,
    Select,
    FormControl,
    MenuItem
} from "@material-ui/core";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import { Portlet, PortletBody } from "../../../../../partials/content/Portlet";
import Notify from "../../../../../components/Notify";
import EventLayout from "../../../../../components/EventLayout";
import MeetingVideo from "./MeetingVideo";
import { meeting as useStyles } from "../../../../../components/CommonStyles";

import * as eventStore from "../../../../../store/ducks/event.duck";
import { ORIGINAL_ROOMNAME } from "../../../../../components/JitsiMeeting";

function Meeting(props) {
    const {
        isEvent,
        event,
        isStartMeeting,
        startMeeting,
        screenMode,
        intl
    } = props;
    const classes = useStyles();
    const [lang, setLang] = React.useState("");
    const [volume, setVolume] = React.useState(0.5);
    const [isOriginal, setIsOriginal] = React.useState(false);
    const [roomsList, setRoomsList] = React.useState([]);

    useEffect(() => {
        if (isEvent) {
            const event_rooms = event.event_rooms;
            var resArr = [];
            event_rooms.filter(function(item) {
                var i = resArr.findIndex(
                    x => x.room.room_name === item.room.room_name
                );
                if (i <= -1) {
                    resArr.push(item);
                }
                return null;
            });
            setRoomsList(resArr);
        }
    }, [isEvent, event]);

    function handleStartMeeting() {
        if (lang) {
            startMeeting();
            const headerMenu = document.getElementById("kt_header_menu");
            const appendContent = document.getElementById("content_header");
            headerMenu.appendChild(appendContent);
        }
    }

    function handleReset() {
        window.location.reload();
    }

    function handleLangChange(event) {
        setLang(event.target.value);
    }

    function handleOriginal() {
        setIsOriginal(prevState => !prevState);
    }

    function handleDown(event) {
        setVolume(prevState => {
            if (prevState >= 0.1) {
                return prevState - 0.1;
            } else {
                return 0.0;
            }
        });
    }

    function handleUp(event) {
        setVolume(prevState => {
            if (prevState <= 0.9) {
                return prevState + 0.1;
            } else {
                return 1.0;
            }
        });
    }

    return (
        <EventLayout
            event={event}
            screenMode={screenMode}
            isStartMeeting={isStartMeeting}
        >
            <div
                className={`row ${!isStartMeeting && classes.eventPlace}`}
                id="eventPlace"
            >
                <div className="col-md-8" id="videoPlace">
                    <Notify />
                    {event && isStartMeeting ? (
                        <MeetingVideo
                            event={event}
                            roomname={lang}
                            volume={volume}
                            size="sm"
                            isOriginal={isOriginal}
                        />
                    ) : (
                        ""
                    )}
                </div>
                <div
                    className={`col-md-4 ${classes.control} ${screenMode < 0 &&
                        classes.fullWidthControl}`}
                >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <Button
                                target="_blank"
                                href="http://olyusei.speedtestcustom.com/"
                                color="primary"
                            >
                                Speed Test
                            </Button>
                        </div>
                        <div className="col-md-12">
                            <Paper className={classes.langControl}>
                                <FormControl
                                    variant="outlined"
                                    disabled={false}
                                    className={classes.formControl}
                                    error={lang ? false : true}
                                >
                                    <Select
                                        value={lang}
                                        onChange={handleLangChange}
                                        displayEmpty
                                        name="language"
                                    >
                                        <MenuItem value="">
                                            <em>
                                                {intl.formatMessage({
                                                    id: "EVENT.LANG.SELECT_LANG"
                                                })}
                                            </em>
                                        </MenuItem>
                                        <MenuItem value={ORIGINAL_ROOMNAME}>
                                            {intl.formatMessage({
                                                id: "EVENT.LANG.ORIGINAL"
                                            })}
                                        </MenuItem>
                                        {event &&
                                            roomsList.length > 0 &&
                                            roomsList.map((row, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        value={
                                                            row.room.room_name
                                                        }
                                                    >
                                                        <img
                                                            src={toAbsoluteUrl(
                                                                `/media/flags-svg/${row.room.output_lang.lang_flag}`
                                                            )}
                                                            alt={
                                                                row.room
                                                                    .output_lang
                                                                    .lang_name
                                                            }
                                                            className={
                                                                classes.flag
                                                            }
                                                        />
                                                        {
                                                            row.room.output_lang
                                                                .lang_label
                                                        }
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Paper>
                        </div>
                        <div className="col-md-12">
                            <Paper className={classes.volumeControl}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    className={classes.volume}
                                    onClick={handleDown}
                                >
                                    <VolumeDownIcon />
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    className={classes.volume}
                                    onClick={handleUp}
                                >
                                    <VolumeUpIcon />
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color={`${
                                        isOriginal ? "primary" : "default"
                                    }`}
                                    className={classes.volume}
                                    onClick={handleOriginal}
                                >
                                    + Ori
                                </Button>
                            </Paper>
                        </div>
                        <div className="col-md-12">
                            <Portlet>
                                <PortletBody>
                                    <div className={classes.tableWrapper}>
                                        <Table>
                                            <TableBody>
                                                <TableRow key="title"></TableRow>
                                                <TableRow key="detail">
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {intl.formatMessage({
                                                            id:
                                                                "EVENTS.TABLE_HEADER.DETAIL"
                                                        })}
                                                    </TableCell>
                                                    <TableCell>
                                                        {event
                                                            ? event.detail
                                                            : ""}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key="start_datetime">
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {intl.formatMessage({
                                                            id:
                                                                "EVENTS.TABLE_HEADER.START_DATE"
                                                        })}
                                                    </TableCell>
                                                    <TableCell>
                                                        {event
                                                            ? event.start_datetime
                                                            : ""}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <Grid
                                        container
                                        className={classes.root}
                                        spacing={2}
                                    >
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                justifyContent="center"
                                                spacing={2}
                                            >
                                                <Grid item xs={6}>
                                                    <Button
                                                        className={
                                                            classes.paper
                                                        }
                                                        disabled={
                                                            isStartMeeting
                                                        }
                                                        onClick={
                                                            handleStartMeeting
                                                        }
                                                    >
                                                        {intl.formatMessage({
                                                            id:
                                                                "EVENT.ACTION.START_MEETING"
                                                        })}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button
                                                        className={
                                                            classes.paper
                                                        }
                                                        disabled={
                                                            isStartMeeting
                                                        }
                                                        onClick={handleReset}
                                                    >
                                                        {intl.formatMessage({
                                                            id:
                                                                "EVENT.ACTION.RESET"
                                                        })}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </PortletBody>
                            </Portlet>
                        </div>
                    </div>
                </div>
            </div>
        </EventLayout>
    );
}

const mapStateToProps = state => {
    return {
        event: state.customerReducer.event,
        isEvent: state.customerReducer.isEvent,
        isStartMeeting: state.event.isStartMeeting,
        screenMode: state.event.screenMode
    };
};

const mapDispatchToProps = dispatch => ({
    startMeeting: () => dispatch(eventStore.actions.startMeeting()),
    endMeeting: () => dispatch(eventStore.actions.endMeeting())
});

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(Meeting)
);
