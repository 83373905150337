import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import _ from "lodash";
import { Autocomplete } from "@material-ui/lab";
import {
    makeStyles,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    IconButton,
    TablePagination,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    TextField,
    FormControl,
    Chip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
    EnhancedTableToolbar,
    EnhancedTableHead,
    stableSort,
    getSorting
} from "../../../../components/EnhancedTable";

import * as eventsStore from "../../../../store/ducks/events.duck";
import * as customersStore from "../../../../store/ducks/customers.duck";

const headRows = [
    { id: "name", numeric: false, disablePadding: false, label: "Name" },
    { id: "email", numeric: false, disablePadding: false, label: "Email" },
    { id: "status", numeric: false, disablePadding: false, label: "Status" }
];

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    containerWrapper: {
        marginBottom: theme.spacing(2),
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        [theme.breakpoints.up("md")]: {
            // eslint-disable-line no-useless-computed-key
            width: "70%"
        }
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        // minWidth: 750
    },
    tableWrapper: {
        overflowX: "auto",
        marginTop: "1rem",
        width: "100%"
    },
    button: {
        margin: theme.spacing(1)
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        maxWidth: "100%"
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center"
    }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function EventParticipantsDlg(props) {
    const {
        eventId,
        participantsList,
        getParticipantsList,
        customerList,
        getCustomers,
        open,
        onClose,
        addParticipants,
        removeParticipant,
        intl
    } = props;

    const [selectedUser, setSelectedUser] = React.useState([]);
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [openDelDlg, setOpenDelDlg] = React.useState(false);
    const [clickedValue, setClickedValue] = React.useState(-1);

    useEffect(() => {
        if (!_.isNil(eventId) && eventId !== -1) {
            getParticipantsList(eventId);
            getCustomers();
        }
    }, [eventId, getParticipantsList, getCustomers]);

    const emptyRows =
        rowsPerPage -
        Math.min(
            rowsPerPage,
            participantsList ? participantsList.length : 0 - page * rowsPerPage
        );

    const isSelected = id => selected.indexOf(id) !== -1;

    function handleCheckClick(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = participantsList.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }

    const handleClose = () => {
        setSelectedUser([]);
        onClose();
    };

    function handleSelectChange(event, newValue) {
        setSelectedUser(newValue);
    }
    function handleAddParticipants(event) {
        addParticipants(
            eventId,
            selectedUser.map(element => element.id)
        );
        setSelectedUser([]);
    }

    function handleCloseDel(value) {
        setOpenDelDlg(false);
    }

    function handleDelete(event) {
        if (clickedValue !== -1) {
            removeParticipant(clickedValue);
            setOpenDelDlg(false);
        }
        setClickedValue(-1);
    }

    function handleDeleteClick(event, id) {
        setClickedValue(id);
        setOpenDelDlg(true);
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-event-dialog-title">
                    {intl.formatMessage({ id: "EVENT.PARTICIPANTDLG.TITLE" })}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {intl.formatMessage({
                            id: "EVENT.PARTICIPANTDLG.DESC"
                        })}
                    </DialogContentText>
                    <div className="row">
                        <div className="col-md-10">
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    id="customer-selector"
                                    multiple
                                    value={selectedUser}
                                    onChange={handleSelectChange}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={intl.formatMessage({
                                                id: "EVENT.PARTICIPANTDLG.TITLE"
                                            })}
                                            placeholder=""
                                        />
                                    )}
                                    disableCloseOnSelect
                                    options={customerList}
                                    getOptionLabel={option => option.name}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.name}
                                        </React.Fragment>
                                    )}
                                />
                            </FormControl>
                        </div>
                        <div className={`col-md-2 ${classes.buttonContainer}`}>
                            <Button
                                variant="contained"
                                disabled={selectedUser.length === 0}
                                className={classes.button}
                                onClick={handleAddParticipants}
                            >
                                {intl.formatMessage({
                                    id: "EVENT.PARTICIPANTDLG.ADD"
                                })}
                            </Button>
                        </div>

                        <EnhancedTableToolbar numSelected={selected.length} />
                        <div className={classes.tableWrapper}>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size="medium"
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={
                                        participantsList
                                            ? participantsList.length
                                            : 0
                                    }
                                    headRows={headRows}
                                />
                                <TableBody>
                                    {stableSort(
                                        participantsList,
                                        getSorting(order, orderBy)
                                    )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(
                                                row.id
                                            );
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={
                                                        isItemSelected
                                                    }
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={
                                                                isItemSelected
                                                            }
                                                            onClick={event =>
                                                                handleCheckClick(
                                                                    event,
                                                                    row.id
                                                                )
                                                            }
                                                            inputProps={{
                                                                "aria-labelledby": labelId
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.email}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.status === 0 ? (
                                                            <Chip
                                                                size="small"
                                                                label="Invited"
                                                                // onDelete={handleDelete}
                                                                className={
                                                                    classes.chip
                                                                }
                                                                color="secondary"
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            aria-label="Delete"
                                                            onClick={event =>
                                                                handleDeleteClick(
                                                                    event,
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 49 * emptyRows }}
                                        >
                                            <TableCell colSpan={8} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={participantsList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                "aria-label": "Previous Page"
                            }}
                            nextIconButtonProps={{
                                "aria-label": "Next Page"
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                        {intl.formatMessage({
                            id: "EVENT.PARTICIPANTDLG.CLOSE"
                        })}
                    </Button>
                </DialogActions>
            </Dialog>

            <div>
                <Dialog
                    open={openDelDlg}
                    onClose={handleCloseDel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Will you surely remove this participant?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDel} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleDelete}
                            color="primary"
                            autoFocus
                        >
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}

EventParticipantsDlg.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    eventId: PropTypes.any
};

const mapStateToProps = state => {
    return {
        participantsList: state.events.participantsList,
        customerList: state.customers.customerList
    };
};

const mapDispatchToProps = dispatch => ({
    getParticipantsList: eventId =>
        dispatch(eventsStore.actions.getParticipantsList(eventId)),
    removeParticipant: participantId =>
        dispatch(eventsStore.actions.removeParticipant(participantId)),
    addParticipants: (eventId, userIds) =>
        dispatch(eventsStore.actions.addParticipants(eventId, userIds)),
    getCustomers: () => dispatch(customersStore.actions.getCustomers())
});

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(EventParticipantsDlg)
);
