import React from 'react'
import { connect } from "react-redux"
import { makeStyles, withStyles } from "@material-ui/core";
import { LinearProgress } from '@material-ui/core';
import { Paper } from '@material-ui/core'
import { UserRole } from './utils/UserRole';

const useStyles = makeStyles((theme) => ({
    oscilloMeterContainer: {
        position: 'absolute',
        width: '100%',
        minWidth: '13rem',
        // backgroundColor: theme.palette.common.white,
        // borderRadius: 4,
        // transform: props => { return `translate3d(0px, ${_getInterpreters(props.participants).length * -40}px, 0px)` },
        // boxShadow: '0px 0px 50px 0px rgba(82, 63, 105, 0.15)',
        padding: '1rem',
        marginTop: '-100%',
        bottom: 33,
        maxHeight: 400,
        overflowY: 'auto'
    }
}))

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        // height: 10,
        // borderRadius: 5,
        margin: 5
    },
    // colorPrimary: {
    //     backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    // },
    // bar: {
    //     borderRadius: 5,
    //     backgroundColor: '#1a90ff',
    // },
}))(LinearProgress);

function TranslatorBar(props) {
    const { participant, value } = props

    return (
        <div className="row">
            <div className="col-12">
                {participant.getDisplayName()}
            </div>
            <div className="col-12">
                <BorderLinearProgress variant="determinate" value={value} />
            </div>
        </div>
    )
}

function OscilloMeter(props) {
    const { event, isOscilloMeter, participants, audioLevels } = props
    const classes = useStyles(props)

    const [otherInterRooms, setOtherInterRooms] = React.useState([])

    React.useEffect(() => {
        if (!event) return
        const event_rooms = event.event_rooms
        const emitterRoom = event.event_rooms.filter(event_room => event_room.interpreter_id === 0);

        var resArr = [];
        event_rooms.filter(function (item) {
            var i = resArr.findIndex(x => (x.room ? x.room.room_name === item.room.room_name : x.room_name === item.room_name));
            if (i <= -1) {
                resArr.push(item);
            }
            return null;
        });
        if (resArr) {
            const otherInterRooms = []
            otherInterRooms.push(emitterRoom[0])
            for (var i = 0; i < resArr.length; i++) {
                const event_room = resArr[i];
                if (event_room.interpreter_id !== 0) {
                    otherInterRooms.push(event_room)
                }
            }
            setOtherInterRooms(otherInterRooms)
        }
    }, [event])

    return (
        isOscilloMeter &&
        <Paper className={classes.oscilloMeterContainer}>
            {/* {_getInterpreters(participants).map((p, i) => {
                const pId = p.getId()
                const audioLevel = audioLevels.find(a => a.userId === pId)
                return <TranslatorBar key={i} value={audioLevel ? audioLevel.audioLevel * 100 : 0} participant={p} />
            })} */}
            {
                otherInterRooms.length > 0 ? otherInterRooms.map((interRoom, index) => {
                    const room_output_lang = interRoom.room ? interRoom.room.output_lang.lang_label : interRoom.output_lang_name
                    const room_name = interRoom.room ? interRoom.room.room_name : interRoom.room_name
                    const interpreters = participants.filter(p => parseInt(p.getProperty('role')) === UserRole.INTERPRETER && p.getProperty('output') === room_name)

                    return (
                        <div key={index}>
                            <div>{room_output_lang}</div>
                            {interpreters.map((p, i) => {
                                const pId = p.getId()
                                const audioLevel = audioLevels.find(a => a.userId === pId)
                                return <TranslatorBar key={i} value={audioLevel ? audioLevel.audioLevel * 100 : 0} participant={p} />
                            })}
                        </div>)
                }) : ''
            }
        </Paper>
    )
}

const mapStateToProps = state => {
    return {
        isOscilloMeter: state.event.isOscilloMeter,
        participants: state.event.participants,
        audioLevels: state.event.audioLevels,
    };
};

const mapDispatchToProps = dispatch => ({
    // hideNotification: () => dispatch(eventStore.actions.hideNotification())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OscilloMeter)