import { apiGetRequest, apiPostRequest } from "./helper"
const BASE_URL = process.env.REACT_APP_BASE_URL
export const MANAGER_URL = BASE_URL + "manager_list";
export const MANAGER_EDIT_URL = BASE_URL + "managers";

export function get_managers() {
  return apiGetRequest(MANAGER_URL);
}

export function get_manager(id) {
  return apiGetRequest(MANAGER_EDIT_URL + `/${id}`);
}

export function update_manager(id, newManager) {
  return apiPostRequest(MANAGER_EDIT_URL + `/${id}`, newManager);
}

export function add_manager(manager) {
  return apiPostRequest(BASE_URL + `create_manager`, manager);
}

export function remove_manager(id) {
  return apiPostRequest(BASE_URL + `delete_manager/${id}`);
}
