import React, { useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import ToolBar from "../../../../components/Toolbar";
import SelectDeviceDialog from "../../../../components/SelectDeviceDialog";
import { toAbsoluteUrl } from "../../../../../_metronic";
import { mutePresenterVideo } from "../../../../components/utils/LocalVideoTrackUtils";
import {
    options,
    initOptions,
    confOptions
} from "../../../../components/utils/RoomUtils";
import { meetingVideo as useStyles } from "../../../../components/CommonStyles";
import $ from "jquery";

import * as eventStore from "../../../../store/ducks/event.duck";
import { UserRole } from "../../../../components/utils/UserRole";
import MeetContainer from "../../../../components/MeetContainer";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

const JitsiMeetJS = window.JitsiMeetJS;

function Video(props) {
    const {
        event,
        isEndMeeting,
        endMeetingSuccess,
        volume,
        isMuted,
        changeMute,
        isCameraOn,
        cameraDevices,
        setCameraDevices,
        openAudioOutputSettingDlg,
        setOpenAudioOutputSettingDlg,
        openVideoSettingDlg,
        setOpenVideoSettingDlg,
        openAudioInputSettingDlg,
        setOpenAudioInputSettingDlg,
        isVideo,
        setIsVideo,
        isShareOtherCamera,
        user,
        authToken,
        startStatId,
        showNotification,
        intl
    } = props;

    const [cameraSetting, setCameraSetting] = React.useState("");
    const [audioOutputSetting, setAudioOutputSetting] = React.useState("");
    const [audioInputSetting, setAudioInputSetting] = React.useState("");
    const [audioOutputDevices, setAudioOutputDevices] = React.useState([]);
    const [audioInputDevices, setAudioInputDevices] = React.useState([]);

    const classes = useStyles();

    const connection = React.useRef(null);
    const isJoined = React.useRef(false);
    const room = React.useRef(null);
    const bigVideo = React.useRef(null);
    const bigVideoTrack = React.useRef(null);
    const localTracks = React.useRef([]);

    useEffect(() => {
        if (isEndMeeting) {
            unload();
        } else {
            startJitSiMeeting();
        }
    }, [isEndMeeting]);

    useEffect(() => {
        return () => {
            if (connection) {
                unload();
            }
        };
    }, []);

    useEffect(() => {
        handleMute();
    }, [isMuted]);

    useEffect(() => {
        if (room.current) {
            $("audio").prop("volume", volume);
        }
    }, [volume]);

    useEffect(() => {}, [startStatId]);

    useEffect(() => {
        handleCameraAndShare();
    }, [isShareOtherCamera, isCameraOn, isVideo, cameraSetting]);

    function startJitSiMeeting() {
        if (!event) {
            return;
        }

        JitsiMeetJS.init(initOptions);
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
        const appId = process.env.REACT_APP_APP_ID;

        connection.current = new JitsiMeetJS.JitsiConnection(
            appId,
            authToken,
            options
        );

        connection.current.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            onConnectionSuccess
        );
        connection.current.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            onConnectionFailed
        );
        connection.current.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            disconnect
        );

        JitsiMeetJS.mediaDevices.addEventListener(
            JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
            onDeviceListChanged
        );

        connection.current.connect();

        let _audioOutputDevices = [],
            _audioInputDevices = [],
            _cameraDevices = [];
        let mediaOptions = []; // Initial mediaOptions
        let restOptions = {};
        if (
            JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("output") ||
            JitsiMeetJS.mediaDevices.isDeviceListAvailable()
        ) {
            JitsiMeetJS.mediaDevices.enumerateDevices(devices => {
                devices.forEach((device, i) => {
                    switch (device.kind) {
                        case "audiooutput":
                            _audioOutputDevices.push(device);
                            break;
                        case "audioinput":
                            _audioInputDevices.push(device);
                            break;
                        case "videoinput":
                            _cameraDevices.push(device);
                            break;
                        default:
                            break;
                    }
                });

                // TODO: Check if states are updated above
                if (_audioOutputDevices.length > 0) {
                    setAudioOutputDevices(_audioOutputDevices);
                    setAudioOutputSetting(_audioOutputDevices[0].deviceId);
                }
                if (_audioInputDevices.length > 0) {
                    restOptions["micDeviceId"] = _audioInputDevices[0].deviceId;
                    mediaOptions.push("audio");
                    setAudioInputDevices(_audioInputDevices);
                    setAudioInputSetting(_audioInputDevices[0].deviceId);
                }
                if (_cameraDevices.length > 0) {
                    if (isCameraOn) {
                        restOptions["cameraDeviceId"] =
                            _cameraDevices[0].deviceId;
                        restOptions["maxFpx"] = 60;
                        restOptions["minFpx"] = 30;
                        mediaOptions.push("video");
                    }
                    setCameraDevices(_cameraDevices);
                    setCameraSetting(_cameraDevices[0].deviceId);
                }

                JitsiMeetJS.createLocalTracks({
                    devices: mediaOptions,
                    ...restOptions
                })
                    .then(onLocalTracks)
                    .catch(error => {
                        console.log(error);
                    });
            });
        } else {
            console.log("Please select");
        }
    }

    /**
     * Handle local tracks.
     * @param tracks Array with JitsiTrack objects
     */
    async function onLocalTracks(tracks) {
        localTracks.current = tracks;
        for (let i = 0; i < localTracks.current.length; i++) {
            if (localTracks.current[i].getType() === "video") {
                localTracks.current[i].attach($(`#selfVideo`)[0]);
                localTracks.current[i].attach(bigVideo.current);
                bigVideoTrack.current = localTracks.current[i];
            } else {
                if (isMuted) {
                    localTracks.current[i].mute();
                } else {
                    localTracks.current[i].unmute();
                }
            }
            if (isJoined.current) {
                await room.current.addTrack(localTracks.current[i]);
            }
        }
    }

    /**
     * That function is executed when the conference is joined
     */
    async function onConferenceJoined() {
        isJoined.current = true;
        for (let i = 0; i < localTracks.current.length; i++) {
            await room.current.addTrack(localTracks.current[i]);
        }
    }

    async function onConferenceFailed(error) {
        console.info(error);

        // TODO: This conference failure does not detect isMembersOnly function
        // if (room.current.isMembersOnly()) {
        // }
        try {
            await room.current.joinLobby(user.name, user.email);
        } catch (error) {
            console.info(error);
        }
    }

    /**
     * This function is called when a new user joins.
     * @param id
     */
    function onUserJoined(id, participant) {}

    /**
     * This function is called when a user leaves the room.
     * @param id
     */
    function onUserLeft(id) {}

    /**
     * Handles remote tracks
     * @param track JitsiTrack object
     */
    function onRemoteTrack(track) {}

    function onTrackRemoved(track) {
        const containers = track.containers;
        if (containers.length > 0) {
            if (track.getType() === "video") {
                const container_id = containers[0].id;
                if (container_id) {
                    if (track.disposed) {
                        track.detach($(`#${container_id}`)[0]);
                    }
                }
            }
        }
    }

    /**
     * That function is called when connection is established successfully
     */
    function onConnectionSuccess() {
        room.current = connection.current.initJitsiConference(
            event ? event.event_name : "conference",
            { ...confOptions, statisticsId: user.email }
        );
        room.current.setSenderVideoConstraint(720);
        room.current.setDisplayName(user.name);
        room.current.on(
            JitsiMeetJS.events.conference.CONFERENCE_JOINED,
            onConferenceJoined
        );
        room.current.on(
            JitsiMeetJS.events.conference.CONFERENCE_FAILED,
            onConferenceFailed
        );
        room.current.on(
            JitsiMeetJS.events.conference.USER_JOINED,
            onUserJoined
        );
        room.current.on(
            JitsiMeetJS.events.conference.TRACK_ADDED,
            onRemoteTrack
        );
        room.current.on(
            JitsiMeetJS.events.conference.TRACK_REMOVED,
            onTrackRemoved
        );
        room.current.on(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
        room.current.on(
            JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
            (track, participantThatMutedUs) => {
                console.log(participantThatMutedUs);
                if (participantThatMutedUs) {
                    changeMute(true);
                }
            }
        );
        room.current.on(JitsiMeetJS.events.conference.KICKED, () => {
            console.log("kicked");
            showNotification("error", "You are kicked by admin");
        });

        room.current.on(
            JitsiMeetJS.events.conference.START_MUTED_POLICY_CHANGED,
            policy => {
                // if (policy.audio) {
                //     changeMicOn(false);
                // }
                if (policy.video) {
                }
            }
        );
        room.current.addCommandListener("moderator", handleModeratorEvent);
        room.current.on(
            JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
            handleFeatureChange
        );

        room.current.on(
            JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
            (userID, displayName) => {}
        );
        room.current.on(
            JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
            (userID, audioLevel) => {}
        );
        room.current.on(
            JitsiMeetJS.events.conference.PHONE_NUMBER_CHANGED,
            () => {}
        );

        room.current.setLocalParticipantProperty("role", UserRole.EMITTER);

        room.current.join();
    }

    /**
     * This function is called when feature is changed.
     * @param {*} participant
     */
    function handleFeatureChange(participant) {}

    /**
     * This function is called when the connection fail.
     */
    function onConnectionFailed(
        errType,
        errReason,
        credentials,
        errReasonDetails
    ) {}

    /**
     * This function is called when the connection fail.
     */
    function onDeviceListChanged(devices) {}

    function handleModeratorEvent(results) {
        const value = results.value;
        if (value === room.current.myUserId()) {
            if (results.attributes.actionType === "notify") {
                if (results.attributes.content === "msg") {
                    showNotification("info", results.attributes.msg_en);
                }
            }
        }
    }

    /**
     * This function is called when we disconnect.
     */
    function disconnect(msg) {
        connection.current.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            onConnectionSuccess
        );
        connection.current.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            onConnectionFailed
        );
        connection.current.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            disconnect
        );
    }

    async function unload() {
        if (room.current && room.current.room) {
            const currentTracks = room.current.getLocalTracks();
            for (let i = 0; i < currentTracks.length; i++) {
                if (!currentTracks[i].disposed) {
                    try {
                        await currentTracks[i].dispose();
                    } catch (error) {
                        console.info(error);
                    }
                }
            }
            try {
                await room.current.leave();
            } catch (error) {
                console.info(error);
            }
            await connection.current.disconnect();
            endMeetingSuccess();
        } else {
            endMeetingSuccess();
        }
    }

    // TODO:
    function handleHoverEvent(event) {}

    // TODO:
    function handleLeaveEvent(event) {}

    async function handleCameraAndShare() {
        if (!room.current) {
            return;
        }
        const currentLocalTrack = room.current.getLocalVideoTrack();
        let newVideoTrack;
        if (isVideo) {
            if (isCameraOn) {
                if (currentLocalTrack) {
                    try {
                        await currentLocalTrack.setEffect(undefined);
                    } catch (error) {
                        console.log(error);
                    }
                    await currentLocalTrack.dispose();
                }
                if (isShareOtherCamera) {
                    const otherCameraDevice = _getOtherCamera(
                        cameraDevices,
                        cameraSetting
                    );
                    newVideoTrack = await _handleLocalDeviceTrack(
                        otherCameraDevice.deviceId,
                        cameraSetting
                    );
                } else {
                    newVideoTrack = await _handleLocalDeviceTrack(
                        cameraSetting
                    );
                }
            } else {
                if (currentLocalTrack) {
                    await currentLocalTrack.setEffect(undefined);
                    if (!isShareOtherCamera) {
                        await currentLocalTrack.dispose();
                    } else if (currentLocalTrack.videoType === "desktop") {
                        await currentLocalTrack.dispose();
                        const otherCameraDevice = _getOtherCamera(
                            cameraDevices,
                            cameraSetting
                        );
                        newVideoTrack = await _handleLocalDeviceTrack(
                            otherCameraDevice.deviceId
                        );
                    }
                } else {
                    const otherCameraDevice = _getOtherCamera(
                        cameraDevices,
                        cameraSetting
                    );
                    newVideoTrack = await _handleLocalDeviceTrack(
                        otherCameraDevice.deviceId
                    );
                }
            }
        } else {
            if (isCameraOn) {
                if (
                    currentLocalTrack &&
                    currentLocalTrack.videoType === "desktop"
                ) {
                    const effect = await mutePresenterVideo(
                        currentLocalTrack,
                        cameraSetting
                    );
                    await currentLocalTrack.setEffect(effect);
                    return;
                }
                newVideoTrack = await _handleShareDesktop(
                    currentLocalTrack,
                    cameraSetting
                );
            } else {
                if (currentLocalTrack) {
                    await currentLocalTrack.setEffect(undefined);
                    if (currentLocalTrack.videoType !== "desktop") {
                        newVideoTrack = await _handleShareDesktop(
                            currentLocalTrack
                        );
                    }
                } else {
                    newVideoTrack = await _handleShareDesktop();
                }
            }
        }

        if (newVideoTrack) {
            const videoIndex = localTracks.current.findIndex(
                t => t.getType() === "video"
            );
            if (videoIndex === -1) {
                localTracks.current.push(newVideoTrack);
            } else {
                localTracks.current[videoIndex] = newVideoTrack;
            }
        }
    }

    /**
     * Add desktop sharing track
     * @param {*} currentLocalTrack
     * @param {*} _cameraSetting if not null, desktop track is set effect
     */
    function _handleShareDesktop(
        currentLocalTrack = null,
        _cameraSetting = null
    ) {
        return JitsiMeetJS.createLocalTracks({
            devices: ["desktop"],
            maxFps: 60,
            minFps: 30
        })
            .then(async ([newVideoTrack]) => {
                if (currentLocalTrack) {
                    if (_cameraSetting) {
                        await currentLocalTrack.setEffect(undefined);
                    }
                    await currentLocalTrack.dispose();
                }
                if (_cameraSetting) {
                    const effect = await mutePresenterVideo(
                        newVideoTrack,
                        _cameraSetting
                    );
                    await newVideoTrack.setEffect(effect);
                }
                newVideoTrack.addEventListener(
                    JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
                    () => {
                        console.log(isVideo);
                        setIsVideo(true);
                    }
                );
                newVideoTrack.attach(bigVideo.current);
                newVideoTrack.attach($("#selfVideo")[0]);
                room.current.addTrack(newVideoTrack);
                return Promise.resolve(newVideoTrack);
            })
            .catch(err => {
                setIsVideo(true);
                return Promise.reject();
            });
    }

    /**
     * Add local video track with deviceId
     * @param {*} deviceId
     * @param {*} _cameraSetting if not null, local video track is set effect.
     */
    async function _handleLocalDeviceTrack(deviceId, _cameraSetting = null) {
        const [newVideoTrack] = await JitsiMeetJS.createLocalTracks({
            devices: ["video"],
            maxFps: 60,
            minFps: 30,
            cameraDeviceId: deviceId
        });
        if (_cameraSetting) {
            const effect = await mutePresenterVideo(
                newVideoTrack,
                _cameraSetting
            );
            await newVideoTrack.setEffect(effect);
        }
        newVideoTrack.attach(bigVideo.current);
        newVideoTrack.attach($("#selfVideo")[0]);
        await room.current.addTrack(newVideoTrack);

        return newVideoTrack;
    }

    function _getOtherCamera(_cameraDevices, _cameraSetting) {
        const otherCameras = _cameraDevices.filter(
            device => device.deviceId !== _cameraSetting
        );
        return otherCameras[0];
    }

    function handleMute() {
        if (!room.current || !room.current.room) {
            return;
        }
        const localAudio = room.current.getLocalAudioTrack();
        if (!localAudio) {
            return;
        }
        if (localAudio) {
            if (isMuted) {
                localAudio.mute();
            } else {
                localAudio.unmute();
            }
        }
    }

    /**
     * Handle CameraSetting Change
     * @param {String} value
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleVideoSettingChange(value, isOpen = true) {
        const selectCameraInput = value;
        if (isOpen) {
            setCameraSetting(selectCameraInput);
        }
        setOpenVideoSettingDlg(isOpen);
    }

    /**
     * Handle AudioOutputSetting Change
     * @param {String} value
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleAudioOutputSettingChange(value, isOpen = true) {
        const selectAudioOutput = value;
        if (isOpen) {
            setAudioOutputSetting(selectAudioOutput);
            JitsiMeetJS.mediaDevices.setAudioOutputDevice(selectAudioOutput);
        }
        setOpenAudioOutputSettingDlg(isOpen);
    }

    /**
     * Handle AudioInputSetting Change
     * @param {String} value
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    async function handleAudioInputSettingChange(value, isOpen = true) {
        const selectAudioInput = value;
        if (isOpen) {
            setAudioInputSetting(selectAudioInput);

            if (localTracks.current[0]) {
                localTracks.current[0].dispose();
            }

            const [audioTrack] = await JitsiMeetJS.createLocalTracks({
                devices: ["audio"],
                micDeviceId: selectAudioInput
            });
            localTracks.current[0] = audioTrack;

            if (isMuted) {
                localTracks.current[0].mute();
            } else {
                localTracks.current[0].unmute();
            }
            room.current.addTrack(localTracks.current[0]);
        }
        setOpenAudioInputSettingDlg(isOpen);
    }

    return (
        <>
            <MeetContainer className="row">
                <div className="col-md-12">
                    <div
                        id="localPlace"
                        className={classes.localPlace}
                        onMouseEnter={handleHoverEvent}
                        onMouseLeave={handleLeaveEvent}
                    >
                        <div className={classes.avatar}>
                            <img
                                src={
                                    event.pic
                                        ? process.env.REACT_APP_FILE_URL +
                                          event.pic
                                        : toAbsoluteUrl(
                                              "/media/logos/logo-trans.png"
                                          )
                                }
                                alt="avatar"
                            />
                        </div>

                        <ToolBar event={event} disableChat={true} />

                        <video
                            muted
                            autoPlay="1"
                            ref={bigVideo}
                            className={`${classes.bigVideo}`}
                        />

                        {/* Camera Setting Dialog */}
                        <SelectDeviceDialog
                            id="cameraSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.CAMERA.TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.CAMERA"
                            })}
                            isOpen={openVideoSettingDlg}
                            devices={cameraDevices}
                            currentValue={cameraSetting}
                            onChange={handleVideoSettingChange}
                        />

                        {/* Audio Output Setting Dialog */}
                        <SelectDeviceDialog
                            id="audioOutputSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_OUPUTS.TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_OUPUTS"
                            })}
                            isOpen={openAudioOutputSettingDlg}
                            devices={audioOutputDevices}
                            currentValue={audioOutputSetting}
                            onChange={handleAudioOutputSettingChange}
                        />

                        {/* Audio Input Setting Dialog */}
                        <SelectDeviceDialog
                            id="audioInputSetting"
                            title={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_INPUTS_TITLE"
                            })}
                            label={intl.formatMessage({
                                id: "VIDEO.SETTING.AUDIO_INPUTS"
                            })}
                            isOpen={openAudioInputSettingDlg}
                            devices={audioInputDevices}
                            currentValue={audioInputSetting}
                            onChange={handleAudioInputSettingChange}
                        />
                    </div>
                </div>
            </MeetContainer>
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        authToken: state.auth.authToken,
        isEndMeeting: state.event.isEndMeeting,
        startStatId: state.event.startStatId,
        isMuted: state.event.isMuted,
        isCameraOn: state.event.isCameraOn,
        cameraDevices: state.event.cameraDevices,
        openVideoSettingDlg: state.event.openVideoSettingDlg,
        openAudioOutputSettingDlg: state.event.openAudioOutputSettingDlg,
        openAudioInputSettingDlg: state.event.openAudioInputSettingDlg,
        isVideo: state.event.isVideo,
        isShareOtherCamera: state.event.isShareOtherCamera
    };
};

const mapDispatchToProps = dispatch => ({
    endMeetingSuccess: () => dispatch(eventStore.actions.endMeetingSuccess()),
    changeMute: isMuted => dispatch(eventStore.actions.changeMute(isMuted)),
    addStat: data => dispatch(eventStore.actions.addStat(data)),
    showNotification: (type, content) =>
        dispatch(eventStore.actions.showNotification(type, content)),
    setCameraDevices: cameraDevices =>
        dispatch(eventStore.actions.setCameraDevices(cameraDevices)),
    setOpenAudioInputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioInputSettingDlg(isOpen)),
    setOpenAudioOutputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen)),
    setOpenVideoSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenVideoSettingDlg(isOpen)),
    setIsVideo: isVideo => dispatch(eventStore.actions.setIsVideo(isVideo))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Video));
