import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { colors } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import GroupTranslatorRemoteVideo from "./GroupTranslatorRemoteVideo";
import * as eventStore from "../store/ducks/event.duck";

import $ from "jquery";

const useStyles = makeStyles(theme => ({
    groupTranslatorContainer: {
        position: "absolute",
        width: "100%",
        minWidth: "16rem",
        // backgroundColor: theme.palette.common.white,
        // borderRadius: 4,
        // transform: props => { return `translate3d(0px, ${_getInterpreters(props.participants).length * -40}px, 0px)` },
        // boxShadow: '0px 0px 50px 0px rgba(82, 63, 105, 0.15)',
        padding: "1rem",
        marginTop: "-100%",
        bottom: 33,
        display: props => (props.isGroupTranslator ? "block" : "none")
    },
    videoContainer: {
        display: "flex",
        paddingTop: "75%"
    },
    videoElement: {
        width: "100%",
        marginTop: "-75%"
    },
    participantContainer: {
        display: "block",
        position: "relative",
        borderRadius: 4,
        cursor: "pointer",
        background: colors.common.black,
        margin: 2,
        marginTop: 10
    }
}));

function GroupTranslator(props) {
    const {
        isGroupTranslator,
        openChatBox,
        groupTranslator,
        setToParticipant,
        sendPreMsg
    } = props;
    const classes = useStyles(props);

    React.useEffect(() => {
        if (groupTranslator.length > 0) {
            const participant = groupTranslator[0];
            console.log(participant.getTracks());
            const audioTrack = participant.getTracksByMediaType("audio");
            const videoTrack = participant.getTracksByMediaType("video");
            const id = participant.getId();
            $("#translatorVideoContainer").html(
                `<div id="${id}CabinPlace" class="${classes.participantContainer}"></div>`
            );

            console.log(audioTrack);
            ReactDOM.render(
                <GroupTranslatorRemoteVideo
                    id={id}
                    displayName={participant.getDisplayName()}
                    baseClasses={classes}
                    openChatBox={openChatBox}
                    setToParticipant={setToParticipant}
                    // messageList={messageList}
                    // lang={intl.locale}
                    sendPreMsg={sendPreMsg}
                    isMuted={audioTrack.length > 0 && audioTrack[0].isMuted()}
                    isMicOn={!participant.getProperty("output").includes("non")}
                    // isNotify={true}
                />,
                document.getElementById(`${id}CabinPlace`),
                function() {
                    if (videoTrack.length > 0) {
                        $(`#${id}CabinPlace .videoContainer`).append(
                            `<video muted autoPlay="1" id="${id}GroupTranslatorVideo" class="${classes.videoElement}" />`
                        );
                        const videoElem = document.getElementById(
                            `${id}GroupTranslatorVideo`
                        );
                        if (videoElem) {
                            videoTrack[0].attach(videoElem);
                        }
                    } else {
                        $(`#${id}CabinPlace .videoContainer`).empty();
                    }
                }
            );
        } else {
            $(document.getElementById("translatorVideoContainer")).empty();
        }
    }, [groupTranslator]);

    return (
        <Paper className={classes.groupTranslatorContainer}>
            {groupTranslator.length === 0
                ? "There are no other active interpreters on this output channel"
                : ""}
            <div id="translatorVideoContainer"></div>
        </Paper>
    );
}

const mapStateToProps = state => {
    return {
        isGroupTranslator: state.event.isGroupTranslator,
        participants: state.event.participants,
        groupTranslator: state.event.groupTranslator
    };
};

const mapDispatchToProps = dispatch => ({
    openChatBox: isChatBoxOpen =>
        dispatch(eventStore.actions.openChatBox(isChatBoxOpen)),
    setToParticipant: toParticipant =>
        dispatch(eventStore.actions.setToParticipant(toParticipant))
    // hideNotification: () => dispatch(eventStore.actions.hideNotification())
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupTranslator);
