import React from "react";
import { Route, Switch } from "react-router-dom";
import Channels from "./Channels";
import Languages from "./Languages";
import Messages from "./Messages";


export default function Settings() {
  return (
    <Switch>

      <Route
        exact={true}
        path="/settings/channels"
        component={Channels}
      />

      <Route
        exact={true}
        path="/settings/languages"
        component={Languages}
      />

      <Route
        exact={true}
        path="/settings/messages"
        component={Messages}
      />

    </Switch>
  );
}
