import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
    getUserByToken,
    resetPassword,
    resetPersonalPassword
} from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
    Login: "[Login] Action",
    Logout: "[Logout] Action",
    Register: "[Register] Action",
    UserRequested: "[Request User] Action",
    UserLoaded: "[Load User] Auth API",
    UPDATE_PROFILE: "UPDATE_PROFILE",
    RESET_PASSWORD: "RESET_PASSWORD",
    RESET_PERSONAL_PASSWORD: "RESET_PERSONAL_PASSWORD",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",
    UPDATE_FIRST_LOGIN: "UPDATE_FIRST_LOGIN",
    CANCEL_RESET_PASSWORD: "CANCEL_RESET_PASSWORD",
    FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS"
};

const initialAuthState = {
    user: undefined,
    authToken: undefined
    // firstLogin: false
};

export const actions = {
    login: (authToken, firstLogin) => ({
        type: actionTypes.Login,
        payload: { authToken, firstLogin }
    }),
    register: authToken => ({
        type: actionTypes.Register,
        payload: { authToken }
    }),
    logout: () => ({ type: actionTypes.Logout }),
    requestUser: user => ({
        type: actionTypes.UserRequested,
        payload: { user }
    }),
    fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
    updateProfile: user => ({
        type: actionTypes.UPDATE_PROFILE,
        payload: { user }
    }),
    resetPassword: () => ({ type: actionTypes.RESET_PASSWORD }),
    resetPersionalPassword: password => ({
        type: actionTypes.RESET_PERSONAL_PASSWORD,
        password: password
    }),
    resetPasswordSuccess: authToken => ({
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        payload: { authToken }
    }),
    resetPasswordFail: () => ({ type: actionTypes.RESET_PASSWORD_FAIL }),
    cancelResetPassword: () => ({ type: actionTypes.CANCEL_RESET_PASSWORD }),
    forgotPasswordSuccess: message => ({
        type: actionTypes.FORGOT_PASSWORD_SUCCESS,
        message: message
    })
};

export const reducer = persistReducer(
    { storage, key: "auth", whitelist: ["user", "authToken"] },
    (state = initialAuthState, action) => {
        switch (action.type) {
            case actionTypes.Login: {
                const { authToken, firstLogin } = action.payload;

                return { authToken, user: undefined, firstLogin };
            }

            case actionTypes.Register: {
                const { authToken } = action.payload;

                return { authToken, user: undefined };
            }

            case actionTypes.Logout: {
                routerHelpers.saveLastLocation("/");
                routerHelpers.forgotLastLocation();
                return initialAuthState;
            }

            case actionTypes.UserLoaded: {
                const { user } = action.payload;

                return { ...state, user };
            }
            case actionTypes.resetPersionalPassword: {
                return { ...state, firstLogin: undefined };
            }
            case actionTypes.resetPassword: {
                return { ...state, firstLogin: undefined };
            }

            case actionTypes.CANCEL_RESET_PASSWORD: {
                return { ...state, firstLogin: undefined };
            }

            case actionTypes.FORGOT_PASSWORD_SUCCESS: {
                console.log(action.message);
                return {
                    ...state,
                    notification: action.message
                };
            }
            default:
                return state;
        }
    }
);

export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.UserRequested, function* userRequested() {
        const { data: result } = yield getUserByToken();

        yield put(actions.fulfillUser(result.data));
    });

    yield takeLatest(actionTypes.UPDATE_PROFILE, function* userRequested(
        action
    ) {
        // const newUser = action.user
        const { data: result } = yield getUserByToken();

        yield put(actions.fulfillUser(result.data));
    });

    yield takeLatest(actionTypes.RESET_PASSWORD, function* resetPasswordSaga() {
        try {
            const { data: authToken } = yield resetPassword();
            yield put(actions.resetPasswordSuccess(authToken));
        } catch (error) {
            yield put(actions.resetPasswordFail());
        }
    });

    yield takeLatest(
        actionTypes.RESET_PERSONAL_PASSWORD,
        function* resetPersonalPasswordSaga(action) {
            try {
                const { data: authToken } = yield resetPersonalPassword(
                    action.password
                );
                yield put(actions.resetPasswordSuccess(authToken));
            } catch (error) {
                yield put(actions.resetPasswordFail());
            }
        }
    );
}
