import { apiGetRequest, apiPostRequest } from "./helper"
const BASE_URL = process.env.REACT_APP_BASE_URL
export const EVENT_URL = BASE_URL + "customer_list";
export const EVENT_EDIT_URL = BASE_URL + "customers";

export function get_customers() {
  return apiGetRequest(EVENT_URL);
}

export function get_customer(id) {
  return apiGetRequest(EVENT_EDIT_URL + `/${id}`);
}

export function update_customer(id, newCustomer) {
  return apiPostRequest(EVENT_EDIT_URL + `/${id}`, newCustomer);
}

export function add_customer(customer) {
  return apiPostRequest(BASE_URL + `create_customer`, customer);
}

export function remove_customer(id) {
  return apiPostRequest(BASE_URL + `delete_customer/${id}`);
}
