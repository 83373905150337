import React, { useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Formik } from "formik";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    makeStyles,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../../_metronic";

import * as languagesStore from "../../../../store/ducks/languages.duck";

const useStyles = makeStyles(theme => ({
    formControl: {
        width: "100%"
    },
    flag: {
        width: 48,
        // height: 18,
        marginRight: theme.spacing(1),
        borderRadius: 4
    }
}));
function LanguageDlg(props) {
    const { onClose, open, langId, langValue, dispatch, flags, intl } = props;

    const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
        paddingRight: "2.5rem"
    });
    // Submit Button Effects
    const enableLoading = () => {
        setLoading(true);
        setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
        setLoading(false);
        setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    return (
        <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                {intl.formatMessage({ id: "LANGUAGE.DETAIL.DLG.TITLE" })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {intl.formatMessage({ id: "EVENTDLG.DESC" })}
                </DialogContentText>
                <Formik
                    initialValues={
                        _.isEmpty(langValue)
                            ? {
                                  lang_name: "",
                                  lang_label: "",
                                  lang_label_en: "",
                                  lang_flag: ""
                              }
                            : langValue
                    }
                    enableReinitialize
                    validate={values => {
                        const errors = {};

                        if (!values.lang_label) {
                            errors.lang_label = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.lang_name) {
                            errors.lang_name = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.lang_flag) {
                            errors.lang_flag = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        enableLoading();
                        setTimeout(() => {
                            if (langId !== -1) {
                                dispatch(
                                    languagesStore.actions.updateLanguages(
                                        Object.assign(langValue, values)
                                    )
                                );
                                disableLoading();
                                handleClose();
                            } else {
                                dispatch(
                                    languagesStore.actions.addLanguage(values)
                                );
                                disableLoading();
                                handleClose();
                            }
                        }, 1000);
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form
                            noValidate={true}
                            autoComplete="off"
                            className="kt-form"
                            onSubmit={handleSubmit}
                        >
                            {status ? (
                                <div
                                    role="alert"
                                    className="alert alert-danger"
                                >
                                    <div className="alert-text">{status}</div>
                                </div>
                            ) : (
                                <></>
                            )}

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "LANGUAGE.FIELD.NAME"
                                    })}
                                    className="kt-width-full"
                                    name="lang_name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lang_name}
                                    helperText={
                                        touched.lang_name && errors.lang_name
                                    }
                                    error={Boolean(
                                        touched.lang_name && errors.lang_name
                                    )}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "LANGUAGE.FIELD.LABEL"
                                    })}
                                    className="kt-width-full"
                                    name="lang_label"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lang_label}
                                    helperText={
                                        touched.lang_label && errors.lang_label
                                    }
                                    error={Boolean(
                                        touched.lang_label && errors.lang_label
                                    )}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    type="text"
                                    margin="normal"
                                    label={intl.formatMessage({
                                        id: "LANGUAGE.FIELD.LABEL_EN"
                                    })}
                                    className="kt-width-full"
                                    name="lang_label_en"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lang_label_en}
                                    helperText={
                                        touched.lang_label_en &&
                                        errors.lang_label_en
                                    }
                                    error={Boolean(
                                        touched.lang_label_en &&
                                            errors.lang_label_en
                                    )}
                                />
                            </div>

                            <FormControl
                                variant="outlined"
                                disabled={false}
                                className={classes.formControl}
                                error={Boolean(
                                    touched.lang_flag && errors.lang_flag
                                )}
                            >
                                {/* <InputLabel htmlFor="lang_flag">Flag</InputLabel> */}
                                <Select
                                    value={values.lang_flag}
                                    onChange={handleChange}
                                    displayEmpty
                                    onBlur={handleBlur}
                                    name="lang_flag"
                                    // className={classes.selectEmpty}
                                >
                                    <MenuItem value="">
                                        <em>
                                            {intl.formatMessage({
                                                id: "EVENT.LANG.SELECT_LANG"
                                            })}
                                        </em>
                                    </MenuItem>
                                    {flags.sort().map((flag, index) => {
                                        return (
                                            <MenuItem key={index} value={flag}>
                                                <img
                                                    src={toAbsoluteUrl(
                                                        `/media/flags-svg/${flag}`
                                                    )}
                                                    alt={flag}
                                                    className={classes.flag}
                                                />
                                                {flag}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                {Boolean(
                                    touched.lang_flag && errors.lang_flag
                                ) && (
                                    <FormHelperText>
                                        {errors.lang_flag}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <div className="kt-login__actions">
                                <DialogActions>
                                    <Button
                                        color="primary"
                                        onClick={handleClose}
                                    >
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.CANCEL"
                                        })}
                                    </Button>
                                    <button
                                        id="kt_login_signin_submit"
                                        type="submit"
                                        disabled={isSubmitting}
                                        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                            {
                                                "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                            }
                                        )}`}
                                        style={loadingButtonStyle}
                                    >
                                        {intl.formatMessage({
                                            id: "EVENT.ACTION.SUBMIT"
                                        })}
                                    </button>
                                </DialogActions>
                            </div>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

LanguageDlg.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    langId: PropTypes.any
};

export default injectIntl(connect()(LanguageDlg));
