import { put, takeEvery } from 'redux-saga/effects';

import { get_channels, update_channel, add_channel, remove_channel } from "../../crud/channel.crud"

export const actionTypes = {
  GET_CHANNELS: "GET_CHANNELS",
  RENDER_CHANNEL_LIST: "RENDER_CHANNEL_LIST",
  UPDATE_CHANNELS: "UPDATE_CHANNELS",
  UPDATE_CHANNELS_SUCCESS: "UPDATE_CHANNELS_SUCCESS",
  ADD_CHANNEL: "ADD_CHANNEL",
  ADD_CHANNEL_SUCCESS: "ADD_CHANNEL_SUCCESS",
  REMOVE_CHANNEL: "REMOVE_CHANNEL",
  REMOVE_CHANNELSUCCESS: "REMOVE_CHANNEL_SUCCESS"
};


export const actions = {
  getChannels: () => ({ type: actionTypes.GET_CHANNELS }),
  renderChannelList: channelList => ({ type: actionTypes.RENDER_CHANNEL_LIST, channelList: channelList }),
  updateChannels: channel => ({ type: actionTypes.UPDATE_CHANNELS, newChannel: channel }),
  updateChannelsSuccess: () => ({ type: actionTypes.UPDATE_CHANNELS_SUCCESS }),
  addChannel: channel => ({ type: actionTypes.ADD_CHANNEL, newChannel: channel }),
  addChannelSuccess: channel => ({ type: actionTypes.ADD_CHANNEL_SUCCESS, newChannel: channel }),
  removeChannel: channelId => ({ type: actionTypes.REMOVE_CHANNEL, channelId: channelId }),
  removeChannelSuccess: channelId => ({ type: actionTypes.REMOVE_CHANNELSUCCESS, channelId: channelId }),

};

const initialChannelsState = {
  channelList: [],
};


export const reducer = (state = initialChannelsState, action) => {
  switch (action.type) {
    case actionTypes.RENDER_CHANNEL_LIST: {
      return {
        ...state,
        channelList: action.channelList
      }
    }
    case actionTypes.ADD_CHANNEL_SUCCESS: {
      let newChannelList = [
        ...state.channelList,
        {
          ...action.newChannel
        }
      ];
      return {
        ...state,
        channelList: newChannelList
      }
    }
    case actionTypes.REMOVE_CHANNELSUCCESS: {
      let oldChannelList = [...state.channelList];
      const newChannelList = oldChannelList.filter(channel => channel.id !== action.channelId);
      return {
        ...state,
        channelList: newChannelList
      }
    }
    default:
      return state;
  }
};

export function* channelsSaga() {
  yield takeEvery(actionTypes.GET_CHANNELS, function* getChannelsSaga() {
    try {
      const response = yield get_channels();
      const data = yield response.data ?? [];
      yield put(actions.renderChannelList(data.data));
    } catch {
      yield put(actions.renderChannelList([]));
    }
  });

  yield takeEvery(actionTypes.UPDATE_CHANNELS, function* updateChannelsSaga(action) {
    const newChannel = action.newChannel
    yield update_channel(newChannel.id, newChannel)
    yield put(actions.updateChannelsSuccess());
  });

  yield takeEvery(actionTypes.ADD_CHANNEL, function* addChannelSaga(action) {
    const newChannel = action.newChannel
    const response = yield add_channel(newChannel)
    yield put(actions.addChannelSuccess(response.data.data));
  });

  yield takeEvery(actionTypes.REMOVE_CHANNEL, function* removeChannelSaga(action) {
    const channelId = action.channelId
    yield remove_channel(channelId)
    yield put(actions.removeChannelSuccess(channelId));
  });

}
