import React from "react";
import { Route, Switch } from "react-router-dom";
import Messages from "./Messages";


export default function Settings() {
  return (
    <Switch>

      <Route
        exact={true}
        path="/settings/messages"
        component={Messages}
      />

    </Switch>
  );
}
