import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import { Dropdown } from "react-bootstrap";
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '@material-ui/core';
import moment from 'moment';

import * as eventStore from "../store/ducks/event.duck";

const useStyles = makeStyles(theme => ({
    chatBox: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        height: '100%',
        transition: 'left 5s',
        position: 'absolute',
        width: '100%',
        minWidth: 250
    },
    chat: {
        height: '100%'
    },
    chatPortlet: {
        backgroundColor: 'transparent',
        height: '100%'
    },
    chatPortalFoot: {
        padding: '0.8rem 0.5rem 0.8rem 0.5rem !important',
    },
    chatBody: {
        position: 'relative',
        height: '100%',
        overflow: 'auto'
    },
    chatPanel: {
        // position: 'relative',
        // maxHeight: '400px'
    },
    chatInput: {
        display: 'flex',
    },
    chatToolbar: {
        marginTop: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    chatEditor: {
        width: '100%'
    },
    textInput: {
        resize: 'none',
        width: '100%',
        border: '0',
        outline: 'none',
        backgroundColor: 'transparent',
        color: '#a2a5b9'
    },
    hideVisibility: {
        visibility: 'hidden',
        left: '-300px'
    },
    commonChatContentLayout: {
        margin: '0.2rem 0 !important',
        borderRadius: '16px !important',
        clear: 'both',
        padding: '0.3rem 0.6rem !important',
    },
    otherChatContentLayout: {
        backgroundColor: 'rgba(10, 187, 135, 0.5) !important',
        borderBottomLeftRadius: '0 !important',
        float: 'left !important'
    },
    privateChatContentLayout: {
        backgroundColor: 'rgb(167, 105, 74) !important',
        borderBottomLeftRadius: '0 !important',
        float: 'left !important'
    },
    myChatContentLayout: {
        backgroundColor: 'rgba(93, 120, 255, 0.5) !important',
        borderBottomRightRadius: '0 !important',
    },
    myChatPrivateContentLayout: {
        backgroundColor: 'rgb(167, 105, 74) !important',
        borderBottomRightRadius: '0 !important',
    },
    commonChatContent: {
        color: '#f1eded !important',
        marginTop: '0 !important',
        fontWeight: '100 !important',
        fontSize: '1rem'
    },
    myChatContent: {
    },
    otherChatContent: {
    },
    white: {
        color: colors.common.white
    },
    chatBoxToolbar: {
        display: 'flex'
    },
    chatHeader: {
        padding: '0 0.4rem',
        color: '#fbfb18 !important',
        fontWeight: 400,
        fontSize: '0.85rem'
    }
}))


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </span>
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <ul className="list-unstyled">
                    {React.Children.toArray(children)}
                </ul>
            </div>
        );
    },
);

const ALL = <FormattedMessage id="CHAT.ALL" />
function MessageElement(props) {
    const { message } = props
    const classes = useStyles();
    return (
        <>
            {message.displayName === 'me' ?
                <div className={`${classes.commonChatContentLayout} ${message.type === 'private' ? classes.myChatPrivateContentLayout : classes.myChatContentLayout} kt-chat__message kt-chat__message--right kt-chat__message--brand`}>
                    <div className="kt-chat__user">
                        <span className={classes.chatHeader}>{message.time}</span>
                    </div>
                    <div className={`${classes.commonChatContent} ${classes.myChatContent} kt-chat__text`}>
                        {message.content}
                    </div>
                </div> :
                <div className={`${classes.commonChatContentLayout} ${message.type === 'private' ? classes.privateChatContentLayout : classes.otherChatContentLayout} kt-chat__message kt-chat__message--success`}>
                    <div className="kt-chat__user">
                        <span className={classes.chatHeader}>{message.displayName} {message.time ? `, ${message.time}` : ''}</span>
                    </div>
                    <div className={`${classes.commonChatContent} ${classes.otherChatContent} kt-chat__text`}>
                        {message.content}
                    </div>
                </div>
            }
        </>
    )
}

function Chat(props) {
    const { open, setIsChatDlg, sendTextMessage, receiveMessageObject, isMessageUpdated, updateMessageSuccess, user, participants, toParticipant, setToParticipant, intl } = props
    const classes = useStyles();
    const [message, setMessage] = React.useState('')
    const chatBodyRef = React.useRef(null)

    const messageContent = React.useRef([])

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    }, [participants])

    useEffect(() => {
        if (open) {
            focusChatBox()
        }
    }, [open])

    useEffect(() => {
        if (isMessageUpdated && chatBodyRef.current) {
            const p = participants.find(participant => receiveMessageObject.id === participant.getId())
            addMessage({ displayName: p ? p.getDisplayName() : '', content: receiveMessageObject.text, type: receiveMessageObject.isPrivate ? 'private' : 'public', time: _getCurrentDateTime() })
            chatBodyRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
            updateMessageSuccess()
        }
    }, [isMessageUpdated, participants, receiveMessageObject, user, updateMessageSuccess])

    function onEnterPress(e) {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            const newMessage = e.target.value
            if (newMessage.trim() === '') {
                return
            }
            addMessage({ displayName: 'me', content: newMessage, time: _getCurrentDateTime(), type: toParticipant === 'all' ? 'public' : 'private' })
            chatBodyRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
            console.log(toParticipant)
            sendTextMessage(toParticipant, newMessage)
            setMessage('')
        }
    }

    function addMessage(m) {
        messageContent.current.push(m)
    }

    function onMessageChange(e) {
        setMessage(e.target.value)
    }

    function handleChangeDlg(e) {
        setIsChatDlg(false)
    }

    function handleClickUser(value) {
        setToParticipant(value)
    }

    function getDisplayName(participantId) {
        if (participantId === 'all') {
            return ALL
        } else {
            const participant = participants.find(participant => participant.getId() === participantId)
            return participant ? participant.getDisplayName() : ''
        }
    }

    function focusChatBox() {
        document.getElementById("chatInput").focus();
    }

    function _getCurrentDateTime() {
        return moment().format('h:mm a, MMM D');
    }

    return (
        <div id="chatBox" className={`${open ? '' : classes.hideVisibility} ${classes.chatBox} `}>
            <div className={`kt-chat ${classes.chat}`}>
                <div className={`kt-portlet kt-portlet--last ${classes.chatPortlet}`}>
                    <div className="kt-portlet__head">
                        <div className="kt-chat__head ">
                            <div className="kt-chat__left">
                                <div className={`kt-chat__label ${classes.white}`}>
                                    {getDisplayName(toParticipant)}
                                </div>
                            </div>
                            <div className={classes.chatBoxToolbar}>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-basic" drop={'up'} navbar={false}>
                                        <IconButton color="primary" aria-label="More" className={classes.volume}>
                                            <MoreVertIcon />
                                        </IconButton >
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu}>
                                        <Dropdown.Item eventKey={toParticipant} key="all" onClick={() => handleClickUser('all')}>
                                            {ALL}
                                        </Dropdown.Item>
                                        {participants.map((participant, index) => {
                                            return participant.getDisplayName() ?
                                                <Dropdown.Item eventKey={index} key={index} onClick={() => handleClickUser(participant.getId())}>
                                                    {participant.getDisplayName()}
                                                </Dropdown.Item> : ''

                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <IconButton color="primary" aria-label="More" className={classes.volume} onClick={handleChangeDlg}>
                                    <CloseIcon />
                                </IconButton >

                            </div>
                        </div>
                    </div>
                    <div className={`kt-portlet__body ${classes.chatBody}`} id="chatBody" >
                        <div className={`kt-chat__messages kt-chat__messages--solid ${classes.chatPanel}`} ref={chatBodyRef}>
                            {messageContent.current.map((m, index) => <MessageElement key={index} message={m} />)}
                        </div>
                    </div>
                    <div className={`kt-portlet__foot ${classes.chatPortalFoot}`} onClick={focusChatBox}>
                        <div className={`kt-chat__input ${classes.chatInput}`}>
                            <div className={classes.chatEditor}>
                                {/* <textarea placeholder="Type here..." style="height: 62px; margin-top: 0px; margin-bottom: 0px;"></textarea> */}
                                <textarea id="chatInput" placeholder={intl.formatMessage({ id: "CHAT.TYPE_HERE" })} value={message} className={classes.textInput} onKeyDown={onEnterPress} onChange={onMessageChange}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}


const mapStateToProps = state => {
    return {
        isMessageUpdated: state.event.isMessageUpdated,
        user: state.auth.user,
        toParticipant: state.event.toParticipant,
    };
};

const mapDispatchToProps = dispatch => ({
    updateMessageSuccess: () => dispatch(eventStore.actions.updateMessageSuccess()),
    setToParticipant: (toParticipant) => dispatch(eventStore.actions.setToParticipant(toParticipant)),
})

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Chat)
);